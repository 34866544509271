import { FC } from 'react';
import './Countdown.css';

interface CountdownProps {
  countdown: number;
}
const Countdown: FC<CountdownProps> = ({ countdown }) => {
  return (
    <div className="container">
      <img src="/assets/icon/erlojua.svg" alt="countdown" />
      <div className="centered">{countdown}</div>
    </div>
  );
};
export default Countdown;
