import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  isPlatform,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import './GameDetail.css';
import { useEffect, useRef } from 'react';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import Header from 'components/Elements/Headers/Header/Header';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'hooks';
import GameItem from 'components/Elements/GameItem/GameItem';
import { IGameDetailRequest } from 'interfaces/game.interface';
import GameButton from 'components/Base/GameButton/GameButton';
import messages from 'config/messages';
import { shareSocialOutline } from 'ionicons/icons';
import { Share } from '@capacitor/share';
import { getGameDetail } from 'store/actions/games';

const GameDetail: React.FC = () => {
  const intl = useIntl();
  const { loggedIn } = useAppSelector((state) => state.session);
  const {
    data,
    loading,
    loaded,
  }: {
    loading: boolean;
    loaded: boolean;
    data: IGameDetailRequest;
  } = useAppSelector((state) => state.games.detail);

  const { id } = useParams<{ id: string }>();
  const ref = useRef();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (id && loggedIn) {
      if (data?.id !== Number(id)) dispatch(getGameDetail(Number(id)));
    }
  }, [data?.id, dispatch, id, loggedIn]);

  const shareResult = () => {
    const text = intl.formatMessage(messages.shareResultMessage, {
      correct_1: data.correct_1,
      correct_2: data.correct_2,
      time: data.time,
      points: data.total_points,
    });
    const shareOption = {
      title: 'Mihiluze',
      text: text,
    };

    if (isPlatform('capacitor')) {
      Share.share(shareOption);
    } else {
      // eslint-disable-next-line no-console
      console.log(shareOption);
    }
  };
  const checkIfTodaysGame = () => {
    const dateObj = new Date();
    const padTo2Digits = (num: number) => {
      return num.toString().padStart(2, '0');
    };
    const month = padTo2Digits(dateObj.getUTCMonth() + 1); //months from 1-12
    const day = padTo2Digits(dateObj.getUTCDate());
    const year = dateObj.getUTCFullYear();
    const date = `${year}-${month}-${day}`;
    const gameDate = data.date.split(' ')[0];
    return date === gameDate ? true : false;
  };
  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.game)}>
        <IonButtons slot="start" className="ion-no-margin ion-no-padding">
          <IonBackButton />
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <>
            {loading && !loaded && (
              <div className="ion-text-center">
                <IonSpinner color="secondary" style={{ zoom: 2 }} />
              </div>
            )}
            {!loading && loaded && data && (
              <>
                <GameItem
                  date={data.date}
                  duration={data.time}
                  correct1={data.correct_1}
                  correct2={data.correct_2}
                  points={data.total_points}
                />
                {checkIfTodaysGame() && (
                  <div className="ion-text-center">
                    <IonCard
                      mode="ios"
                      className="ion-no-margin ion-margin-top"
                      button
                      onClick={shareResult}
                    >
                      <IonItem lines="none">
                        <IonLabel color="secondary">
                          {intl.formatMessage(messages.shareYourResult)}
                        </IonLabel>
                        <IonIcon
                          icon={shareSocialOutline}
                          slot="end"
                          color="secondary"
                        />
                      </IonItem>
                    </IonCard>
                  </div>
                )}
                <h3 className="question-type ion-margin-top">
                  {data.answers.find((item) => item.type === 1)?.type_str}
                </h3>
                <p>{data.answers.find((item) => item.type === 1)?.question}</p>
                {data.answers
                  .find((item) => item.type === 1)
                  ?.answers.map((item, i) => (
                    <GameButton
                      key={i}
                      title={item.name}
                      position={item.value ? 'end' : 'start'}
                      correct={item.correct}
                    />
                  ))}
                {data.answers?.find((item) => item.type === 2)?.answers
                  ?.length !== 0 && (
                  <>
                    <h3 className="question-type ion-margin-top">
                      {data.answers.find((item) => item.type === 2)?.type_str}
                    </h3>
                    <p>
                      {data.answers.find((item) => item.type === 2)?.question}
                    </p>
                    {data.answers
                      .find((item) => item.type === 2)
                      ?.answers.map((item, i) => (
                        <GameButton
                          key={i}
                          title={item.name}
                          position={item.value ? 'end' : 'start'}
                          correct={
                            item.solution
                              ? true
                              : item.value && !item.correct
                                ? false
                                : null
                          }
                          selected={item.solution && !item.value}
                        />
                      ))}
                  </>
                )}
              </>
            )}
          </>
          <br />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default GameDetail;
