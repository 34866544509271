import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import '../CenteredHeaderTitle.css';
import './GroupHeader.css';
import { groupPhoto } from 'config/constants';
import messages from 'config/messages';
import { useIntl } from 'react-intl';

export interface GroupHeaderProps {
  title: string;
  photoUrl: string;
  children?: React.ReactNode | any;
  members: number;
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  title,
  photoUrl,
  children = false,
  members,
}) => {
  const intl = useIntl();

  return (
    <IonHeader className="header-group-item  ion-no-border">
      <IonToolbar>
        <IonButtons slot="start" className="ion-no-margin ion-no-padding">
          <IonBackButton />
        </IonButtons>
        <IonItem lines="none">
          <IonThumbnail>
            <img src={photoUrl || groupPhoto} alt={title} loading="lazy" />
          </IonThumbnail>
          <IonLabel className="ion-no-margin" mode="md">
            <IonTitle>
              {title}
              {members !== 0 && (
                <>
                  <br />
                  <small>
                    {intl.formatMessage(messages.members, {
                      membersCount: members,
                    })}
                  </small>
                </>
              )}
            </IonTitle>
          </IonLabel>
          <IonButtons slot="end" className="ion-no-margin ion-no-padding">
            {children}
          </IonButtons>
        </IonItem>
      </IonToolbar>
    </IonHeader>
  );
};

export default GroupHeader;
