import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IHome } from 'interfaces/home.interface';
import { controlPanelStatusCodes, statusCodes } from 'config/constants';
import { emptyLive, signUpToCompetition } from 'store/actions/live';
import { ILiveUserCompetition } from 'interfaces/live.interface';
import LiveGameModal from './LiveGameModal';
import useRealtimeDB from 'hooks/useRTDB';
import { getHomeInfo } from 'store/actions/home';

const LiveStatusController: React.FC = () => {
  const dispatch = useAppDispatch();

  const home: IHome = useAppSelector((state) => state.home.data);

  const { loggedIn } = useAppSelector((state) => state.session);

  const controlPanel = useRealtimeDB<{ status: 0 | 1 | 2 | 3 }>('controlpanel');
  const liveStatus = useRealtimeDB(
    `competitions/${home?.today?.hash_code}/status/status`,
  );
  const liveCompetition: ILiveUserCompetition = useAppSelector(
    (state) => state.live.liveCompetition.competition,
  );

  // useEffect(() => {
  //   // Refresh the home every minute
  //   if (loggedIn) {
  //     dispatch(getHomeInfo());
  //   }
  // }, [dispatch, loggedIn]);

  useEffect(() => {
    // Refresh the home every minute
    if (loggedIn && controlPanel?.status !== undefined) {
      dispatch(getHomeInfo());
    }
  }, [loggedIn, controlPanel?.status, dispatch]);

  useEffect(() => {
    if (
      liveStatus === statusCodes.HASI_GABE &&
      controlPanel?.status === controlPanelStatusCodes.PROGRAM_STARTED &&
      !liveCompetition?.game_id
    ) {
      dispatch(signUpToCompetition(home?.today?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liveStatus,
    controlPanel?.status,
    home?.today?.id,
    liveCompetition?.game_id,
  ]);

  useEffect(() => {
    if (liveStatus === null) {
      dispatch(emptyLive());
    }
  }, [dispatch, liveStatus]);
  return <LiveGameModal />;
};

export default LiveStatusController;
