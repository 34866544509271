import { FC, useEffect, useState } from 'react';
import {
  ILiveQuestion,
  ILiveUserGameSelection,
} from 'interfaces/live.interface';
import { IonBadge, IonButton, IonCol, IonRow, isPlatform } from '@ionic/react';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import useTimer from 'hooks/useTimer';
import { liveGameFirstRoundSeconds } from 'config/constants';
import React from 'react';
import GameButton from 'components/Base/GameButton/GameButton';
import Countdown from 'components/Base/Countdown/Countdown';

interface PlayGameFirstRoundProps {
  question: ILiveQuestion | null;
  countDownToStartGame: number;
  onFinishRound: ({
    finished,
    userSelection,
    time,
  }: {
    finished: boolean;
    userSelection: ILiveUserGameSelection[];
    time: number;
  }) => void;
}
const PlayGameFirstRound: FC<PlayGameFirstRoundProps> = ({
  question = null,
  onFinishRound,
  countDownToStartGame,
}) => {
  const intl = useIntl();
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [userSelection, setUserSelection] = useState<ILiveUserGameSelection[]>(
    [],
  );
  const [countDown, setCountDown] = useState(countDownToStartGame);

  const selectOption = ({
    id,
    value,
    name,
  }: {
    id: string;
    name: string;
    value: boolean;
  }) => {
    userSelection.push({
      id,
      name,
      value,
    });
    setUserSelection(userSelection);
    setQuestionNumber(questionNumber + 1);
    if (questionNumber === question?.options.length) {
      stopTimer();
      onFinishRound({
        finished: true,
        userSelection: userSelection,
        time: time || 0,
      });
    }
  };
  const { startTimer, stopTimer, time } = useTimer();
  useEffect(() => {
    startCountDown();
    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time) {
      if (liveGameFirstRoundSeconds + 1 - time === 0) {
        onFinishRound({
          finished: false,
          userSelection: [],
          time: time || 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const startCountDown = () => {
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev - 1 <= 1) {
          startTimer();
        }
        if (prev - 1 <= 0) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  };
  const isIosDevice = isPlatform('ios') ? 'ios-device' : '';

  return (
    <>
      {countDown > 0 ? (
        <div className="center-container">
          <h2>{intl.formatMessage(messages.theGameIsGoingToBegin)}</h2>
          <h2 className="live-msg">
            {intl.formatMessage(messages.areYouReady)}
          </h2>
          <div className="countdown">{countDown}</div>
        </div>
      ) : (
        ''
      )}
      {countDown <= 0 && question && (
        <div className="ion-text-center">
          <div className={`ion-padding-top sticky-top ${isIosDevice}`}>
            {time && (
              <div className="animate__animated animate__flip">
                <Countdown countdown={liveGameFirstRoundSeconds + 1 - time} />
              </div>
            )}
            <h3 className="question-type">
              {intl.formatMessage(messages.hamaikakoa)}
            </h3>
            <div className="question-container">
              <div>{question.title}</div>
            </div>
            <br />
            {question.options.map((item, i) => (
              <React.Fragment key={i}>
                {questionNumber === i + 1 && (
                  <IonRow className="user-anwser-row">
                    <IonCol size="2">
                      <IonButton
                        onClick={() =>
                          selectOption({
                            id: item.id,
                            name: item.name,
                            value: false,
                          })
                        }
                        className="answer-button dark"
                      >
                        <div>{intl.formatMessage(messages.no)}</div>
                      </IonButton>
                    </IonCol>
                    <IonCol size="8">
                      <GameButton
                        title={item.name}
                        className="question-button ion-text-center"
                      />
                    </IonCol>
                    <IonCol size="2">
                      <IonButton
                        onClick={() =>
                          selectOption({
                            id: item.id,
                            name: item.name,
                            value: true,
                          })
                        }
                        className="answer-button light"
                      >
                        <div>{intl.formatMessage(messages.yes)}</div>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                )}
              </React.Fragment>
            ))}
            <p>{intl.formatMessage(messages.clickOnNoYesButtons)}</p>
            <IonRow>
              <IonCol>
                {[...Array(11).keys()].map((i) => (
                  <IonBadge
                    className={
                      questionNumber !== i + 1
                        ? 'non-active-badge'
                        : 'active-badge'
                    }
                    key={i}
                  >
                    {i + 1}
                  </IonBadge>
                ))}
              </IonCol>
            </IonRow>
          </div>
          <br />

          <div style={{ margin: '0 10px' }}>
            {userSelection.map((item, i) => (
              <GameButton
                disabled
                title={item.name}
                position={item.value ? 'end' : 'start'}
                key={i}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};
export default PlayGameFirstRound;
