import api from './api';
export const refreshToken = (refresh: string) => {
  /*
      @param refresh - refresh token
      Get user JWT token with phone number
  */
  let body = { refresh: refresh };
  return api.post(`/token/refresh/`, body);
};

export interface IRegisterUser {
  accessToken: string;
  name: string;
}
export const registerUser = ({ name, accessToken }: IRegisterUser) => {
  let body = { name, token: accessToken };
  return api.post(`/users/`, body);
};

export const getPhonePrefixes = () => {
  return api.get('/phone-prefixes/');
};
