import { IGroupRequest } from 'interfaces/groups.interface';
import api from './api';

export const getGroups = () => {
  return api.get<IGroupRequest>('/groups/');
};

export const getGroupDetail = (id: number) => {
  return api.get<any>(`/groups/${id}/`);
};

export const postGroup = ({ name }: { name: string }) => {
  const body = {
    name,
  };
  return api.post<any>('/groups/', body);
};

export const joinToGroup = (hash: string) => {
  return api.post(`/join/?h=${hash}`);
};

export const leaveTheGroup = (id: number) => {
  const body = {
    group: id,
  };
  return api.post(`/sign-out/`, body);
};

export const updateGroupPhoto = async (
  id: number,
  base64Photo: string,
  photoFormat: string,
) => {
  const body = {
    base64_img: base64Photo,
    extension: photoFormat,
  };
  return api.post<any>(`/groups/${id}/upload_photo/`, body);
};

export const updateGroupData = ({
  name,
  id,
  text = '',
}: {
  name: string;
  id: number;
  text?: string;
}) => {
  const body = {
    name,
    group_text: text,
  };
  return api.put<any>(`/groups/${id}/`, body);
};

export const getGroupClassification = (
  groupId: number,
  competitionId: number,
) => {
  return api.get<any>(
    `/groups/${groupId}/classification/?competition=${competitionId}`,
  );
};

export const getGroupDays = (groupId: number) => {
  return api.get<any>(`/groups/${groupId}/previous_competitions/`);
};

export const getGroupWeeks = (groupId: number) => {
  return api.get<any>(`/groups/${groupId}/weeks/`);
};
export const getGroupWeekClassification = (groupId: number, weekId: number) => {
  return api.get<any>(`/groups/${groupId}/weeks/${weekId}/`);
};
