import { Dispatch } from '@reduxjs/toolkit';
import { IRegisterUser } from 'api/session';
import { dbKeys } from 'config/constants';
import { getPreference, setPreference } from 'helpers/preferences';
import { registerUser as registerUserAPI } from 'api/session';
import { getUserData } from './user';

export const SET_JWT_TOKEN = 'SET_JWT_TOKEN';

export const setJWTToken = ({
  access,
  refresh,
}: {
  access: string;
  refresh: string;
}) => {
  return {
    type: SET_JWT_TOKEN,
    payload: {
      access,
      refresh,
    },
  };
};
export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
};

export const REMOVE_SESSION = 'REMOVE_SESSION';

export const removeSession = () => {
  return {
    type: REMOVE_SESSION,
  };
};

export const REGISTER_SESSION_PENDING = 'REGISTER_SESSION_PENDING';
export const REGISTER_SESSION_SUCCESS = 'REGISTER_SESSION_SUCCESS';
export const REGISTER_SESSION_ERROR = 'REGISTER_SESSION_ERROR';

export const registerUser = ({ name, accessToken }: IRegisterUser) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REGISTER_SESSION_PENDING,
    });
    try {
      // Register new user
      const { data } = await registerUserAPI({ name, accessToken });
      const jwt = {
        access: data.jwt.access,
        refresh: data.jwt.refresh,
      };
      // Set token to local storage and store
      await setPreference(dbKeys.jwt, jwt);
      dispatch(setJWTToken(jwt));
      getUserData(data.user_id)(dispatch);

      dispatch({
        type: REGISTER_SESSION_SUCCESS,
        payload: {
          access: data.jwt.access,
        },
      });
    } catch (error) {
      dispatch({
        type: REGISTER_SESSION_ERROR,
        payload: error,
      });
    }
  };
};

export const LOAD_SESSION_FROM_LOCAL_PENDING =
  'LOAD_SESSION_FROM_LOCAL_PENDING';
export const LOAD_SESSION_FROM_LOCAL_SUCCESS =
  'LOAD_SESSION_FROM_LOCAL_SUCCESS';
export const LOAD_SESSION_FROM_LOCAL_ERROR = 'LOAD_SESSION_FROM_LOCAL_ERROR';

export const loadSessionFromLocal = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOAD_SESSION_FROM_LOCAL_PENDING,
    });
    try {
      const jwt = await getPreference(dbKeys.jwt);
      dispatch({
        type: LOAD_SESSION_FROM_LOCAL_SUCCESS,
        payload: jwt,
      });
    } catch (error) {
      dispatch({
        type: LOAD_SESSION_FROM_LOCAL_ERROR,
        payload: error,
      });
    }
  };
};
