import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  initializeFirebaseApp,
  setCurrentScreen,
  setEnabled,
  setUserId,
} from '../../helpers/analytics';
import { firebaseConfig } from 'config/constants';
import { useAppSelector } from 'hooks';

const InitializeFirebase: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    initializeFirebaseApp(firebaseConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseConfig]);

  useEffect(() => {
    const unlisten = history.listen(({ location }: any) => {
      if (location) {
        let pathname = location.pathname;
        setCurrentScreen(pathname);
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const userId: number = useAppSelector((state) => state.user.data?.id);

  useEffect(() => {
    setEnabled();
    if (userId) setUserId(String(userId));
  }, [userId]);
  return null;
};
export default InitializeFirebase;
