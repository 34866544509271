import { INotifications } from 'interfaces/notification.interface';
import api from './api';

export interface IPostTokenResult {
  status: string;
  uuid: string;
}

export const postToken = ({
  token,
  uuid,
  enabled,
}: {
  token: string;
  uuid: string;
  enabled: boolean;
}) => {
  let body = {
    uuid,
    token,
    enabled,
  };
  return api.post<IPostTokenResult>('/register-device/', body);
};
export const getNotifications = () => {
  return api.get<INotifications>('/notifications/');
};
