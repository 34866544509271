import { IonButton } from '@ionic/react';
import { FC } from 'react';
import './GameButton.css';

interface GameButtonProps {
  title: string;
  position?: 'start' | 'end';

  correct?: boolean | null;
  disabled?: boolean | undefined;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  className?: string;
  selected?: boolean;
}
const GameButton: FC<GameButtonProps> = ({
  onClick,
  title,
  position = null,
  correct = null,
  disabled = false,
  className = '',
  selected = false,
}) => {
  return (
    <div
      className={
        position === null
          ? ''
          : position === 'end'
            ? 'ion-text-end'
            : 'ion-text-start'
      }
    >
      <IonButton
        onClick={onClick}
        expand={position === null ? 'block' : undefined}
        disabled={disabled}
        className={`mhlz-game-button ion-text-wrap ${
          correct !== null ? (correct ? 'color-success' : 'color-danger') : ''
        } ${className} ${selected ? 'selected' : ''} ${
          position !== null ? 'small' : ''
        }
        `}
      >
        {title}
      </IonButton>
    </div>
  );
};
export default GameButton;
