import {
  ILiveQuestion,
  IStatus,
  ILiveUserCompetition,
  ILiveUserGame,
} from 'interfaces/live.interface';
import firebaseAPI from './firebaseAPI';
import api from './api';

export const getGeneral = (hash: string) => {
  return firebaseAPI.get<any>(`/api/competitions/${hash}/general.json`);
};

export const getStatus = (hash: string) => {
  return firebaseAPI.get<IStatus>(`/api/competitions/${hash}/status.json`);
};

export const getQuestions = (hash: string) => {
  return firebaseAPI.get<ILiveQuestion>(
    `/api/competitions/${hash}/questions.json`,
  );
};

export const getClassification = (hash: string) => {
  return firebaseAPI.get<any>(`/api/competitions/${hash}/classification.json`);
};

export const getPositions = (hash: string) => {
  return firebaseAPI.get<any>(`/api/competitions/${hash}/positions.json`);
};

export const getGames = (hash: string) => {
  return firebaseAPI.get<any>(`/api/competitions/${hash}/games.json`);
};

export const getGameDetail = (hash: string) => {
  return firebaseAPI.get<any>(`/api/competitions/${hash}/game_details.json`);
};

export const putUserGame = (gameId: number, userGame: ILiveUserGame) => {
  return api.put<any>(`/games/${gameId}/`, userGame);
};

export const postUserGame = (userGame: ILiveUserGame) => {
  return api.post<any>(`/games/`, userGame);
};

export const signUpToCompetition = (id: number) => {
  return api.post<ILiveUserCompetition>(`/competitions/${id}/signup/`);
};
