import {
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IWeekClassification } from 'interfaces/classification.interface';
import { IGroup } from 'interfaces/groups.interface';
import ClassificationLayout from 'pages/Classification/ClassificationLayout';
import { useParams } from 'react-router';
import { getGroupWeekClassification } from 'store/actions/groups';

const GroupWeekClassification: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const weekClassification: IWeekClassification | null =
    useAppSelector((state) => state.groups?.data?.results)?.find(
      (item: IGroup) => item.id === Number(id),
    ).week_classification || null;

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    weekClassification &&
      dispatch(getGroupWeekClassification(Number(id), weekClassification?.id));
    event.detail.complete();
  };

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {weekClassification && (
        <ClassificationLayout
          name={weekClassification.name}
          classification={weekClassification?.cls_data}
          userPosition={weekClassification?.user_position}
          weekId={weekClassification.id}
        />
      )}
    </>
  );
};

export default GroupWeekClassification;
