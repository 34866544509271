import {
  IonModal,
  IonCheckbox,
  IonItem,
  IonContent,
  IonButton,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { InnerHTML } from 'components/Utils';
import messages from 'config/messages';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { flatpages } from '../../config/flatpages';
import GameButton from 'components/Base/GameButton/GameButton';
import Header from 'components/Elements/Headers/Header/Header';
import { closeOutline } from 'ionicons/icons';

interface AcceptPrivacyPolicyProps {
  onChange: ({ accepted }: { accepted: boolean }) => void;
}

const AcceptPrivacyPolicy: React.FC<AcceptPrivacyPolicyProps> = ({
  onChange,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeInputId, setActiveInputId] = useState<string>();

  const modal = useRef<HTMLIonModalElement>(null);
  const intl = useIntl();

  const [privacyInputs, setPrivacyInputs] = useState([
    {
      label: intl.formatMessage(messages.IReadPrivacyPolicy),
      shortLabel: intl.formatMessage(messages.privacyPolicy),
      url: flatpages.acceptPrivacyPolicy.url,
      checked: false,
      id: 'privacy-policy',
    },
    {
      label: intl.formatMessage(messages.IReadTermsOfUse),
      shortLabel: intl.formatMessage(messages.termsOfUse),
      url: flatpages.acceptTermsOfUse.url,
      checked: false,
      id: 'terms-of-use',
    },
    {
      label: intl.formatMessage(
        messages.IAgreeToReceiveCommercialCommunications,
      ),
      shortLabel: intl.formatMessage(messages.commercialCommunications),
      url: flatpages.acceptCommercialCommunications.url,
      checked: false,
      id: 'receive-commercial-communications',
    },
  ]);
  useEffect(() => {
    if (
      privacyInputs.filter((item) => item.checked).length ===
      privacyInputs.length
    ) {
      onChange({ accepted: true });
    } else {
      onChange({ accepted: false });
    }
  }, [privacyInputs, onChange]);

  const checkInput = (inputId: string, checked: boolean) => {
    const changedInput = privacyInputs.map((item) => {
      if (item.id === inputId) {
        return {
          ...item,
          checked: checked,
        };
      } else {
        return item;
      }
    });
    setPrivacyInputs(changedInput);
  };

  return (
    <>
      <IonModal isOpen={Boolean(activeInputId)}>
        <Header
          title={
            privacyInputs.find((item) => item.id === activeInputId)
              ?.shortLabel || ''
          }
        >
          <IonButtons slot="start">
            <IonButton onClick={() => setActiveInputId('')}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </Header>
        <IonContent className="ion-padding">
          <InnerHTML
            url={privacyInputs.find((item) => item.id === activeInputId)?.url}
          />
        </IonContent>
      </IonModal>
      <IonModal
        ref={modal}
        isOpen={openModal}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.5]}
        onIonModalDidDismiss={() => setOpenModal(false)}
      >
        <IonContent className="ion-padding">
          <h2>{intl.formatMessage(messages.consultOutPrivacyPolicy)}</h2>
          <p>
            {intl.formatMessage(messages.consultOutPrivacyPolicyDescription)}
          </p>
          {privacyInputs.map((item, key) => (
            <GameButton
              title={item.shortLabel}
              className="ion-margin-top"
              key={`button-${key}`}
              onClick={() => setActiveInputId(item.id)}
            />
          ))}
        </IonContent>
      </IonModal>
      {privacyInputs.map((input, key) => (
        <IonItem
          lines="none"
          className="ion-text-nowrap input-padding"
          key={`input-${key}`}
        >
          <IonCheckbox
            checked={input.checked}
            slot="start"
            onIonChange={(e) => checkInput(input.id, e.detail.checked)}
            color="primary"
          />

          <div
            className="ion-text-wrap privacy-policy-text"
            onClick={() => setOpenModal(true)}
            onKeyUp={() => setOpenModal(true)}
            role="button"
            tabIndex={0}
          >
            <InnerHTML>{input.label}</InnerHTML>
          </div>
        </IonItem>
      ))}
    </>
  );
};

export default AcceptPrivacyPolicy;
