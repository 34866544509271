import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { initializeApp } from 'firebase/app';
import { FirebaseConfig } from 'interfaces/firebase.interface';

export let firebaseInstance: any = null;
const initializeFirebaseApp = (firebaseConfig: FirebaseConfig) => {
  firebaseInstance = initializeApp(firebaseConfig);
};

const setUserId = async (userId: string) => {
  await FirebaseAnalytics.setUserId({ userId });
};

const setUserProperty = async ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  await FirebaseAnalytics.setUserProperty({ key, value });
};

const setCurrentScreen = async (screenName: string) => {
  await FirebaseAnalytics.setCurrentScreen({ screenName });
};

const logEvent = async ({ name, params }: { name: string; params: object }) => {
  await FirebaseAnalytics.logEvent({
    name,
    params,
  });
};

const setEnabled = async () => {
  await FirebaseAnalytics.setEnabled({ enabled: true });
};

const isEnabled = async () => {
  const { enabled } = await FirebaseAnalytics.isEnabled();
  return enabled;
};

export {
  initializeFirebaseApp,
  setUserId,
  setUserProperty,
  setCurrentScreen,
  logEvent,
  setEnabled,
  isEnabled,
};
