import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import { useEffect, useRef } from 'react';
import { useAPI } from 'hooks';
import Header from 'components/Elements/Headers/Header/Header';
import { useParams } from 'react-router';

import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import { IWeekClassification } from 'interfaces/classification.interface';
import ClassificationLayout from 'pages/Classification/ClassificationLayout';
import { getGroupWeekClassification } from 'api/groups';

const GroupPreviousWeekDetail: React.FC = () => {
  const {
    data: weekClassification,
    triggerRequest,
  }: { data: IWeekClassification; triggerRequest: any } = useAPI();

  const ref = useRef();
  const { id, weekId } = useParams<{ id: string; weekId: string }>();
  const intl = useIntl();

  useEffect(() => {
    if (id)
      triggerRequest(getGroupWeekClassification, Number(id), Number(weekId));
  }, [id, triggerRequest, weekId]);
  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.classification)}>
        <IonToolbar>
          <IonButtons slot="start" className="ion-no-margin ion-no-padding">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <ClassificationLayout
            name={weekClassification?.name}
            classification={weekClassification?.cls_data}
            userPosition={weekClassification?.user_position}
            weekId={weekClassification?.id}
            userGame={weekClassification?.user_cls}
          />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default GroupPreviousWeekDetail;
