import { IUser } from 'interfaces/user.interface';
import {
  GET_USER_DATA_ERROR,
  GET_USER_DATA_PENDING,
  GET_USER_DATA_SUCCESS,
  LOAD_USER_DATA_ERROR,
  LOAD_USER_DATA_PENDING,
  LOAD_USER_DATA_SUCCESS,
  REMOVE_USER_DATA_ERROR,
  REMOVE_USER_DATA_PENDING,
  REMOVE_USER_DATA_SUCCESS,
  SET_USER_DATA_ERROR,
  SET_USER_DATA_PENDING,
  SET_USER_DATA_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_ERROR,
  UPDATE_USER_DATA_PENDING,
} from 'store/actions/user';

export const dataInitialState: IUser = {
  name: '',
  phone: '',
  photo_url: '',
  id: null,
  birthdate: null,
  town: '',
  email: '',
};

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  data: dataInitialState,
};

const user = (state = initialState, action: any) => {
  switch (action.type) {
    case LOAD_USER_DATA_PENDING:
    case REMOVE_USER_DATA_PENDING:
    case SET_USER_DATA_PENDING:
    case GET_USER_DATA_PENDING:
    case DELETE_USER_PENDING:
    case UPDATE_USER_DATA_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case LOAD_USER_DATA_ERROR:
    case REMOVE_USER_DATA_ERROR:
    case SET_USER_DATA_ERROR:
    case GET_USER_DATA_ERROR:
    case DELETE_USER_ERROR:
    case UPDATE_USER_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case LOAD_USER_DATA_SUCCESS:
    case SET_USER_DATA_SUCCESS:
    case GET_USER_DATA_SUCCESS:
    case UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case REMOVE_USER_DATA_SUCCESS:
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        ...initialState,
        loaded: true,
      };

    // Default case
    default:
      return state;
  }
};

export default user;
