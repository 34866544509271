import {
  IClassificationRequest,
  IPreviousCompetition,
  IPreviousWeek,
  IWeekClassification,
} from 'interfaces/classification.interface';
import {
  GET_TODAY_CLASSIFICATION_PENDING,
  GET_TODAY_CLASSIFICATION_ERROR,
  GET_TODAY_CLASSIFICATION_SUCCESS,
  GET_WEEK_CLASSIFICATION_PENDING,
  GET_WEEK_CLASSIFICATION_ERROR,
  GET_WEEK_CLASSIFICATION_SUCCESS,
  GET_PREVIOUS_DAYS_PENDING,
  GET_PREVIOUS_DAYS_ERROR,
  GET_PREVIOUS_DAYS_SUCCESS,
  GET_PREVIOUS_WEEKS_SUCCESS,
  GET_PREVIOUS_WEEKS_PENDING,
  GET_PREVIOUS_WEEKS_ERROR,
} from '../actions/classification';

interface IInitialState {
  loading: boolean;
  loaded: boolean;
  data: {
    today: IClassificationRequest | null;
    week: IWeekClassification | null;
    previousDays: IPreviousCompetition[] | null;
    previousWeeks: IPreviousWeek | null;
  };
  error: any;
}

const initialState: IInitialState = {
  loading: false,
  loaded: false,
  error: null,
  data: {
    today: null,
    week: null,
    previousDays: null,
    previousWeeks: null,
  },
};

const classification = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TODAY_CLASSIFICATION_PENDING:
    case GET_WEEK_CLASSIFICATION_PENDING:
    case GET_PREVIOUS_DAYS_PENDING:
    case GET_PREVIOUS_WEEKS_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_TODAY_CLASSIFICATION_ERROR:
    case GET_WEEK_CLASSIFICATION_ERROR:
    case GET_PREVIOUS_DAYS_ERROR:
    case GET_PREVIOUS_WEEKS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case GET_TODAY_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          today: action.payload,
        },
        loading: false,
        loaded: true,
      };
    case GET_WEEK_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          week: action.payload,
        },
        loading: false,
        loaded: true,
      };
    case GET_PREVIOUS_DAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          previousDays: action.payload,
        },
      };
    case GET_PREVIOUS_WEEKS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          previousWeeks: action.payload,
        },
      };

    // Default case
    default:
      return state;
  }
};

export default classification;
