import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform, useIonAlert, useIonLoading } from '@ionic/react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

const PushNotificationListener: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dissmissLoading] = useIonLoading();

  const pushNotificationReceived = () => {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notificationClicked) => {
        if (notificationClicked.actionId === 'tap') {
          if ('eventType' in notificationClicked.notification.data) {
            const eventType = notificationClicked.notification.data.eventType;
            switch (eventType) {
              case 'openAppRoute':
                presentLoading();
                setTimeout(() => {
                  history.push(notificationClicked.notification.data.route);
                  dissmissLoading();
                }, 1000);
                break;
              case 'showAlert':
                const closeMessage = intl.formatMessage({
                  id: 'Close',
                  defaultMessage: 'Close',
                });
                presentAlert({
                  header: notificationClicked.notification.data.title,
                  message: notificationClicked.notification.data.message,
                  buttons: [
                    {
                      text: closeMessage,
                      role: 'cancel',
                    },
                  ],
                });
                break;
            }
          }
        }
      },
    );
  };

  useEffect(() => {
    if (isPlatform('capacitor')) {
      pushNotificationReceived();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default PushNotificationListener;
