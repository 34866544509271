import { getDatabase, onValue, ref } from 'firebase/database';
import { firebaseInstance } from 'helpers/analytics';
import { useEffect, useState } from 'react';

const useRealtimeDB = <T>(url: string) => {
  const [value, setValue] = useState<T>();
  useEffect(() => {
    if (firebaseInstance && url) {
      const db = getDatabase(firebaseInstance);
      const refInstance = ref(db, `/api/${url}`);
      onValue(refInstance, (snapshot) => {
        const data = snapshot.val();
        setValue(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, firebaseInstance]);

  return value;
};

export default useRealtimeDB;
