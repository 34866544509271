import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import './Groups.css';
import ProfileHeader from 'components/Elements/Headers/ProfileHeader/ProfileHeader';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import messages from 'config/messages';
import GroupItem from 'components/Elements/GroupItem/GroupItem';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import { routes } from 'config/routes';
import { getGroups } from 'store/actions/groups';
import { IGroupRequest } from 'interfaces/groups.interface';
import AddGroupModal from './AddGroupModal/AddGroupModal';

const Groups: React.FC = () => {
  const intl = useIntl();
  const ref = useRef();
  const pushRoute = usePushRoute();
  const { loggedIn } = useAppSelector((state) => state.session);
  const {
    data,
    loading,
    loaded,
  }: { data: IGroupRequest; loading: boolean; loaded: boolean } =
    useAppSelector((state) => state.groups);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loggedIn && dispatch(getGroups());
  }, [dispatch, loggedIn]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getGroups());
    event.detail.complete();
  };

  return (
    <IonPage ref={ref}>
      <ProfileHeader title={intl.formatMessage(messages.groups)}>
        <IonButton
          onClick={() =>
            pushRoute(routes.moreInfo, {
              menuId: 'groups',
              title: intl.formatMessage(messages.groups),
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
        </IonButton>
      </ProfileHeader>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <h1 className="ion-no-margin ion-margin-bottom">
            {intl.formatMessage(messages.yourGroups)}
          </h1>
          {loading && !loaded && (
            <div className="ion-text-center">
              <IonSpinner color="secondary" style={{ zoom: 2 }} />
            </div>
          )}
          {!loading && loaded && data?.results?.length === 0 ? (
            <h2>
              {intl.formatMessage(
                messages.thereAreNotGroupYetCreateTheFirstOne,
              )}
            </h2>
          ) : (
            <>
              {data?.results?.map((item, i: number) => (
                <GroupItem
                  name={item.name}
                  photoUrl={item.photo_url}
                  members={item.user_amount}
                  key={i}
                  onClick={() => pushRoute(routes.groupDetail, { id: item.id })}
                />
              ))}
            </>
          )}
          <AddGroupModal />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Groups;
