import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonItem,
  IonPage,
  IonToggle,
} from '@ionic/react';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import Header from 'components/Elements/Headers/Header/Header';
import messages from 'config/messages';
import {
  enableUserNotifications,
  localNotificationSettingsIsEnabled,
} from 'helpers/notifications';
import { closeOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

const NotificationsSettings: React.FC = () => {
  const intl = useIntl();
  const ref = useRef();

  const [enableGeneralNotifications, setEnableGeneralNotifications] =
    useState(false);

  const getLocalSettings = async () => {
    const notificationEnabled = await localNotificationSettingsIsEnabled();
    setEnableGeneralNotifications(notificationEnabled);
  };
  useEffect(() => {
    getLocalSettings();
  }, []);

  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.notifications)}>
        <IonButtons slot="end" className="ion-no-margin ion-no-padding">
          <IonBackButton icon={closeOutline} />
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer ionPageRef={ref} ellipsedBackground>
          <IonItem lines="none">
            <IonToggle
              enableOnOffLabels={true}
              checked={enableGeneralNotifications}
              onClick={() => {
                setEnableGeneralNotifications(!enableGeneralNotifications);
                enableUserNotifications(!enableGeneralNotifications);
              }}
            >
              {intl.formatMessage(messages.enableOrDisableNotifications)}
            </IonToggle>
          </IonItem>
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default NotificationsSettings;
