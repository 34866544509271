import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react';
import './Classification.css';
import ProfileHeader from 'components/Elements/Headers/ProfileHeader/ProfileHeader';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import { useRef, useState } from 'react';
import { useAppSelector, usePushRoute } from 'hooks';
import { routes } from 'config/routes';
import TodayClassification from './Tabs/TodayClassification';
import WeekClassification from './Tabs/WeekClassification';
const Segments = {
  today: 'today',
  week: 'week',
};

const Classification: React.FC = () => {
  const intl = useIntl();
  const ref = useRef();
  const pushRoute = usePushRoute();
  const [segment, setSegment] = useState<string | undefined>(Segments.today);
  const weekClassificationID: number = useAppSelector(
    (state) => state?.home?.data?.week?.id || null,
  );
  return (
    <IonPage ref={ref}>
      <ProfileHeader title={intl.formatMessage(messages.classification)}>
        <IonButton
          onClick={() =>
            pushRoute(routes.moreInfo, {
              menuId: 'classification',
              title: intl.formatMessage(messages.classification),
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
        </IonButton>
      </ProfileHeader>
      {weekClassificationID && (
        <IonToolbar>
          <IonSegment
            value={segment}
            className="sticky-segment"
            mode="ios"
            onIonChange={(e: any) => {
              e?.detail?.value && setSegment(e?.detail.value);
            }}
          >
            <IonSegmentButton value={Segments.today}>
              <IonLabel>{intl.formatMessage(messages.ofTheDay)}</IonLabel>
            </IonSegmentButton>

            <IonSegmentButton value={Segments.week}>
              <IonLabel>{intl.formatMessage(messages.ofTheWeek)}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      )}
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          {segment === Segments.today && <TodayClassification />}
          {segment === Segments.week && <WeekClassification />}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Classification;
