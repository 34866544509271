import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import './Games.css';
import ProfileHeader from 'components/Elements/Headers/ProfileHeader/ProfileHeader';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import messages from 'config/messages';
import { useEffect, useRef } from 'react';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import GameItem from 'components/Elements/GameItem/GameItem';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import { routes } from 'config/routes';
import { getGames } from 'store/actions/games';
import { IGamesRequest } from 'interfaces/game.interface';

const Games: React.FC = () => {
  const intl = useIntl();
  const ref = useRef();
  const pushRoute = usePushRoute();
  const { loggedIn } = useAppSelector((state) => state.session);
  const {
    data,
    loading,
    loaded,
  }: { data: IGamesRequest; loading: boolean; loaded: boolean } =
    useAppSelector((state) => state.games);
  const dispatch = useAppDispatch();

  useEffect(() => {
    loggedIn && dispatch(getGames());
  }, [dispatch, loggedIn]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getGames());
    event.detail.complete();
  };
  return (
    <IonPage ref={ref}>
      <ProfileHeader title={intl.formatMessage(messages.games)}>
        <IonButton
          onClick={() =>
            pushRoute(routes.moreInfo, {
              menuId: 'games',
              title: intl.formatMessage(messages.games),
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
        </IonButton>
      </ProfileHeader>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>

          {loading && !loaded && (
            <div className="ion-text-center">
              <IonSpinner color="secondary" style={{ zoom: 2 }} />
            </div>
          )}
          {!loading && loaded && data?.results?.length === 0 ? (
            <h2>{intl.formatMessage(messages.youHaveNotPlayedAGameYet)}</h2>
          ) : (
            <>
              {data?.results?.map((game, i) => (
                <div key={i} className="ion-padding-bottom">
                  <GameItem
                    date={game.date}
                    duration={game.time}
                    correct1={game.correct_1}
                    correct2={game.correct_2}
                    points={game.total_points}
                    onClick={() =>
                      game.visible &&
                      pushRoute(routes.gameDetail, { id: game.id })
                    }
                    visible={game.visible}
                  />
                </div>
              ))}
            </>
          )}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Games;
