import messages_eu from '../locales/eu/eu.json';

interface IAvailableLanguages {
  [x: string]: { messages: {} };
}
export const availableLanguages: IAvailableLanguages = {
  eu: {
    messages: messages_eu,
  },
};

export const languageCode: string = 'eu';
