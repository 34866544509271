import { useEffect } from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import { statusBarColor } from 'config/constants';
import { isPlatform } from '@ionic/react';

const InitializeStatusBar = () => {
  useEffect(() => {
    if (isPlatform('capacitor')) {
      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setBackgroundColor({ color: statusBarColor });
    }
  }, []);
  return <></>;
};

export default InitializeStatusBar;
