import { IonCol, IonGrid, IonRippleEffect, IonRow } from '@ionic/react';
import { FC } from 'react';
import './GameItem.css';
import { useIntl } from 'react-intl';
import { capitalizeString, normalizeDate } from 'helpers/utils';

interface GameItemProps {
  date: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;
  duration: string;
  correct1: number;
  correct2: number;
  points: number;
  visible?: boolean;
  className?: string;
}
const GameItem: FC<GameItemProps> = ({
  onClick,
  date,
  duration,
  correct1,
  correct2,
  points,
  visible = true,
  className = '',
}) => {
  const intl = useIntl();
  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
      className={`game-item ${className}`}
    >
      <div className="ion-activatable ripple-parent ion-padding-start">
        <IonGrid class="ion-no-padding">
          <IonRow className="ion-align-items-center ion-no-padding">
            <IonCol size="4">
              <p className="ion-no-margin padding-bottom">
                {capitalizeString(
                  intl.formatDate(normalizeDate(date), { month: 'long' }),
                )}{' '}
                {intl.formatDate(normalizeDate(date), { day: '2-digit' })}
              </p>
              <strong>
                {capitalizeString(
                  intl.formatDate(normalizeDate(date), { weekday: 'long' }),
                )}
              </strong>
            </IonCol>
            <IonCol class="ion-no-padding" size="4">
              <div id="ellipse-1"></div>
              <div className="ellipse-container">
                <h3 className="padding-bottom">
                  {visible ? `${correct1} + ${correct2}` : '?'}
                </h3>
                <p>{visible ? duration : '--:--'}</p>
              </div>
            </IonCol>
            <IonCol class="ion-no-padding" size="4">
              <div id="ellipse-2"></div>
              <div className="ellipse-container">
                <h3>{visible ? points : '?'}</h3>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {visible && onClick && <IonRippleEffect />}
      </div>
    </div>
  );
};
export default GameItem;
