/* eslint-disable no-console */
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/react';
import { postToken } from '../api/notification';
import { dbKeys } from 'config/constants';
import { INotification } from '../interfaces/notification.interface';

import { getPreference, setPreference } from './preferences';

const requestNotificationPermissions = async () => {
  // external required step
  // register for push
  if (isPlatform('capacitor')) {
    await PushNotifications.requestPermissions();
    await PushNotifications.register();
    await PushNotifications.addListener('registration', async () => {
      // Get FCM token instead the APN one returned by Capacitor
      // if (isPlatform('ios')) {
      const { token } = await FCM.getToken();

      // const token = fcm_token;
      // }

      const notificationLocalSettings: any = await getPreference(
        dbKeys.notifications,
      );

      console.log('fcmToken: ', token);

      const notificationConfig: INotification = {
        token: token,
        ...notificationLocalSettings,
      };
      const { data } = await postToken(notificationConfig);
      const newValue = {
        ...notificationConfig,
        uuid: data.uuid,
        enabled: true,
      };
      setPreference(dbKeys.notifications, newValue);
    });
  } else {
    console.log('Not Capacitor');
    console.log('Setting up notifications in debug mode');
    setPreference(dbKeys.notifications, { token: '' });
  }
};

const getFCMToken = async () => {
  try {
    const t = await FCM.getToken();
    return t.token;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const enableUserNotifications = async (enabled: boolean) => {
  if (isPlatform('capacitor')) {
    const notificationLocalSettings: any = await getPreference(
      dbKeys.notifications,
    );
    if (
      notificationLocalSettings !== null &&
      'token' in notificationLocalSettings
    ) {
      const notificationConfig: INotification = {
        token: notificationLocalSettings.token,
        uuid: notificationLocalSettings ? notificationLocalSettings.uuid : '',
        enabled: enabled,
      };

      try {
        const { data } = await postToken(notificationConfig);
        const newValue = {
          ...notificationConfig,
          uuid: data.uuid,
        };
        setPreference(dbKeys.notifications, newValue);
      } catch (e) {
        console.log(e);
      }
    } else {
      requestNotificationPermissions();
    }
  }
};

const updateUserNotificationToken = async () => {
  const notificationLocalSettings: any = await getPreference(
    dbKeys.notifications,
  );
  if (
    notificationLocalSettings !== null &&
    'token' in notificationLocalSettings
  ) {
    const token = await getFCMToken();
    if (token && notificationLocalSettings.enabled) {
      const notificationConfig: INotification = {
        token: token,
        uuid: notificationLocalSettings ? notificationLocalSettings.uuid : '',
        enabled: notificationLocalSettings.enabled,
      };
      await postToken(notificationConfig);

      setPreference(dbKeys.notifications, notificationConfig);
    }
  }
};

const localNotificationSettingsIsEnabled = async () => {
  const notificationLocalSettings: any = await getPreference(
    dbKeys.notifications,
  );
  return notificationLocalSettings ? notificationLocalSettings?.enabled : false;
};

const FCMAutoInit = () => {
  FCM.setAutoInit({ enabled: true });
};
export {
  requestNotificationPermissions,
  getFCMToken,
  enableUserNotifications,
  localNotificationSettingsIsEnabled,
  FCMAutoInit,
  updateUserNotificationToken,
};
