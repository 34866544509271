import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const LockScreenOrientation: React.FC = () => {
  const lockOrientation = async () => {
    ScreenOrientation.lock({ orientation: 'portrait' });
  };

  useEffect(() => {
    if (
      isPlatform('capacitor') &&
      (!isPlatform('ipad') || !isPlatform('tablet'))
    ) {
      lockOrientation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LockScreenOrientation;
