import messages from './messages';

export interface IMoreInfoMenus {
  label: { id: string; defaultMessage: string };
  action: {
    type: string;
    route?: any;
    params?: { [key: string]: string };
  };
}

const popoverActionType = {
  flatpage: 'flatpage',
  route: 'route',
  rateApp: 'rateApp',
  goBack: 'goBack',
};

const homePopover: IMoreInfoMenus[] = [
  {
    label: messages.termsOfUse,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'mihiluzePolicy',
      },
    },
  },
  // {
  //   label: messages.rateTheApp,
  //   action: {
  //     type: popoverActionType.rateApp,
  //   },
  // },
];

const classificationPopover: IMoreInfoMenus[] = [
  {
    label: messages.aboutClassification,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'aboutClassification',
      },
    },
  },
  {
    label: messages.prizes,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'prizes',
      },
    },
  },
  {
    label: messages.previousDays,
    action: {
      type: popoverActionType.route,
      route: 'previousDays',
    },
  },
  {
    label: messages.previousWeeks,
    action: {
      type: popoverActionType.route,
      route: 'previousWeeks',
    },
  },
];

const groupsPopover: IMoreInfoMenus[] = [
  {
    label: messages.aboutGroups,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'aboutGroups',
      },
    },
  },
];

const groupDetailsPopover: IMoreInfoMenus[] = [
  {
    label: messages.previousDays,
    action: {
      type: popoverActionType.route,
      route: 'groupDetailPreviousDays',
    },
  },
  {
    label: messages.previousWeeks,
    action: {
      type: popoverActionType.route,
      route: 'groupDetailPreviousWeeks',
    },
  },
];
const gamesPopover: IMoreInfoMenus[] = [
  {
    label: messages.aboutGames,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'aboutGames',
      },
    },
  },
];

const userProfilePopover: IMoreInfoMenus[] = [
  {
    label: messages.notifications,
    action: {
      type: popoverActionType.route,
      route: 'notificationsSettings',
    },
  },
  {
    label: messages.privacyPolicy,
    action: {
      type: popoverActionType.route,
      route: 'flatpage',
      params: {
        id: 'privacyPolicy',
      },
    },
  },
];

export const moreInfoMenus: { [x: string]: IMoreInfoMenus[] } = {
  home: homePopover,
  classification: classificationPopover,
  groups: groupsPopover,
  groupDetails: groupDetailsPopover,
  games: gamesPopover,
  userProfile: userProfilePopover,
};

// export { moreInfoMenus };
