import { IUser } from 'interfaces/user.interface';
import api from './api';

export const getUserInfo = (userId: number) => {
  return api.get(`/users/${userId}/`);
};

export const updateUserInfo = (userId: number, userData: IUser) => {
  const body = userData;
  return api.put(`/users/${userId}/`, body);
};

interface IUpdateUserRequest {
  updated: boolean;
  success: boolean;
}

export const changeUserPhoto = async (
  base64Photo: string,
  photoFormat: string,
) => {
  const body = {
    base64_img: base64Photo,
    extension: photoFormat,
  };
  return api.post<IUpdateUserRequest>(`/users/upload_photo/`, body);
};
export const deleteUser = (userId: number) => {
  return api.delete(`/users/${userId}/`);
};
