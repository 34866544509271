import {
  IClassificationRequest,
  IPreviousCompetition,
  IPreviousWeek,
  IWeekClassification,
} from 'interfaces/classification.interface';
import api from './api';

export const getClassification = (id: number) => {
  return api.get<IClassificationRequest>(`/competitions/${id}/classification/`);
};

export const getCompetitionDetail = (id: number) => {
  return api.get<any>(`/competitions/${id}/`);
};

export const getWeekClassification = (id: number) => {
  return api.get<IWeekClassification>(`/weeks/${id}/`);
};

export const getCompetitionList = () => {
  return api.get<IPreviousCompetition>(`/competitions/`);
};

export const getWeeks = () => {
  return api.get<IPreviousWeek>(`/weeks/`);
};

// export const getNextCompetitionList = (nextUrl: string) => {
//   return api.get<any>(nextUrl);
// };
