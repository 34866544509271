import { useCallback, useState } from 'react';

const getMS = (every: string, customMillisecons?: number) => {
  switch (every) {
    case 'second':
      return 1000;
    case 'minute':
      return 1000 * 60;
    case 'custom':
      return customMillisecons;
  }
};

const useTimer = (every?: string, milliseconds?: number) => {
  const [intervalId, setIntervalId] = useState<typeof setInterval | any>();

  const [time, setTime] = useState<number>();

  const startTimer = () => {
    const startTime = Date.now();
    const interval = getMS(every || 'second', milliseconds);
    const timer_interval = setInterval(() => {
      const date_now = new Date();
      const dif = date_now.getTime() - new Date(startTime).getTime();
      const seconds_from_start_to_now = Math.trunc(dif / 1000);
      setTime(seconds_from_start_to_now);
    }, interval);
    setIntervalId(timer_interval);
  };

  const stopTimer = useCallback(() => {
    clearInterval(intervalId);
  }, [intervalId]);

  return { startTimer, stopTimer, time };
};

export default useTimer;
