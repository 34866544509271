import Profile from 'pages/Profile/Profile';
import Flatpage from '../pages/Flatpage';
import Home from '../pages/Home/Home';
import Classification from '../pages/Classification/Classification';
import Groups from '../pages/Groups/Groups';
import Games from 'pages/Games/Games';
import MoreInfoMenu from 'pages/MoreInfoMenu/MoreInfoMenu';
import NotificationsSettings from 'pages/Profile/NotificationsSettings';
import Notifications from 'pages/Notifications';
import GroupDetail from 'pages/Groups/GroupDetail/GroupDetail';
import GameDetail from 'pages/Games/GameDetail';
import ClassificationDetail from 'pages/Classification/PreviousDayDetail';
import UserWeekGames from 'pages/UserWeekGames/UserWeekGames';
import PreviousDays from 'pages/Classification/PreviousDays';
import PreviousWeeks from 'pages/Classification/PreviousWeeks';
import PreviousWeekDetail from 'pages/Classification/PreviousWeekDetail';
import GroupPreviousDays from 'pages/Groups/GroupPreviousDays/GroupPreviousDays';
import GroupPreviousWeeks from 'pages/Groups/GroupPreviousWeeks/GroupPreviousWeeks';
import GroupPreviousWeekDetail from 'pages/Groups/GroupPreviousWeeks/GroupPreviousWeekDetail';
import GroupPreviousDayDetail from 'pages/Groups/GroupPreviousDays/GroupPreviousDayDetail';

const initialRoute = '/home';
export interface IRoute {
  path: string;
  component: React.ComponentType;
  exact?: boolean;
  hideTabBar?: boolean;
}
export interface IRoutes {
  [key: string]: IRoute;
}
const routes: IRoutes = {
  home: {
    path: '/home',
    component: Home,
    exact: true,
  },
  notifications: {
    path: '/home/inbox',
    component: Notifications,
    exact: true,
  },
  classification: {
    path: '/classification',
    component: Classification,
    exact: true,
  },
  classificationDetail: {
    path: '/classification/:id',
    component: ClassificationDetail,
    exact: true,
  },
  weekClassificationDetail: {
    path: '/week-classification/:id',
    component: PreviousWeekDetail,
    exact: true,
  },
  previousDays: {
    path: '/previous-days',
    component: PreviousDays,
    exact: true,
  },
  previousWeeks: {
    path: '/previous-weeks',
    component: PreviousWeeks,
    exact: true,
  },
  groups: {
    path: '/groups',
    component: Groups,
    exact: true,
  },
  groupDetail: {
    path: '/groups/:id',
    component: GroupDetail,
    exact: true,
  },
  groupDetailPreviousDays: {
    path: '/groups-previous-days',
    component: GroupPreviousDays,
    exact: true,
  },
  groupDetailPreviousDayDetail: {
    path: '/groups-previous-days/:id/:competitionId',
    component: GroupPreviousDayDetail,
    exact: true,
  },
  groupDetailPreviousWeeks: {
    path: '/groups-previous-weeks',
    component: GroupPreviousWeeks,
    exact: true,
  },
  groupDetailPreviousWeekDetail: {
    path: '/groups-previous-weeks/:id/:weekId',
    component: GroupPreviousWeekDetail,
    exact: true,
  },
  games: {
    path: '/games',
    component: Games,
    exact: true,
  },
  gameDetail: {
    path: '/games/:id',
    component: GameDetail,
    exact: true,
  },
  profile: {
    path: '/**/profile',
    component: Profile,
    exact: true,
  },
  notificationsSettings: {
    path: '/**/profile/notifications',
    component: NotificationsSettings,
    exact: true,
  },
  flatpage: {
    path: '/**/flatpage/:id',
    component: Flatpage,
  },
  moreInfo: {
    path: '/**/more-info/:menuId/:title',
    component: MoreInfoMenu,
    exact: true,
  },
  userWeekGames: {
    path: '/user-week-game/:weekId/:userId/:userData',
    component: UserWeekGames,
    exact: true,
  },
};
export { initialRoute, routes };
