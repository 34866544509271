import { IonInput } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import './Input.css';

interface InputProps {
  /**
   * Optional click handler
   */
  onChange?: (e: string | null) => void;
  /**
   * Input disabled
   */
  disabled?: boolean;
  label: string;
  value?: string | number;
  color?: 'primary' | 'danger' | 'warning' | 'success' | 'dark';
  type?: 'text' | 'number' | 'email' | 'tel';
  counter?: boolean;
  maxLenght?: number;
  name?: string;
}
const Input: FC<InputProps> = ({
  onChange,
  disabled = false,
  label,
  type = 'text',
  value,
  maxLenght = 20,
  counter = false,
  name,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <IonInput
      fill="outline"
      disabled={disabled}
      value={inputValue}
      autoCapitalize="true"
      label-placement="floating"
      label={label}
      type={type}
      counter={counter}
      maxlength={maxLenght}
      className="mhlz-input"
      onIonInput={(ev) => {
        onChange && onChange(ev.detail.value || null);
        setInputValue(ev.detail.value || '');
      }}
      {...(name ? { name } : {})}
    />
  );
};
export default Input;
