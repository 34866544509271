import { isPlatform } from '@ionic/react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import {
  getAuth,
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber as signInWithPhoneNumberFBWeb,
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'config/constants';

const initializeFirebaseApp = () => {
  initializeApp(firebaseConfig);
};

const sendPhoneNumber = async (
  phoneNumber: string,
): Promise<{ phoneNumber: string; verificationId: string }> => {
  return new Promise(async (resolve, reject) => {
    if (phoneNumber) {
      const phoneNumberString = phoneNumber;
      if (isPlatform('capacitor')) {
        // Auth methods for android and ios
        await FirebaseAuthentication.signInWithPhoneNumber({
          phoneNumber: phoneNumberString,
        });
        await FirebaseAuthentication.addListener(
          'phoneCodeSent',
          async (event) => {
            // Ask the user for the SMS code
            resolve({
              phoneNumber: phoneNumberString,
              verificationId: event.verificationId,
            });
          },
        );
        await FirebaseAuthentication.addListener(
          'phoneVerificationFailed',
          async (error) => {
            reject(error.message);
          },
        );
      } else {
        // Auth methods for web
        const auth = getAuth();
        auth.languageCode = 'eu';
        const recaptchaVerifier = new RecaptchaVerifier(
          auth,
          'recaptcha-container',
          {
            size: 'invisible',
          },
        );
        const appVerifier = recaptchaVerifier;
        signInWithPhoneNumberFBWeb(auth, phoneNumberString, appVerifier)
          .then((confirmationResult) => {
            resolve({
              phoneNumber: phoneNumberString,
              verificationId: confirmationResult.verificationId,
            });
          })
          .catch((error) => reject(error));
      }
    }
  });
};

const sendVerificationCode = async (
  verificationId: string,
  verificationCode: string,
): Promise<{
  phoneNumber: string | null | undefined;
  accessToken: string | null | undefined;
}> => {
  return new Promise(async (resolve, reject) => {
    if (isPlatform('capacitor')) {
      FirebaseAuthentication.confirmVerificationCode({
        verificationId: verificationId,
        verificationCode: verificationCode || '',
      })
        .then((res) => {
          FirebaseAuthentication.getIdToken().then((idToken) => {
            resolve({
              phoneNumber: res.user?.phoneNumber,
              accessToken: idToken.token,
            });
          });
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      var credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode ?? '',
      );
      const auth = getAuth();

      signInWithCredential(auth, credential)
        .then((res: any) => {
          resolve({
            phoneNumber: res?.user?.phoneNumber ?? '',
            accessToken: res?.user.accessToken ?? '',
          });
          removeRecaptcha();
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};

const authStateChange = (): Promise<{
  phoneNumber: string;
  accessToken: string;
}> => {
  return new Promise(async (resolve) => {
    FirebaseAuthentication.removeAllListeners().then(() => {
      FirebaseAuthentication.addListener('authStateChange', (change) => {
        if (change.user?.phoneNumber) {
          FirebaseAuthentication.getIdToken().then((idToken) => {
            resolve({
              phoneNumber: change?.user?.phoneNumber || '',
              accessToken: idToken.token,
            });
          });
        }
      });
    });
  });
};

const renderRecaptcha = (key: string) => {
  if (!isPlatform('capacitor')) {
    let recaptcha = document.createElement('div');
    recaptcha.id = 'recaptcha-container';
    recaptcha.setAttribute('data-size', 'invisible');
    recaptcha.setAttribute('data-sitekey', key);
    document.getElementsByTagName('body')[0].appendChild(recaptcha);
  }
};

const removeRecaptcha = () => {
  let recaptcha = document.getElementById('recaptcha-container');
  recaptcha?.remove();
};

export {
  initializeFirebaseApp,
  sendPhoneNumber,
  sendVerificationCode,
  authStateChange,
  renderRecaptcha,
  removeRecaptcha,
};
