import { Preferences } from '@capacitor/preferences';

const configurePreferences = async (db_name: string) => {
  await Preferences.configure({ group: db_name });
};

const setPreference = async (key: string, value: string | object | boolean) => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

const getPreference = async (key: string): Promise<object | null | any> => {
  const { value } = await Preferences.get({ key });
  return value ? JSON.parse(value) : null;
};

const removePreference = async (key: string) => {
  return await Preferences.remove({ key });
};

const clearPreferences = async () => {
  return await Preferences.clear();
};
export {
  configurePreferences,
  setPreference,
  getPreference,
  removePreference,
  clearPreferences,
};
