import { IHome } from 'interfaces/home.interface';
import {
  GET_HOME_PENDING,
  GET_HOME_ERROR,
  GET_HOME_SUCCESS,
} from '../actions/home';

interface IInitialState {
  loading: boolean;
  loaded: boolean;
  data: IHome | null;
  error: any;
}

const initialState: IInitialState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
};

const home = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_HOME_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_HOME_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case GET_HOME_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    // Default case
    default:
      return state;
  }
};

export default home;
