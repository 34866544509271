import { App } from '@capacitor/app';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isPlatform } from '@ionic/react';
import packageJson from '../../../package.json';

const AppVersion = () => {
  const [appVersionNumber, setAppVersionNumber] = useState<string | null>(null);
  const getAppVersion = async () => {
    if (isPlatform('capacitor')) {
      const { version } = await App.getInfo();
      setAppVersionNumber(version);
    } else {
      setAppVersionNumber(packageJson.version);
    }
  };
  useEffect(() => {
    getAppVersion();
  }, []);

  return (
    <>
      {appVersionNumber && (
        <p>
          <FormattedMessage
            id="App version number: {appVersionNumber}"
            defaultMessage="App version number: {appVersionNumber}"
            values={{ appVersionNumber }}
          />
        </p>
      )}

      {packageJson?.version && (
        <p>
          <FormattedMessage
            id="Core version number: {coreVersionNumber}"
            defaultMessage="Core version number: {coreVersionNumber}"
            values={{ coreVersionNumber: packageJson?.version }}
          />
        </p>
      )}
    </>
  );
};

export default AppVersion;
