import { Dispatch } from '@reduxjs/toolkit';
import { dbKeys } from 'config/constants';
import { IUser } from 'interfaces/user.interface';
import {
  getUserInfo,
  updateUserInfo,
  deleteUser as deleteUserAPI,
} from 'api/user';

import {
  clearPreferences,
  getPreference,
  setPreference,
} from 'helpers/preferences';
import { dataInitialState } from 'store/reducers/user';
import { removeSession } from './session';
// import { IUser } from 'interfaces/user.interface';
// import { dataInitialState } from '../reducers/user';

export const GET_USER_DATA_PENDING = 'GET_USER_DATA_PENDING';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

export const getUserData = (userId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_DATA_PENDING,
    });
    try {
      const { data } = await getUserInfo(userId);
      const userData: IUser = {
        phone: data.phone,
        name: data.name,
        photo_url: data.photo_url,
        id: data.id,
        email: data.email,
        birthdate: data.birthdate,
        town: data.town,
      };
      await setPreference(dbKeys.user, userData);
      dispatch({
        type: GET_USER_DATA_SUCCESS,
        payload: userData,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_DATA_ERROR,
        payload: error,
      });
    }
  };
};

export const LOAD_USER_DATA_PENDING = 'LOAD_USER_DATA_PENDING';
export const LOAD_USER_DATA_SUCCESS = 'LOAD_USER_DATA_SUCCESS';
export const LOAD_USER_DATA_ERROR = 'LOAD_USER_DATA_ERROR';

export const loadUserDataFromLocal = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOAD_USER_DATA_PENDING,
    });
    try {
      const userData = await getPreference(dbKeys.user);
      dispatch({
        type: LOAD_USER_DATA_SUCCESS,
        payload: userData || dataInitialState,
      });
    } catch (error) {
      dispatch({
        type: LOAD_USER_DATA_ERROR,
        payload: error,
      });
    }
  };
};

export const SET_USER_DATA_PENDING = 'SET_USER_DATA_PENDING';
export const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS';
export const SET_USER_DATA_ERROR = 'SET_USER_DATA_ERROR';

export const setUserData = (data: IUser) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_DATA_PENDING,
    });
    try {
      await setPreference(dbKeys.user, data);
      dispatch({
        type: SET_USER_DATA_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SET_USER_DATA_ERROR,
        payload: error,
      });
    }
  };
};

export const REMOVE_USER_DATA_PENDING = 'REMOVE_USER_DATA_PENDING';
export const REMOVE_USER_DATA_SUCCESS = 'REMOVE_USER_DATA_SUCCESS';
export const REMOVE_USER_DATA_ERROR = 'REMOVE_USER_DATA_ERROR';

export const removeUserData = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REMOVE_USER_DATA_PENDING,
    });
    try {
      await clearPreferences();
      dispatch({
        type: REMOVE_USER_DATA_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REMOVE_USER_DATA_ERROR,
        payload: error,
      });
    }
  };
};

export const UPDATE_USER_DATA_PENDING = 'UPDATE_USER_DATA_PENDING';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_ERROR = 'UPDATE_USER_DATA_ERROR';

export const updateUserData = (userId: number, userData: IUser) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_USER_DATA_PENDING,
    });
    try {
      const { data } = await updateUserInfo(userId, userData);
      await setPreference(dbKeys.user, data);
      dispatch({
        type: UPDATE_USER_DATA_SUCCESS,
        payload: userData,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_DATA_ERROR,
        payload: error,
      });
    }
  };
};

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const deleteUser = (id: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_USER_PENDING,
    });
    try {
      await deleteUserAPI(id);
      removeUserData()(dispatch);
      dispatch(removeSession());
      dispatch({
        type: DELETE_USER_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DELETE_USER_ERROR,
        payload: error,
      });
    }
  };
};
