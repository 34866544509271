import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import messages from 'config/messages';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import { IGroup, IGroupDetail } from 'interfaces/groups.interface';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import './GroupDetail.css';
import GroupHeader from 'components/Elements/Headers/GroupHeader/GroupHeader';
import GroupSettings from './Tabs/GroupSettings/GroupSettings';
import {
  getGroupDetail,
  getGroupWeekClassification,
} from 'store/actions/groups';
import GroupCurrentCompetition from './Tabs/GroupCurrentCompetition';
import GroupWeekClassification from './Tabs/GroupWeekClassification';
import {
  closeOutline,
  ellipsisVerticalOutline,
  settingsOutline,
} from 'ionicons/icons';
import { routes } from 'config/routes';
const Segments = {
  today: 'today',
  week: 'week',
};

const GroupDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const ref = useRef();
  const intl = useIntl();
  const { loggedIn } = useAppSelector((state) => state.session);
  const group: IGroupDetail | null = useAppSelector(
    (state) => state.groups?.data?.results,
  )?.find((item: IGroup) => item.id === Number(id));
  const weekClassificationId: number = useAppSelector(
    (state) => state.home?.data?.week?.id || null,
  );

  const [segment, setSegment] = useState<string | undefined>(Segments.today);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loggedIn && id) {
      dispatch(getGroupDetail(Number(id)));
      weekClassificationId &&
        dispatch(getGroupWeekClassification(Number(id), weekClassificationId));
    }
  }, [dispatch, id, loggedIn, weekClassificationId]);

  // const [open, close] = useIonModal(GroupSettings, {
  //   group,
  //   breakPoints: [0, 0.5],
  // });

  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const openGroupSettingsModal = () => {
    setOpenSettingsModal(!openSettingsModal);
  };
  const pushRoute = usePushRoute();

  return (
    <>
      <IonPage ref={ref}>
        <GroupHeader
          title={group?.name || ''}
          photoUrl={group?.thumbnail_photo_url || ''}
          members={group?.user_amount || 0}
        >
          {!openSettingsModal && (
            <IonButton
              onClick={() =>
                pushRoute(
                  routes.moreInfo,
                  {
                    menuId: 'groupDetails',
                    title: group?.name || '',
                  },
                  `?id=${group?.id}`,
                )
              }
            >
              <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
            </IonButton>
          )}
          <IonButton onClick={openGroupSettingsModal}>
            <IonIcon
              slot="icon-only"
              icon={openSettingsModal ? closeOutline : settingsOutline}
            />
          </IonButton>
        </GroupHeader>
        <IonToolbar>
          <IonSegment
            value={segment}
            className="sticky-segment"
            mode="ios"
            onIonChange={(e: any) => {
              e?.detail?.value && setSegment(e?.detail.value);
            }}
          >
            <IonSegmentButton value={Segments.today}>
              <IonLabel>{intl.formatMessage(messages.ofTheDay)}</IonLabel>
            </IonSegmentButton>
            {group?.week_classification && (
              <IonSegmentButton value={Segments.week}>
                <IonLabel>{intl.formatMessage(messages.ofTheWeek)}</IonLabel>
              </IonSegmentButton>
            )}
          </IonSegment>
        </IonToolbar>
        <IonContent>
          <BackgroundContainer
            ionPageRef={ref}
            className="ion-padding-horizontal"
          >
            {segment === Segments.today && <GroupCurrentCompetition />}
            {segment === Segments.week && <GroupWeekClassification />}
          </BackgroundContainer>
        </IonContent>
      </IonPage>
      <>{group && <GroupSettings group={group} isOpen={openSettingsModal} />}</>
    </>
  );
};

export default GroupDetail;
