import { FC, useEffect, useState } from 'react';
import {
  ILiveQuestion,
  ILiveUserGameSelection,
} from 'interfaces/live.interface';
import { IonCard, isPlatform } from '@ionic/react';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import useTimer from 'hooks/useTimer';
import GameButton from 'components/Base/GameButton/GameButton';
import Countdown from 'components/Base/Countdown/Countdown';
import { encyptKey, liveGameFirstRoundSeconds } from 'config/constants';
import { decryptAes } from 'helpers/crypto';

interface PlayGameResolutionProps {
  question: ILiveQuestion | null;
  firstRoundUserSelection: ILiveUserGameSelection[];
  timer: number;
  questionAux: any;
  onResolutionFinished: ({
    finished,
    userSelection,
    time,
  }: {
    finished: boolean;
    userSelection: ILiveUserGameSelection[];
    time: number;
  }) => void;
  onBGChange: (className: string) => void;
}
const PlayGameResolution: FC<PlayGameResolutionProps> = ({
  firstRoundUserSelection,
  question = null,
  timer,
  questionAux,
  onResolutionFinished,
  onBGChange,
}) => {
  const intl = useIntl();
  const [resolutionAttempts, setResolutionAttempts] = useState({
    currentAttempt: 0,
    maxAttempt: 3,
  });
  const [userSelection, setUserSelection] = useState<ILiveUserGameSelection[]>(
    firstRoundUserSelection,
  );
  // showResolution: false,
  const [showErrors, setShowErrors] = useState(false);
  const [showResolutionMenu, setShowResolutionMenu] = useState(true);
  const [showResolutionScreen, setShowResolutionScreen] = useState(false);
  const toggleOption = ({
    id,
    value,
    name,
  }: {
    id: string;
    name: string;
    value: boolean;
  }) => {
    const newUseSelection = userSelection.map((item) => {
      if (item.id === id) {
        return { id, value: !value, name };
      } else {
        return item;
      }
    });
    setUserSelection(newUseSelection);
  };
  const { startTimer, stopTimer, time } = useTimer();
  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log('resolutionAttempts: ', resolutionAttempts);
  const isIosDevice = isPlatform('ios') ? 'ios-device' : '';

  const [errors, setErrors] = useState(0);
  // const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const getErrorScreen = () => {
    const aux = decryptAes(questionAux, encyptKey);
    const no = userSelection
      .filter((item) => !item.value)
      .filter((item) => !aux['0'].includes(item.name)).length;
    const yes = userSelection
      .filter((item) => item.value)
      .filter((item) => !aux['1'].includes(item.name)).length;
    const errors = no + yes;
    onBGChange(errors ? 'live-red-bg' : 'live-green-bg');
    setErrors(errors);
    setShowErrors(true);
    setShowResolutionMenu(false);
    setShowResolutionScreen(false);

    setTimeout(() => {
      setShowErrors(false);
      onBGChange('live-bg');

      setShowResolutionScreen(true);
      setShowResolutionMenu(false);
      if (!errors) {
        resolveFinished(true);
      }
    }, 1500);
  };

  useEffect(() => {
    if (resolutionAttempts.currentAttempt === resolutionAttempts.maxAttempt) {
      resolveFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolutionAttempts.currentAttempt]);

  const send = () => {
    setShowResolutionMenu(true);
    setShowErrors(false);
    setShowResolutionScreen(false);
    setResolutionAttempts((prev) => {
      return {
        ...prev,
        currentAttempt: prev.currentAttempt + 1,
      };
    });
  };

  useEffect(() => {
    if (time) {
      if (liveGameFirstRoundSeconds + 1 - timer - time === 0) {
        resolveFinished(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);
  const resolveFinished = (isResolved: boolean) => {
    onResolutionFinished({
      finished: isResolved,
      userSelection: !isResolved ? [] : userSelection,
      time: time || 0,
    });
    // initialize values
    setShowErrors(false);
    setShowResolutionScreen(false);
    setShowResolutionMenu(true);
    setResolutionAttempts({
      currentAttempt: 0,
      maxAttempt: 3,
    });
  };
  return (
    <>
      {question && (
        <div className="ion-text-center">
          {showResolutionMenu && (
            <div>
              <div className={`ion-padding-top sticky-top ${isIosDevice}`}>
                <Countdown
                  countdown={
                    liveGameFirstRoundSeconds + 1 - timer - (time || 0)
                  }
                />
                <br />
              </div>
              <div
                style={{
                  border: 0,
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                <IonCard
                  mode="ios"
                  className="resolution-button bg-red"
                  button
                  onClick={() => getErrorScreen()}
                >
                  <p>{intl.formatMessage(messages.hamaikakoa)}</p>
                  <h3>{intl.formatMessage(messages.verify)}</h3>
                  <p>
                    {`${resolutionAttempts.currentAttempt + 1}/${resolutionAttempts.maxAttempt}`}
                  </p>
                </IonCard>
                <br />
                <IonCard
                  mode="ios"
                  className="resolution-button bg-gold"
                  button
                  onClick={() => resolveFinished(true)}
                >
                  <p>{intl.formatMessage(messages.sendAnd)}</p>
                  <h3>{intl.formatMessage(messages.goToGoldQuestion)}</h3>
                </IonCard>
              </div>
            </div>
          )}
          {
            <>
              {showErrors && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div className="ion-padding center-container">
                  <h2
                    style={{
                      fontSize: '250px',
                      textShadow: '2px 2px 4px #00000040',
                    }}
                  >
                    {errors}
                  </h2>
                </div>
              )}

              {showResolutionScreen && (
                <>
                  <div className={`ion-padding-top sticky-top ${isIosDevice}`}>
                    <Countdown
                      countdown={
                        liveGameFirstRoundSeconds + 1 - timer - (time || 0)
                      }
                    />
                    <h3 className="question-type">
                      {intl.formatMessage(messages.hamaikakoa)}
                    </h3>
                    <div className="question-container">
                      <div>{question.title}</div>
                    </div>
                    <br />
                    <p
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '0 15px',
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                    >
                      <span>{intl.formatMessage(messages.no)}</span>
                      <span>{intl.formatMessage(messages.yes)}</span>
                    </p>
                    <br />
                  </div>
                  <br />

                  <div style={{ margin: '0 15px' }}>
                    {userSelection.map((item, i) => (
                      <GameButton
                        title={item.name}
                        position={item.value ? 'end' : 'start'}
                        key={i}
                        onClick={() =>
                          toggleOption({
                            id: item.id,
                            value: item.value,
                            name: item.name,
                          })
                        }
                      />
                    ))}
                  </div>

                  <div
                    style={{
                      position: 'sticky',
                      bottom: 0,
                      padding: 1,
                      backdropFilter: 'blur(15px)',
                    }}
                  >
                    <IonCard
                      mode="ios"
                      className="resolution-button bg-red"
                      button
                      style={{
                        height: 60,
                        fontSize: 20,
                      }}
                      onClick={send}
                    >
                      {intl.formatMessage(messages.verify)}
                    </IonCard>
                  </div>
                </>
              )}
            </>
          }
        </div>
      )}
    </>
  );
};
export default PlayGameResolution;
