import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import '../CenteredHeaderTitle.css';
import './UserHeader.css';
import { avatarPhoto } from 'config/constants';
export interface UserHeaderProps {
  title: string;
  photoUrl: string;
}

const UserHeader: React.FC<UserHeaderProps> = ({ title, photoUrl }) => {
  return (
    <IonHeader className="user-header-item  ion-no-border">
      <IonToolbar>
        <IonButtons slot="start" className="ion-no-margin ion-no-padding">
          <IonBackButton />
        </IonButtons>
        <IonItem lines="none">
          <IonAvatar>
            <img src={photoUrl || avatarPhoto} alt={title} loading="lazy" />
          </IonAvatar>
          <IonLabel className="ion-no-margin" mode="md">
            <IonTitle>{title}</IonTitle>
          </IonLabel>
        </IonItem>
      </IonToolbar>
    </IonHeader>
  );
};

export default UserHeader;
