import { updateGroupData } from 'api/groups';
import Button from 'components/Base/Button/Button';
import PhotoEditor from 'components/Utils/PhotoEditor/PhotoEditor';
import { groupPhoto } from 'config/constants';
import messages from 'config/messages';
import { useAPI, useAppDispatch, useAppSelector } from 'hooks';
import { IGroupDetail } from 'interfaces/groups.interface';
import { useIntl } from 'react-intl';
import './GroupAdminSettings.css';
import Input from 'components/Base/Input/Input';
import { FormEvent, useEffect } from 'react';
import { getGroupDetail } from 'store/actions/groups';
import ShareGroupKey from './ShareGroupKey';

const GroupAdminSettings: React.FC<{ group: IGroupDetail }> = ({ group }) => {
  const intl = useIntl();
  const { id } = useAppSelector((state) => state.user.data);
  const { triggerRequest, data, loading, loaded } = useAPI();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.success && data?.updated) {
      dispatch(getGroupDetail(Number(group.id)));
    }
  }, [data, loading, loaded, dispatch, group.id]);
  const submitUpdateGroupData = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      'group-name': { value: string };
      // 'group-text': { value: string };
    };
    const groupName = formElements['group-name'].value || '';
    // const groupText = formElements['group-text'].value || '';

    groupName &&
      triggerRequest(updateGroupData, {
        name: groupName,
        // text: groupText,
        id: group.id,
      });
  };
  return (
    <>
      {group?.admin?.id === id && (
        <>
          <div className="group-admin-settings-container">
            <PhotoEditor
              currentPhotoUrl={group.photo_url || groupPhoto}
              groupId={group.id}
            />

            <form
              className="ion-padding-horizontal"
              onSubmit={submitUpdateGroupData}
            >
              <Input
                label={intl.formatMessage(messages.groupName)}
                value={group.name}
                name="group-name"
              />
              <br />
              {/* <IonTextarea
                fill="outline"
                maxlength={200}
                counter={true}
                label-placement="floating"
                label={intl.formatMessage(messages.groupDescription)}
                placeholder={intl.formatMessage(
                  messages.writeHereGroupDescription,
                )}
                autoGrow={true}
                value={group.group_text}
                name="group-text"
              ></IonTextarea>
              <br /> */}
              <Button title={intl.formatMessage(messages.save)} type="submit" />
            </form>
          </div>
          <ShareGroupKey groupKey={group.group_minified_hash} />
        </>
      )}
    </>
  );
};

export default GroupAdminSettings;
