import {
  gameController,
  gameControllerOutline,
  home,
  homeOutline,
  people,
  peopleOutline,
  trophy,
  trophyOutline,
} from 'ionicons/icons';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  classification: {
    id: 'Classification',
    defaultMessage: 'Classification',
  },
  groups: {
    id: 'Groups',
    defaultMessage: 'Groups',
  },
  games: {
    id: 'Games',
    defaultMessage: 'Games',
  },
});

export interface ITab {
  icon: string;
  fillIcon: string;
  label: { id: string; defaultMessage: string };
  tab: string;
}

export const tabs: ITab[] = [
  {
    icon: homeOutline,
    fillIcon: home,
    label: messages.home,
    tab: 'home',
  },
  {
    icon: trophyOutline,
    fillIcon: trophy,
    label: messages.classification,
    tab: 'classification',
  },
  {
    icon: peopleOutline,
    fillIcon: people,
    label: messages.groups,
    tab: 'groups',
  },
  {
    icon: gameControllerOutline,
    fillIcon: gameController,
    label: messages.games,
    tab: 'games',
  },
];
