import { useEffect, useRef } from 'react';
import { useAPI, usePushRoute } from 'hooks';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonToolbar,
  RefresherEventDetail,
} from '@ionic/react';
import { routes } from 'config/routes';
import { IPreviousWeek } from 'interfaces/classification.interface';
import { useIntl } from 'react-intl';
import Header from 'components/Elements/Headers/Header/Header';
import messages from 'config/messages';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import { getGroupWeeks } from 'api/groups';

const GroupPreviousWeeks: React.FC = () => {
  const intl = useIntl();
  const params = new URL(window.location.href).searchParams;
  const id = params.get('id');

  const { triggerRequest, data, loading, loaded } = useAPI();

  const pushRoute = usePushRoute();

  useEffect(() => {
    if (id) triggerRequest(getGroupWeeks, id);
  }, [id, triggerRequest]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    if (id) triggerRequest(getGroupWeeks, id);
    event.detail.complete();
  };
  const ref = useRef();

  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.previousWeeks)}>
        <IonToolbar>
          <IonButtons slot="start" className="ion-no-margin ion-no-padding">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {loading && !loaded && (
            <div className="ion-text-center">
              <IonSpinner color="secondary" style={{ zoom: 2 }} />
            </div>
          )}
          {data &&
            data.map((week: IPreviousWeek, i: number) => (
              <IonCard
                mode="ios"
                className="ion-no-margin ion-margin-bottom"
                button
                key={i}
                onClick={() =>
                  pushRoute(routes.groupDetailPreviousWeekDetail, {
                    weekId: week.id,
                    id: id || '',
                  })
                }
              >
                <IonCardContent>
                  <h1 className="ion-no-margin">
                    <strong>{week.name}</strong>
                  </h1>
                </IonCardContent>
              </IonCard>
            ))}
          <br />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default GroupPreviousWeeks;
