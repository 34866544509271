import {
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { getGroupClassification } from 'api/groups';
import { useAPI, useAppSelector } from 'hooks';
import ClassificationLayout from 'pages/Classification/ClassificationLayout';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';

const GroupCurrentCompetition: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: classificationData, triggerRequest } = useAPI();

  const competitionId = useAppSelector((state) =>
    state.home?.data?.today
      ? state.home?.data?.today?.id
      : state.home?.data?.last || null,
  );

  const getGroupClassificationHandler = useCallback(() => {
    competitionId &&
      id &&
      triggerRequest(getGroupClassification, id, competitionId);
  }, [competitionId, id, triggerRequest]);

  useEffect(() => {
    getGroupClassificationHandler();
  }, [getGroupClassificationHandler]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    getGroupClassificationHandler();
    event.detail.complete();
  };
  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      <ClassificationLayout
        date={classificationData?.date || ''}
        classification={classificationData?.classification || []}
        msg={classificationData?.msg}
        userPosition={classificationData?.user_position}
      />
    </>
  );
};

export default GroupCurrentCompetition;
