import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Input from 'components/Base/Input/Input';
import PhoneInput from 'components/Base/PhoneInput/PhoneInput';

interface SendPhoneNumberProps {
  onChange: ({
    userName,
    phoneNumber,
  }: {
    userName: string;
    phoneNumber: string;
  }) => void;
}

const SendPhoneNumber: React.FC<SendPhoneNumberProps> = ({ onChange }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const intl = useIntl();

  const nameLabel = intl.formatMessage({
    id: 'Name',
    defaultMessage: 'Name',
  });

  useEffect(() => {
    if (phoneNumber) {
      onChange({
        userName,
        phoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, userName]);

  return (
    <div className="ion-padding-top">
      <Input
        label={nameLabel}
        onChange={(userName) => setUserName(userName || '')}
      />
      <br />
      <PhoneInput onChange={(phoneNumber) => setPhoneNumber(phoneNumber)} />
    </div>
  );
};

export default SendPhoneNumber;
