import { Share } from '@capacitor/share';

const share = async ({
  title,
  text,
  url,
}: {
  title: string;
  text: string;
  url: string;
}) => {
  await Share.share({
    title,
    text,
    url,
  });
};

export { share };
