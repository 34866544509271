import { FC, useEffect, useState } from 'react';
import {
  ILiveQuestion,
  ILiveUserGameSelection,
} from 'interfaces/live.interface';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import useTimer from 'hooks/useTimer';
import { liveGameSecondRoundSeconds } from 'config/constants';
import GameButton from 'components/Base/GameButton/GameButton';
import Countdown from 'components/Base/Countdown/Countdown';
import { isPlatform } from '@ionic/react';

interface PlayGameSecondRoundProps {
  firstRoundUserSelection: ILiveUserGameSelection[] | null;
  question: ILiveQuestion | null;

  onFinishRound: ({
    finished,
    userSelectionIds,
    time,
  }: {
    finished: boolean;
    userSelectionIds: string[];
    time: number;
  }) => void;
}
const PlayGameSecondRound: FC<PlayGameSecondRoundProps> = ({
  firstRoundUserSelection = null,
  question,
  onFinishRound,
}) => {
  const intl = useIntl();
  const [userSelectionIds, setUserSelectionIds] = useState<string[]>([]);

  const selectOption = (id: string) => {
    if (userSelectionIds.length <= 2 && !userSelectionIds.includes(id)) {
      setUserSelectionIds([...userSelectionIds, id]);
    }
  };

  const [countDown, setCountDown] = useState(5);
  const startCountDown = () => {
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev - 1 <= 1) {
          startTimer();
        }
        if (prev - 1 <= 0) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const { startTimer, stopTimer, time } = useTimer();
  useEffect(() => {
    startCountDown();
    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userSelectionIds.length === 2) {
      stopTimer();
      onFinishRound({
        finished: true,
        userSelectionIds: userSelectionIds,
        time: time || 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, userSelectionIds]);

  useEffect(() => {
    if (time) {
      if (liveGameSecondRoundSeconds + 1 - time === 0) {
        onFinishRound({
          finished: false,
          userSelectionIds: [],
          time: time || 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const selectedInFirstRound = (optionName: string) => {
    return firstRoundUserSelection?.find((item) => item.name === optionName)
      ?.value;
  };

  const isIosDevice = isPlatform('ios') ? 'ios-device' : '';

  return (
    <>
      {countDown > 0 ? (
        <div className="center-container ion-padding-horizontal">
          <h3 className="question-type">
            {intl.formatMessage(messages.goldQuestion)}
          </h3>
          {question?.title && (
            <div className="question-container gold">
              <div>{question.title}</div>
            </div>
          )}
          <div className="countdown">{countDown}</div>
        </div>
      ) : (
        ''
      )}

      {countDown === 0 && question && (
        <div className="ion-text-center">
          <div className={`ion-padding-top sticky-top ${isIosDevice}`}>
            {time && (
              <Countdown countdown={liveGameSecondRoundSeconds + 1 - time} />
            )}
            <h3 className="question-type">
              {intl.formatMessage(messages.goldQuestion)}
            </h3>
            <div className="question-container gold">
              <div>{question.title}</div>
            </div>
            <p>{intl.formatMessage(messages.select2ByClickingOnIt)}</p>
          </div>
          <div style={{ margin: '0 10px' }} className="scrolling-box">
            <br />
            {question.options?.map((item, i) => (
              <GameButton
                title={item.name}
                position={selectedInFirstRound(item.name) ? 'end' : 'start'}
                key={i}
                selected={userSelectionIds.includes(item.id)}
                onClick={() => selectOption(item.id)}
                disabled={
                  userSelectionIds.length >= 2 &&
                  !userSelectionIds.includes(item.id)
                }
              />
            ))}
          </div>
          <br />
        </div>
      )}
    </>
  );
};
export default PlayGameSecondRound;
