import { FC, createRef, useEffect, useState } from 'react';
import './NumberInput.css';

interface NumberInputProps {
  numberOfInputs?: number;
  /**
   * Optional click handler
   */
  onComplete?: (e: string) => void;
}
const NumberInput: FC<NumberInputProps> = ({
  numberOfInputs = 6,
  onComplete,
}) => {
  const [inputRefsArray] = useState<any>(() =>
    Array.from({ length: numberOfInputs }, () => createRef()),
  );
  const [, setCurrentIndex] = useState(0);

  const [letters, setLetters] = useState<string[]>(() =>
    Array.from({ length: numberOfInputs }, () => ''),
  );
  const noneEmpty = (arr: string[]) => {
    return arr.filter((item) => item !== '');
  };
  useEffect(() => {
    if (noneEmpty(letters).length === numberOfInputs) {
      onComplete && onComplete(letters.join(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letters]);

  const handleKeyPress = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = prevIndex < numberOfInputs - 1 ? prevIndex + 1 : 0;
      const nextInput: any = inputRefsArray?.[nextIndex]?.current;
      nextInput.focus();
      // nextInput.select();
      return nextIndex;
    });
  };

  useEffect(() => {
    if (inputRefsArray?.[0]?.current) {
      inputRefsArray?.[0]?.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRefsArray]);

  return (
    <div className="number-input-container">
      {inputRefsArray.map((ref: any, index: any) => {
        return (
          <input
            ref={ref}
            type="number"
            className="number-input"
            id={`box-${index}-1`}
            key={`box-${index}-1`}
            onChange={(e) => {
              const { value } = e.target;
              setLetters((letters) =>
                letters.map((letter, letterIndex) =>
                  letterIndex === index ? value : letter,
                ),
              );
              handleKeyPress();
            }}
            onClick={(e: any) => {
              setCurrentIndex(index);
              e.target.select();
            }}
            value={letters[index]}
            max={'1'}
          />
        );
      })}
    </div>
  );
};

export default NumberInput;
