import { Dispatch } from '@reduxjs/toolkit';
import {
  getGroups as getGroupsRequest,
  getGroupDetail as getGroupDetailRequest,
  getGroupWeekClassification as getGroupWeekClassificationRequest,
} from '../../api/groups';

export const GET_GROUPS_PENDING = 'GET_GROUPS_PENDING';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';
export const getGroups = () => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_GROUPS_PENDING,
    });
    try {
      const { data } = await getGroupsRequest();
      dispatch({
        type: GET_GROUPS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUPS_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_GROUP_DETAIL_PENDING = 'GET_GROUP_DETAIL_PENDING';
export const GET_GROUP_DETAIL_SUCCESS = 'GET_GROUP_DETAIL_SUCCESS';
export const GET_GROUP_DETAIL_ERROR = 'GET_GROUP_DETAIL_ERROR';
export const getGroupDetail = (id: number) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_GROUP_DETAIL_PENDING,
    });
    try {
      const { data } = await getGroupDetailRequest(id);
      dispatch({
        type: GET_GROUP_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUP_DETAIL_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_GROUP_WEEK_CLASSIFICATION_PENDING =
  'GET_GROUP_WEEK_CLASSIFICATION_PENDING';
export const GET_GROUP_WEEK_CLASSIFICATION_SUCCESS =
  'GET_GROUP_WEEK_CLASSIFICATION_SUCCESS';
export const GET_GROUP_WEEK_CLASSIFICATION_ERROR =
  'GET_GROUP_WEEK_CLASSIFICATION_ERROR';

export const getGroupWeekClassification = (groupId: number, weekId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_GROUP_WEEK_CLASSIFICATION_PENDING,
    });
    try {
      const { data } = await getGroupWeekClassificationRequest(groupId, weekId);
      dispatch({
        type: GET_GROUP_WEEK_CLASSIFICATION_SUCCESS,
        payload: data,
        groupId,
      });
    } catch (error) {
      dispatch({
        type: GET_GROUP_WEEK_CLASSIFICATION_ERROR,
        payload: error,
      });
    }
  };
};
