import {
  SIGN_UP_TO_COMPETITION_PENDING,
  SIGN_UP_TO_COMPETITION_ERROR,
  SIGN_UP_TO_COMPETITION_SUCCESS,
  SEND_USER_GAME_PENDING,
  SEND_USER_GAME_ERROR,
  SEND_USER_GAME_SUCCESS,
  EMPTY_LIVE,
  END_OF_TIME,
} from '../actions/live';
import { ILiveUserCompetition } from 'interfaces/live.interface';

interface IInitialState {
  liveCompetition: {
    competition: ILiveUserCompetition | null;
    requestStatus: {
      loading: boolean;
      loaded: boolean;
      error: any;
    };
  };
  liveSendUserGame: {
    success: boolean;
    msg: string;
    loading: boolean;
    loaded: boolean;
    error: any;
    endOfTime: boolean;
  };
}

const initialState: IInitialState = {
  liveCompetition: {
    competition: null,
    requestStatus: {
      loaded: false,
      loading: false,
      error: null,
    },
  },
  liveSendUserGame: {
    success: false,
    msg: '',
    loaded: false,
    loading: false,
    error: null,
    endOfTime: false,
  },
};

const live = (state = initialState, action: any) => {
  switch (action.type) {
    case SIGN_UP_TO_COMPETITION_PENDING:
      return {
        ...state,
        liveCompetition: {
          ...state.liveCompetition,
          requestStatus: {
            loading: true,
            loaded: false,
            error: null,
          },
        },
      };
    case SIGN_UP_TO_COMPETITION_ERROR:
      return {
        ...state,
        liveCompetition: {
          ...state.liveCompetition,
          requestStatus: {
            error: action.payload,
            loading: false,
            loaded: false,
          },
        },
      };
    case SIGN_UP_TO_COMPETITION_SUCCESS:
      return {
        ...state,
        liveCompetition: {
          competition: action.payload,
          requestStatus: {
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };

    case SEND_USER_GAME_PENDING:
      return {
        ...state,
        liveSendUserGame: {
          ...state.liveSendUserGame,
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case SEND_USER_GAME_ERROR:
      return {
        ...state,
        liveSendUserGame: {
          ...state.liveSendUserGame,
          error: action.payload,
          loading: false,
          loaded: false,
        },
      };
    case SEND_USER_GAME_SUCCESS:
      return {
        ...state,
        liveSendUserGame: {
          ...state.liveSendUserGame,
          success: action.payload.success,
          msg: action.payload.msg,
          loading: false,
          loaded: true,
          error: null,
        },
        liveCompetition: {
          ...state.liveCompetition,
          competition: {
            ...state.liveCompetition.competition,
            game_id: action.payload.game_id,
          },
        },
      };

    case EMPTY_LIVE:
      return initialState;

    case END_OF_TIME:
      return {
        ...state,
        liveSendUserGame: {
          ...state.liveSendUserGame,
          endOfTime: true,
        },
      };
    // Default case
    default:
      return state;
  }
};

export default live;
