import { IGameDetailRequest } from 'interfaces/game.interface';
import {
  GET_GAMES_PENDING,
  GET_GAMES_ERROR,
  GET_GAMES_SUCCESS,
  GET_GAME_DETAIL_PENDING,
  GET_GAME_DETAIL_ERROR,
  GET_GAME_DETAIL_SUCCESS,
  GET_LIVE_GAME_DETAIL_PENDING,
  GET_LIVE_GAME_DETAIL_SUCCESS,
  GET_LIVE_GAME_DETAIL_ERROR,
} from '../actions/games';

interface IInitialState {
  loading: boolean;
  loaded: boolean;
  data: any | null;
  error: any;
  detail: {
    loading: boolean;
    loaded: boolean;
    error: any;
    data: IGameDetailRequest | null;
  };
}

const initialState: IInitialState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
  detail: {
    loading: false,
    loaded: false,
    error: null,
    data: null,
  },
};

const games = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_GAMES_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_GAMES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case GET_GAMES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };

    case GET_GAME_DETAIL_PENDING:
    case GET_LIVE_GAME_DETAIL_PENDING:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
          loaded: false,
        },
      };

    case GET_GAME_DETAIL_ERROR:
    case GET_LIVE_GAME_DETAIL_ERROR:
      return {
        ...state,
        detail: {
          ...state.detail,
          error: action.payload,
          loading: false,
          loaded: false,
        },
      };
    case GET_GAME_DETAIL_SUCCESS:
    case GET_LIVE_GAME_DETAIL_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          data: action.payload,
          loading: false,
          loaded: true,
        },
      };
    // Default case
    default:
      return state;
  }
};

export default games;
