import api from './api';
import { IGameDetailRequest, IGamesRequest } from 'interfaces/game.interface';

export const getGames = () => {
  return api.get<IGamesRequest>(`/games/`);
};

export const getGameDetail = (id: number) => {
  return api.get<IGameDetailRequest>(`/games/${id}/`);
};

export const getUserWeekGames = (weekId: number, userId: number) => {
  return api.get<any>(`/weeks/${weekId}/games/${userId}/`);
};
