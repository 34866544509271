import { useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { TextZoom } from '@capacitor/text-zoom';

const LockTextZoom: React.FC = () => {
  const setTextZoom = async () => {
    await TextZoom.set({ value: 1.0 });
  };

  useEffect(() => {
    if (isPlatform('capacitor') && !isPlatform('ipad')) {
      setTextZoom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LockTextZoom;
