import { useCallback } from 'react';
import { getPreference, setPreference } from '../helpers/preferences';
import { updateUserNotificationToken } from '../helpers/notifications';
import { dbKeys, updateNotificationTokenDaysInterval } from 'config/constants';

const useUpdateFCMToken = () => {
  const getLastDBDate = () => {
    return new Promise<number>((resolve) => {
      getPreference(dbKeys.updateFCMTokenTimestamp).then((res) => {
        if (res) {
          resolve(Number(new Date(String(res))));
        } else {
          const initializeTimeMinuteAgo = Number(
            new Date(
              new Date().getTime() +
                updateNotificationTokenDaysInterval * (1000 * 60 * 60 * 24),
            ),
          );
          resolve(initializeTimeMinuteAgo);
        }
      });
    });
  };

  const setLastDBDate = async (value: string) => {
    return await setPreference(dbKeys.updateFCMTokenTimestamp, value);
  };

  const checkAndUpdateFCMToken = useCallback(async () => {
    let dateNow: number = Number(new Date());
    let notification_token_update_post_timestamp = await getLastDBDate();
    const difference_in_milliseconds = Math.round(
      notification_token_update_post_timestamp - dateNow,
    );
    const diffDays =
      Math.round(difference_in_milliseconds / (1000 * 60 * 60 * 24)) + 1;

    if (diffDays >= updateNotificationTokenDaysInterval || diffDays <= -1) {
      const date_now = new Date();
      updateUserNotificationToken();
      setLastDBDate(date_now.toDateString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { checkAndUpdateFCMToken };
};

export default useUpdateFCMToken;
