import axios from 'axios';
import { firebaseRealTimeDatabase } from 'config/constants';
const instance = axios.create({
  baseURL: firebaseRealTimeDatabase,
  headers: {
    Accept: 'application/json',
  },
});

export default instance;
