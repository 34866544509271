import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  useIonToast,
} from '@ionic/react';
import messages from 'config/messages';
import { clipboardOutline, shareOutline } from 'ionicons/icons';
import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import '../../../../AddGroupModal/AddGroupModal.css';
import { writeToClipboard } from 'helpers/clipboard';
import { share } from 'helpers/share';
import { deepLinkGroupHashLink } from 'config/constants';

const AddGroupModal: FC<{ groupKey: string }> = ({ groupKey }) => {
  const intl = useIntl();
  const modal = useRef<HTMLIonModalElement>(null);
  const [createGroupModal, setCreateGroupModal] = useState<boolean>(false);

  const dismiss = () => {
    modal.current?.dismiss();
  };

  const [presentToast] = useIonToast();
  const copyToClipboard = async () => {
    await writeToClipboard(groupKey);
    presentToast({
      duration: 3000,
      message: intl.formatMessage(messages.groupKeyCopiedToClipboard),
      mode: 'ios',
      color: 'primary',
    });
    dismiss();
  };

  const shareGroupKey = () => {
    share({
      title: intl.formatMessage(messages.shareGroupKey),
      text: intl.formatMessage(messages.shareGroupLinkMessage, {
        groupKey: groupKey,
      }),
      url: deepLinkGroupHashLink + groupKey,
    });
    dismiss();
  };

  return (
    <>
      <IonCard
        mode="ios"
        className="ion-no-margin ion-margin-top"
        button
        onClick={() => setCreateGroupModal(true)}
      >
        <p className="ion-text-center">
          {intl.formatMessage(messages.shareGroupKey, {
            groupKey: <strong>{groupKey}</strong>,
          })}
        </p>
      </IonCard>
      <IonModal
        isOpen={createGroupModal}
        ref={modal}
        className="bottom-modal"
        onDidDismiss={() => {
          setCreateGroupModal(false);
        }}
      >
        <div className="ion-padding-horizontal">
          <h2>
            {intl.formatMessage(messages.shareGroupKey, {
              groupKey: <strong>{groupKey}</strong>,
            })}
          </h2>
          <IonGrid className="ion-no-padding">
            <IonRow className="new-group-modal-button">
              <IonCol size="6">
                <IonButton
                  mode="ios"
                  className="new-group-modal-button button-1"
                  onClick={copyToClipboard}
                >
                  <div>
                    <IonIcon icon={clipboardOutline} />
                    <span style={{ paddingTop: '5px' }}>
                      {intl.formatMessage(messages.copyToClipboard)}
                    </span>
                  </div>
                </IonButton>
              </IonCol>
              <IonCol size="6">
                <IonButton
                  mode="ios"
                  className="new-group-modal-button  button-2"
                  onClick={shareGroupKey}
                >
                  <div>
                    <IonIcon icon={shareOutline} />
                    <span style={{ paddingTop: '5px' }}>
                      {intl.formatMessage(messages.share)}
                    </span>
                  </div>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonModal>
    </>
  );
};
export default AddGroupModal;
