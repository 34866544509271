import {
  IonButton,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
} from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';
import messages from 'config/messages';
import { useIntl } from 'react-intl';
import './PhoneInput.css';
import { useAPI } from 'hooks';
import { getPhonePrefixes } from 'api/session';
import { PhonePrefix } from 'interfaces/phone.interface';

interface InputProps {
  /**
   * Optional click handler
   */
  onChange?: (e: string) => void;
}
const PhoneInput: FC<InputProps> = ({ onChange }) => {
  const intl = useIntl();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { loading, loaded, triggerRequest, data } = useAPI();
  const [phonePrefixes, setPhonePrefixes] = useState<{
    filter: PhonePrefix[] | [];
    data: PhonePrefix[];
    loading: boolean;
  }>({
    filter: [],
    data: [],
    loading: true,
  });

  useEffect(() => {
    triggerRequest(getPhonePrefixes);
  }, [triggerRequest]);
  const [phonePrefix, setPhonePrefix] = useState<string>('+34');
  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    onChange && onChange(phonePrefix + phoneNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, phonePrefix]);

  useEffect(() => {
    if (data) {
      setPhonePrefixes({
        filter: data,
        data: data,
        loading: false,
      });
      setPhonePrefix(data[0].prefix);
    }
  }, [data]);

  const filterPhonePrefixes = (value: string) => {
    // const value = event.target
    if (value && value.length > 0) {
      const filtered = phonePrefixes.data.filter(
        (prefix) =>
          prefix.country.toLowerCase().includes(value.toLowerCase()) ||
          prefix.prefix.toLowerCase().includes(value.toLowerCase()),
      );
      setPhonePrefixes({ ...phonePrefixes, filter: filtered });
    } else {
      setPhonePrefixes({ ...phonePrefixes, filter: phonePrefixes.data });
    }
  };
  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-phone-prefixes-modal"
        initialBreakpoint={1}
        breakpoints={[0, 0.5, 0.75, 1]}
      >
        <IonHeader className="ion-no-border ion-padding-top">
          <IonSearchbar
            onClick={() => modal.current?.setCurrentBreakpoint(1)}
            placeholder={intl.formatMessage(messages.search)}
            mode="ios"
            onIonInput={(e: any) => filterPhonePrefixes(e.target.value)}
          ></IonSearchbar>
        </IonHeader>
        <IonContent>
          <IonList inset={true}>
            {phonePrefixes?.filter?.map((item: PhonePrefix, key: number) => (
              <IonItem
                button
                key={key}
                {...(phonePrefix === item.prefix && { color: 'medium' })}
                onClick={() => {
                  setPhonePrefix(item.prefix);
                  modal.current?.setCurrentBreakpoint(0);
                }}
              >
                <IonLabel>
                  <h2>
                    <strong>{`(${item.prefix})`}</strong> {item.country}
                  </h2>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonModal>
      <div className="phone-input-container">
        <IonButton
          fill="clear"
          id="open-phone-prefixes-modal"
          disabled={loading && !loaded}
        >
          {loading && !loaded ? <IonSpinner /> : `(${phonePrefix})`}
        </IonButton>
        <input
          className="phone-input"
          placeholder={intl.formatMessage(messages.phoneNumber)}
          type="tel"
          onInput={(ev: any) => setPhoneNumber(ev.target.value)}
        />
      </div>
    </>
  );
};
export default PhoneInput;
