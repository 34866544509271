import {
  GET_GROUPS_PENDING,
  GET_GROUPS_ERROR,
  GET_GROUPS_SUCCESS,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_WEEK_CLASSIFICATION_SUCCESS,
} from '../actions/groups';

interface IInitialState {
  loading: boolean;
  loaded: boolean;
  data: any | null;
  error: any;
}

const initialState: IInitialState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
};

const groups = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_GROUPS_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GET_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
      };
    case GET_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: !state.data?.hasOwnProperty('results')
            ? [action.payload]
            : state.data?.results.map((group: any) => {
                if (group.id === action.payload.id) {
                  return {
                    ...group,
                    ...action.payload,
                  };
                }
                return group;
              }),
        },
        loading: false,
        loaded: true,
      };
    case GET_GROUP_WEEK_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: !state.data?.hasOwnProperty('results')
            ? [action.payload]
            : state.data?.results.map((group: any) => {
                if (group.id === action.groupId) {
                  return {
                    ...group,
                    week_classification: action.payload,
                  };
                }
                return group;
              }),
        },
        loading: false,
        loaded: true,
      };
    // Default case
    default:
      return state;
  }
};

export default groups;
