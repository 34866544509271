import { ComponentProps, useEffect } from 'react';
import { configurePreferences } from '../preferences';
import { dbName } from '../../config/constants';
import { useAppDispatch } from 'hooks';
import { loadUserDataFromLocal } from 'store/actions/user';
import { loadSessionFromLocal } from 'store/actions/session';
import LiveStatusController from 'pages/LiveGame/LiveStatusController';
import LoginModal from 'pages/Login/LoginModal';
import InitializeStatusBar from 'components/Utils/InitializeStatusBar';
import AppDeepLinking from 'components/Utils/AppDeepLinking';
import InitializeFirebase from 'components/Utils/InitializeAnalytics';
import PushNotificationListener from 'components/Utils/PushNotificationListener';
import useUpdateFCMToken from 'hooks/useUpdateFCMToken';
import LockScreenOrientation from 'components/Utils/LockScreenOrientation';
import LockTextZoom from 'components/Utils/LockTextZoom';

const initialLoader = (AppContainer: any) => {
  return (props: ComponentProps<any>) => {
    const dispatch = useAppDispatch();
    const { checkAndUpdateFCMToken } = useUpdateFCMToken();
    useEffect(() => {
      configurePreferences(dbName).then(() => {
        dispatch(loadUserDataFromLocal());
        dispatch(loadSessionFromLocal());
        checkAndUpdateFCMToken();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <LockTextZoom />
        <InitializeStatusBar />
        {/* <HomeAutoUpdater /> */}
        <LiveStatusController />
        <LoginModal />
        <AppDeepLinking />
        <PushNotificationListener />
        <InitializeFirebase />
        <LockScreenOrientation />
        <AppContainer {...props}>{props.children}</AppContainer>
      </>
    );
  };
};

export default initialLoader;
