import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  RefresherEventDetail,
  useIonViewWillEnter,
} from '@ionic/react';
import { routes } from '../../config/routes';
import usePushRoute from '../../hooks/usePushRoute';
import ProfileHeader from 'components/Elements/Headers/ProfileHeader/ProfileHeader';
import { ellipsisVerticalOutline, notificationsOutline } from 'ionicons/icons';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHomeInfo } from 'store/actions/home';
import { IHome } from 'interfaces/home.interface';
import { InnerHTML } from 'components/Utils';
import GameStartCountdown from './GameStartCountdown';
import './Home.css';

const Home: React.FC = () => {
  const pushRoute = usePushRoute();
  const ref = useRef();
  const dispatch = useAppDispatch();
  const { loggedIn } = useAppSelector((state) => state.session);
  const {
    data,
    loading,
    loaded,
  }: { data: IHome; loading: boolean; loaded: boolean } = useAppSelector(
    (state) => state.home,
  );

  useIonViewWillEnter(() => {
    loggedIn && dispatch(getHomeInfo());
  }, [loggedIn]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getHomeInfo());
    event.detail.complete();
  };
  return (
    <IonPage ref={ref}>
      <ProfileHeader title={'Mihiluze'}>
        <IonButton onClick={() => pushRoute(routes.notifications)}>
          <IonIcon slot="icon-only" icon={notificationsOutline} />
        </IonButton>
        <IonButton
          onClick={() =>
            pushRoute(routes.moreInfo, {
              menuId: 'home',
              title: 'Mihiluze',
            })
          }
        >
          <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
        </IonButton>
      </ProfileHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <BackgroundContainer
          ionPageRef={ref}
          showLogo
          className="ion-text-center"
        >
          {loading && !loaded && !data && (
            <IonSpinner color="secondary" style={{ zoom: 2 }} />
          )}
          {data?.control_panel?.open ? (
            <>
              {data?.content && <InnerHTML>{data.content}</InnerHTML>}
              {data?.today && <GameStartCountdown />}

              {data?.today?.contestants && (
                <IonGrid className="ion-padding-vertical">
                  <IonRow>
                    {data?.today?.contestants?.map((item, i: number) => (
                      <IonCol key={i}>
                        <IonCard className="ion-no-margin today-contestants">
                          <IonCardHeader
                            className="ion-no-padding"
                            style={{ padding: 5 }}
                          >
                            <img src={item.photo} alt={item.name} />
                            <IonCardTitle>{item.name}</IonCardTitle>
                            <IonCardSubtitle>
                              {item.description}
                            </IonCardSubtitle>
                          </IonCardHeader>
                        </IonCard>
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              )}
              {data?.week?.content && (
                <InnerHTML>{data.week.content}</InnerHTML>
              )}
            </>
          ) : (
            <div>
              <h2>{data?.control_panel?.close_title}</h2>
              <InnerHTML>{data?.control_panel?.close_text}</InnerHTML>
            </div>
          )}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Home;
