import { combineReducers } from 'redux';
import { Reducer } from '@reduxjs/toolkit';

import user from './user';
import session from './session';
import home from './home';
import classification from './classification';
import groups from './groups';
import games from './games';
import live from './live';

const rootReducer: Reducer = combineReducers({
  user,
  session,
  home,
  classification,
  groups,
  games,
  live,
});

export default rootReducer;
