import UserItem from 'components/Elements/UserItem/UserItem';
import messages from 'config/messages';
import { routes } from 'config/routes';
import { capitalizeString, isEmptyObject } from 'helpers/utils';
import { usePushRoute } from 'hooks';
import { IClassification, IClsData } from 'interfaces/classification.interface';
import { useIntl } from 'react-intl';

interface IClassificationLayout {
  name?: string;
  date?: string;
  msg?: string;
  userGame?: IClassification | IClsData;
  classification: IClassification[] | IClsData[];
  weekId?: number;
  userPosition?: string;
}
const ClassificationLayout: React.FC<IClassificationLayout> = ({
  name,
  date,
  classification = null,
  msg,
  userGame = null,
  weekId = null,
  userPosition = null,
}: any) => {
  const intl = useIntl();
  const pushRoute = usePushRoute();
  return (
    <>
      {date && (
        <>
          <p className="ion-no-margin">
            {capitalizeString(intl.formatDate(date, { month: 'long' }))}{' '}
            {intl.formatDate(date, { day: '2-digit' })}
          </p>

          <h2 className="ion-no-margin">
            {capitalizeString(intl.formatDate(date, { weekday: 'long' }))}
          </h2>
        </>
      )}
      {name && <h1 className="ion-no-margin">{name}</h1>}
      <p>
        {intl.formatMessage(messages.yourPosition, {
          userPosition: userPosition || '-',
        })}
      </p>
      {userGame && Object.keys(userGame).length !== 0 && (
        <>
          <UserItem
            name={userGame.user_name}
            position={userGame.position}
            {...((userGame.time || userGame.total_time) && { height: 'large' })}
            onClick={() =>
              weekId &&
              pushRoute(routes.userWeekGames, {
                weekId: weekId,
                userId: userGame.user_id,
                userData: JSON.stringify({
                  name: userGame.user_name,
                  photo: encodeURIComponent(userGame.thumbnail_photo_url) || '',
                }),
              })
            }
            endLabel={
              <>
                {userGame.total_points} 🏆
                {(userGame.time || userGame.total_time) && (
                  <>
                    <br />
                    {userGame.time || userGame.total_time} 🕗
                  </>
                )}
              </>
            }
            photoUrl={userGame.thumbnail_photo_url}
          />
          <h2>{intl.formatMessage(messages.theFirst20)}</h2>
          <hr />
        </>
      )}
      {!isEmptyObject(classification) && classification ? (
        classification?.map((item: any, i: number) => (
          <UserItem
            key={i}
            name={item.user_name}
            position={item.position}
            {...((item.time || item.total_time) && { height: 'large' })}
            onClick={() =>
              weekId &&
              pushRoute(routes.userWeekGames, {
                weekId: weekId,
                userId: item.user_id,
                userData: JSON.stringify({
                  name: item.user_name,
                  photo: encodeURIComponent(item.thumbnail_photo_url) || '',
                }),
              })
            }
            endLabel={
              <>
                {item.total_points} 🏆
                {(item.time || item.total_time) && (
                  <>
                    <br />
                    {item.time || item.total_time} 🕗
                  </>
                )}
              </>
            }
            photoUrl={item.thumbnail_photo_url}
          />
        ))
      ) : (
        <h3>{msg}</h3>
      )}
    </>
  );
};

export default ClassificationLayout;
