import * as CryptoJS from 'crypto-js';

const decryptAes = (encryptedMessage: string, key: string) => {
  let newkey = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.enc.Base64.parse(encryptedMessage);
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: encrypted,
  });
  const decrypted = CryptoJS.AES.decrypt(cipherParams, newkey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });

  let parsedBase64Content = CryptoJS.enc.Base64.parse(
    CryptoJS.enc.Utf8.stringify(decrypted),
  );
  let content = CryptoJS.enc.Utf8.stringify(parsedBase64Content);
  let json = JSON.parse(content);

  return json;
};

export { decryptAes };
