import { FC, useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import useTimer from 'hooks/useTimer';
import { useAppSelector } from 'hooks';
import { stopwatchOutline } from 'ionicons/icons';
import { IHome } from 'interfaces/home.interface';
import { controlPanelStatusCodes } from 'config/constants';
import useRealtimeDB from 'hooks/useRTDB';
import { useIntl } from 'react-intl';
import { normalizeDate } from 'helpers/utils';
import messages from 'config/messages';

const GameStartCountdown: FC = () => {
  const { startTimer, stopTimer, time } = useTimer(); //Trigger event every minute
  const [timeToStart, setTimeToStart] = useState<null | {
    hh: string;
    mm: string;
    ss: string;
  }>(null);
  const { data }: { data: IHome } = useAppSelector((state) => state.home);

  const controlPanel = useRealtimeDB<{
    status: 0 | 1 | 2 | 3;
  }>('controlpanel');

  const finalRoundDate = useRealtimeDB<string>(
    `competitions/${data?.today?.hash_code}/general/final_round_date`,
  );

  const usersAmount = useRealtimeDB<{ show_users: boolean; count: number }>(
    `competitions/${data?.today?.hash_code}/users_amount`,
  );

  useEffect(() => {
    if (finalRoundDate) {
      var countDownDate = new Date(finalRoundDate).getTime();
      var now = new Date().getTime();
      var distance = countDownDate - now;

      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      ).toString();
      var minutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60),
      ).toString();
      var seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
      hours.length === 1 && (hours = '0' + hours);
      minutes.length === 1 && (minutes = '0' + minutes);
      seconds.length === 1 && (seconds = '0' + seconds);

      setTimeToStart({
        hh: hours,
        mm: minutes,
        ss: seconds,
      });
      // setTimeToStart(
      //   (hours !== '00' ? hours + ':' : '') +
      //     minutes +
      //     'm ' +
      //     seconds +
      //     's barru',
      // );

      // If the count down is finished, write some text
      if (distance < 0) {
        setTimeToStart(null);
        stopTimer();
      }

      if (
        controlPanel?.status === controlPanelStatusCodes.PROGRAM_BEFORE ||
        controlPanel?.status === controlPanelStatusCodes.PROGRAM_FINISHED ||
        controlPanel?.status === controlPanelStatusCodes.GAME_STARTED
      ) {
        setTimeToStart(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, finalRoundDate, controlPanel?.status]);

  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const intl = useIntl();
  return (
    <>
      {controlPanel?.status === controlPanelStatusCodes.PROGRAM_STARTED &&
        finalRoundDate && (
          <>
            {finalRoundDate && (
              <h2 className="ion-text-danger">
                {intl.formatDate(normalizeDate(finalRoundDate), {
                  hour: '2-digit',
                  minute: '2-digit',
                  // second: '2-digit',
                })}
              </h2>
            )}
            <IonIcon
              icon={stopwatchOutline}
              style={{ zoom: 4 }}
              color="danger"
            />
          </>
        )}
      {timeToStart !== null && (
        <>
          <h2 className="ion-text-danger">
            {intl.formatMessage(messages.gameCountDown, {
              mm: timeToStart.mm,
              ss: timeToStart.ss,
            })}
          </h2>
        </>
      )}
      {usersAmount?.show_users && usersAmount.count !== 0 && (
        <div className="home-content-week">
          <h2 className="ion-no-margin">
            {intl.formatMessage(messages.usersAmount, {
              count: usersAmount.count,
            })}
          </h2>
        </div>
      )}
    </>
  );
};
export default GameStartCountdown;
