import {
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from '@ionic/react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import ClassificationLayout from '../ClassificationLayout';
import { IWeekClassification } from 'interfaces/classification.interface';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import { getWeekClassification } from 'store/actions/classification';

const WeekClassification: React.FC = () => {
  const { week }: { week: IWeekClassification } = useAppSelector(
    (state) => state.classification.data,
  );
  const { loading, loaded }: { loading: boolean; loaded: boolean } =
    useAppSelector((state) => state.classification);
  const weekClassificationID: number = useAppSelector(
    (state) => state.home?.data?.week?.id || null,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (weekClassificationID) {
      dispatch(getWeekClassification(Number(weekClassificationID)));
    }
  }, [dispatch, weekClassificationID]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getWeekClassification(Number(weekClassificationID)));
    event.detail.complete();
  };
  const intl = useIntl();

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      {week ? (
        <ClassificationLayout
          name={week?.name}
          classification={week?.cls_data}
          userPosition={week?.user_position}
          weekId={week?.id}
          userGame={week?.user_cls}
        />
      ) : (
        <>
          {!loaded && !loading ? (
            <h3>
              {intl.formatMessage(messages.theWeeklyRankingHasNotStartedYet)}
            </h3>
          ) : (
            <div className="ion-text-center">
              <IonSpinner color="secondary" style={{ zoom: 2 }} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WeekClassification;
