import { IAppStoresUrls } from 'interfaces/config.interface';
import { FirebaseConfig } from 'interfaces/firebase.interface';
import { isPlatform } from '@ionic/react';

// export const domainPath: string = 'https://mhlzback.com';
// export const apiPath: string = `${domainPath}/api`;
export const DOMAIN = `https://api.eitb.eus/`;
const DEV_DOMAIN = 'https://mhlz.korpoweb.com/api';

export const apiPath: string =
  process.env.REACT_APP_DEV_MODE === 'true'
    ? DEV_DOMAIN
    : `${DOMAIN}${isPlatform('android') ? 'android' : 'ios'}_mihiluze`;

// export const apiPath: string = `https://api.eitb.eus/android_mihiluze`;

export const dbName: string = 'mhlz';

export const dbKeys = {
  login: 'LOGIN',
  user: 'user',
  notifications: 'notifications',
  jwt: 'jwt',
  updateFCMTokenTimestamp: 'update_fcm_token_timestamp',
};

export const recaptchaSiteKey: string =
  '6Ld-HOsnAAAAABplH5JYXrBCIAYPJ2NLEkUPjsOh';

const prodFirebaseRealTimeDatabase =
  'https://mihiluze-app-default-rtdb.europe-west1.firebasedatabase.app';

const devFirebaseRealTimeDatabase =
  'https://mihiluze-app-test.europe-west1.firebasedatabase.app';

export const firebaseRealTimeDatabase: string =
  process.env.REACT_APP_DEV_MODE === 'true'
    ? devFirebaseRealTimeDatabase
    : prodFirebaseRealTimeDatabase;
export const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyCb5_WYMCMG3eml7fKzEOo_fS5CRXq4lwc',
  authDomain: 'mihiluze-app.firebaseapp.com',
  projectId: 'mihiluze-app',
  storageBucket: 'mihiluze-app.appspot.com',
  messagingSenderId: '192100424625',
  appId: '1:192100424625:web:172ec3a50acde464a7fb90',
  measurementId: 'G-2YKMXWFF3F',
  databaseURL: firebaseRealTimeDatabase,
};

export const userDefaultPhoto = '/assets/icon/user.svg';

// export const bannerPhoto = '/assets/icon/banner.svg';

export const bannerPhoto = '/assets/icon/banner_figma.svg';
export const groupPhoto = '/assets/icon/group.svg';
export const avatarPhoto = '/assets/icon/avatar.svg';

export const appStoresUrls: IAppStoresUrls = {
  android:
    'https://play.google.com/store/apps/details?id=com.eitb.mihiluze&hl=eu',
  ios: '',
};

export const liveGameFirstRoundSeconds = 120;
export const liveGameSecondRoundSeconds = 20;

export const requestGameDetailAfterPlayRandomTimeout = [20, 60]; //20 eta 60 segundu artean

// export const liveGameStatusInterval = 6000;

export const statusCodes = {
  HASI_GABE: 0,
  HASTERA: 1,
  HASITA: 2,
  BUKATUTA: 3,
};
export const controlPanelStatusCodes = {
  PROGRAM_BEFORE: 0,
  PROGRAM_STARTED: 1,
  GAME_STARTED: 2,
  PROGRAM_FINISHED: 3,
};

export const statusBarColor: string = '#026dce';

export const deepLinkGroupHashLink: string =
  'https://mhlz.eus/taldearen-giltza=';

export const updateNotificationTokenDaysInterval: number = 7;

export const encyptKey: string = '£2cFj0S$6+>#oe8';
