import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import { getUserWeekGames } from 'api/games';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import GameItem from 'components/Elements/GameItem/GameItem';
import UserHeader from 'components/Elements/Headers/UserHeader/UserHeader';
import { useAPI } from 'hooks';
import { IGameDetailRequest } from 'interfaces/game.interface';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router';

const UserWeekGames: React.FC = () => {
  const ref = useRef();
  const { userId, weekId, userData } = useParams<{
    userId: string;
    weekId: string;
    userData: string;
  }>();

  const {
    data,
    triggerRequest,
    loading,
    loaded,
  }: {
    data: any;
    triggerRequest: (f: any, p1: number, p2: number) => void;
    loading: boolean;
    loaded: boolean;
  } = useAPI();

  useEffect(() => {
    triggerRequest(getUserWeekGames, Number(weekId), Number(userId));
  }, [triggerRequest, userId, weekId]);

  const getUserPhotoUrl = () => {
    const userPhoto = decodeURIComponent(JSON.parse(userData).photo);
    if (userPhoto !== 'null') {
      return userPhoto;
    } else {
      return '';
    }
  };

  return (
    <IonPage ref={ref}>
      <UserHeader
        title={JSON.parse(userData).name || ''}
        photoUrl={getUserPhotoUrl()}
      />

      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          {loading && !loaded && !data && (
            <div className="ion-text-center">
              <IonSpinner color="secondary" style={{ zoom: 2 }} />
            </div>
          )}
          {data &&
            data.map((item: IGameDetailRequest, key: number) => (
              <GameItem
                key={key}
                date={item.date}
                duration={item.time}
                correct1={item.correct_1}
                correct2={item.correct_2}
                points={item.total_points}
                className="ion-margin-bottom"
              />
            ))}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default UserWeekGames;
