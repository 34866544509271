/* eslint-disable import/no-unresolved */
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { useState, useRef, useEffect } from 'react';
import { InnerHTML } from '../../Utils';
import { closeOutline } from 'ionicons/icons';
import { useAPI } from '../../../hooks';
import './WelcomePagesModal.css';
import { useIntl } from 'react-intl';
import { register } from 'swiper/element/bundle';
import { getWelcomePages } from '../../../api/welcomePages';
import { isPlatform } from '@ionic/react';
import { bannerPhoto } from 'config/constants';
import Button from 'components/Base/Button/Button';
import messages from 'config/messages';

register();

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'swiper-container':
        | React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLElement>,
            HTMLElement
          >
        | any;
      'swiper-slide': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}
const WelcomePagesModal = () => {
  const swiperElRef = useRef(null);
  const { data, triggerRequest } = useAPI();
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  useEffect(() => {
    if (data && data?.results?.length !== 0) {
      setIsOpen(true);
    }
  }, [data]);
  useEffect(() => {
    triggerRequest(getWelcomePages);
  }, [triggerRequest]);

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false} className="full-modal">
      <IonContent className="ion-padding welcome-page">
        <div className="ion-text-center">
          <img src={bannerPhoto} alt="Mihiluze" />
        </div>
        <IonFab
          vertical="top"
          horizontal="start"
          style={isPlatform('ios') ? { top: '1.5em' } : {}}
        >
          <IonFabButton
            size="small"
            onClick={() => setIsOpen(false)}
            mode="ios"
            className="modal-close-button"
          >
            <IonIcon icon={closeOutline} />
          </IonFabButton>
        </IonFab>
        <swiper-container
          ref={swiperElRef}
          slides-per-view="1"
          pagination="true"
        >
          {data?.results?.map((item: any, i: number) => (
            <swiper-slide key={i}>
              <InnerHTML>{item.content}</InnerHTML>
              {data?.results?.length === i + 1 && (
                <div className="welcome-page-close-button">
                  <Button
                    size="large"
                    onClick={() => setIsOpen(false)}
                    title={intl.formatMessage(messages.start)}
                  />
                </div>
              )}
            </swiper-slide>
          ))}
        </swiper-container>
      </IonContent>
    </IonModal>
  );
};
export default WelcomePagesModal;
