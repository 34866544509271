import { IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './Routes';
import { IntlProvider } from 'react-intl';
import { availableLanguages, languageCode } from './config/language';
// import '@fontsource/archivo';
import './formatjs-polyfills';
import 'animate.css';
// Supports weights 100-900
import '@fontsource-variable/archivo';
import './theme/variables.css';
import './theme/global.css';

setupIonicReact({
  mode: 'md',
  swipeBackEnabled: isPlatform('ios') ? true : false,
});

const App: React.FC = () => (
  <IntlProvider
    locale={languageCode}
    messages={availableLanguages[languageCode].messages}
  >
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <Routes />
        </IonReactRouter>
      </IonApp>
    </Provider>
  </IntlProvider>
);

export default App;
