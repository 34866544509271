import { IonButton, IonSpinner } from '@ionic/react';
import { FC } from 'react';
import './Button.css';

interface ButtonProps {
  title: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  loading?: boolean;
  id?: string;
  size?: 'small' | 'default' | 'large' | undefined;
}
const Button: FC<ButtonProps> = ({
  onClick,
  title,
  disabled = false,
  loading = false,
  type = 'button',
  id,
  size = 'large',
}) => {
  return (
    <IonButton
      onClick={onClick}
      expand="block"
      // size="large"
      className="mhlz-button"
      disabled={disabled}
      type={type}
      size={size}
      {...(id && { id })}
    >
      {loading ? <IonSpinner /> : title}
    </IonButton>
  );
};
export default Button;
