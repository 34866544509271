import { defineMessages } from 'react-intl';
const messages = defineMessages({
  welcome: {
    id: 'Welcome!',
    defaultMessage: 'Welcome!',
  },
  signUpMessage: {
    id: 'Sign up to participate in the Mihiluze competition',
    defaultMessage: 'Sign up to participate in the Mihiluze competition',
  },
  IReadPrivacyPolicy: {
    id: 'I have read the terms and conditions and I accept them.',
    defaultMessage:
      'I have read the <strong>terms and conditions</strong> and I accept them.',
  },
  IReadTermsOfUse: {
    id: 'I have read the terms of use and I accept them',
    defaultMessage:
      'I have read the <strong>terms of use</strong> and I accept them.',
  },
  consultOutPrivacyPolicy: {
    id: 'Consult our privacy policies',
    defaultMessage: 'Consult our privacy policies',
  },
  consultOutPrivacyPolicyDescription: {
    id: 'Consult our privacy policy, our terms of use and our cookie policy at these links.',
    defaultMessage:
      'Consult our privacy policy, our terms of use and our cookie policy at these links.',
  },
  termsOfUse: {
    id: 'Terms of use',
    defaultMessage: 'Terms of use',
  },
  IAgreeToReceiveCommercialCommunications: {
    id: 'I agree to receive commercial communications from the organization',
    defaultMessage:
      'I agree to receive <strong>commercial communications</strong> from the organization',
  },
  commercialCommunications: {
    id: 'Commercial communications',
    defaultMessage: 'Commercial communications',
  },
  phoneNumber: {
    id: 'Phone number',
    defaultMessage: 'Phone number',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  classification: {
    id: 'Classification',
    defaultMessage: 'Classification',
  },
  groups: {
    id: 'Groups',
    defaultMessage: 'Groups',
  },
  games: {
    id: 'Games',
    defaultMessage: 'Games',
  },
  game: {
    id: 'Game',
    defaultMessage: 'Game',
  },
  name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  town: {
    id: 'Town',
    defaultMessage: 'Town',
  },
  birthYear: {
    id: 'Birth year',
    defaultMessage: 'Birth year',
  },
  profileUpdatedSuccessfully: {
    id: 'Profile updated successfully',
    defaultMessage: 'Profile updated successfully',
  },
  confirm: {
    id: 'Confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  privacyPolicy: {
    id: 'Privacy policy',
    defaultMessage: 'Privacy policy',
  },
  deleteAccount: {
    id: 'Delete account',
    defaultMessage: 'Delete account',
  },
  didntReceiveAnySMS: {
    id: "Didn't receive any SMS?",
    defaultMessage: "Didn't receive any SMS?",
  },
  verification: {
    id: 'Verification',
    defaultMessage: 'Verification',
  },
  enterTheNumberWeSentToYourPhoneNumber: {
    id: 'Enter the number we sent to your phone number',
    defaultMessage: 'Enter the number we sent to your phone number',
  },
  verificationNumber6Digits: {
    id: 'Verification number (6 digits)',
    defaultMessage: 'Verification number (6 digits)',
  },
  areYouSureDeleteAccount: {
    id: 'Are you sure you want to delete your account?',
    defaultMessage: 'Are you sure you want to delete your account?',
  },
  areYouSureLeaveTheGroup: {
    id: 'Are you sure you want to leave the group?',
    defaultMessage: 'Are you sure you want to leave the group?',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  start: {
    id: 'Start',
    defaultMessage: 'Start',
  },
  signUp: {
    id: 'Sign up',
    defaultMessage: 'Sign up',
  },
  generalInformation: {
    id: 'General information',
    defaultMessage: 'General information',
  },
  contact: {
    id: 'Contact',
    defaultMessage: 'Contact',
  },
  rateTheApp: {
    id: 'Rate the app',
    defaultMessage: 'Rate the app',
  },
  aboutClassification: {
    id: 'About classification',
    defaultMessage: 'About classification',
  },
  prizes: {
    id: 'Prizes',
    defaultMessage: 'Prizes',
  },
  aboutGroups: {
    id: 'About groups',
    defaultMessage: 'About groups',
  },
  aboutGames: {
    id: 'About games',
    defaultMessage: 'About games',
  },
  notifications: {
    id: 'Notifications',
    defaultMessage: 'Notifications',
  },
  settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  members: {
    id: '{membersCount} members',
    defaultMessage: '{membersCount} members',
  },
  addGroup: {
    id: 'Add group',
    defaultMessage: 'Add group',
  },
  createNewGroup: {
    id: 'Create new group',
    defaultMessage: 'Create new group',
  },
  usingGroupKey: {
    id: 'Using group key',
    defaultMessage: 'Using group key',
  },
  yourGroups: {
    id: 'Your groups',
    defaultMessage: 'Your groups',
  },
  groupName: {
    id: 'Group name',
    defaultMessage: 'Group name',
  },
  groupDescription: {
    id: 'Group description',
    defaultMessage: 'Group description',
  },
  writeHereGroupDescription: {
    id: 'Write here group description...',
    defaultMessage: 'Write here group description...',
  },
  groupKey: {
    id: 'Group key',
    defaultMessage: 'Group key',
  },
  joinToGroup: {
    id: 'Join to group',
    defaultMessage: 'Join to group',
  },
  createNewGroupErrorMessage: {
    id: 'There was a problem creating the group. Please, try again.',
    defaultMessage: 'There was a problem creating the group. Please try again.',
  },
  joinToGroupErrorMessage: {
    id: 'There was a problem joining the group. Make sure the code is correct.',
    defaultMessage:
      'There was a problem joining the group. Make sure the code is correct.',
  },
  anErrorOcurred: {
    id: 'An error ocurred',
    defaultMessage: 'An error ocurred',
  },
  save: {
    id: 'Save',
    defaultMessage: 'Save',
  },
  leaveTheGroup: {
    id: 'Leave the group',
    defaultMessage: 'Leave the group',
  },
  participants: {
    id: 'Participants',
    defaultMessage: 'Participants',
  },
  shareGroupKey: {
    id: 'Share group key: {groupKey}',
    defaultMessage: 'Share group key: {groupKey}',
  },
  copyToClipboard: {
    id: 'Copy to clipboard',
    defaultMessage: 'Copy to clipboard',
  },
  share: {
    id: 'Share',
    defaultMessage: 'Share',
  },
  groupKeyCopiedToClipboard: {
    id: 'Group key copied to clipboard',
    defaultMessage: 'Group key copied to clipboard',
  },
  goldQuestion: {
    id: 'Gold question',
    defaultMessage: 'Gold question',
  },
  clickOnNoYesButtons: {
    id: 'Click on no yes buttons',
    defaultMessage: 'Click on no yes buttons',
  },
  hamaikakoa: {
    id: 'Hamaikakoa',
    defaultMessage: 'Hamaikakoa',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'No',
    defaultMessage: 'No',
  },
  select2ByClickingOnIt: {
    id: 'Select 2 by clicking on it',
    defaultMessage: 'Select 2 by clicking on it',
  },
  liveGameOutOfTimeHeader: {
    id: 'You are out of time',
    defaultMessage: 'You are out of time',
  },
  liveGameOutOfTimeGoldQuestionMessage: {
    id: 'Watch out next time! You have {seconds} seconds to finish gold question!',
    defaultMessage:
      'Watch out next time! You have {seconds} seconds to finish gold question!',
  },
  liveGameOutOfTimeHamaikakoaMessage: {
    id: 'Watch out next time! You have {seconds} seconds to finish hamaikakoa question!',
    defaultMessage:
      'Watch out next time! You have {seconds} seconds to finish hamaikakoa question!',
  },
  areYouReady: {
    id: 'Are you ready?',
    defaultMessage: 'Are you ready?',
  },
  theGameIsGoingToBegin: {
    id: 'The game is going to begin',
    defaultMessage: 'The game is going to begin',
  },
  ofTheDay: {
    id: 'Of the day',
    defaultMessage: 'Of the day',
  },
  ofTheWeek: {
    id: 'Of the week',
    defaultMessage: 'Of the week',
  },
  previousDays: {
    id: 'Previous days',
    defaultMessage: 'Previous days',
  },
  previousWeeks: {
    id: 'Previous weeks',
    defaultMessage: 'Previous weeks',
  },
  yourPosition: {
    id: 'Your position: {userPosition}',
    defaultMessage: 'Your position: {userPosition}',
  },
  sendingGame: {
    id: 'Sending game...',
    defaultMessage: 'Sending game...',
  },
  gameSavedMsg: {
    id: 'The game has been saved successfully. The results will soon be visible.',
    defaultMessage:
      'The game has been saved successfully. The results will soon be visible.',
  },
  thereAreNotGroupYetCreateTheFirstOne: {
    id: "You don't have any group yet, create the first one!",
    defaultMessage: "You don't have any group yet, create the first one!",
  },
  youHaveNotPlayedAGameYet: {
    id: "You haven't played a game yet",
    defaultMessage: "You haven't played a game yet",
  },
  enableOrDisableNotifications: {
    id: 'Enable/Disable notifications',
    defaultMessage: 'Enable/Disable notifications',
  },
  shareYourResult: {
    id: 'Share your result',
    defaultMessage: 'Share your result',
  },
  shareResultMessage: {
    id: 'game_share_message',
    defaultMessage: `Mihiluze\nToday's result\n{correct_1}+{correct_2} 🏆 | {time} 🕔 | {points} points`,
  },
  messageAfterPlayLiveGame: {
    id: 'message_after_play_live_game',
    defaultMessage: 'After solving on TV, your result will appear here.',
  },
  shareGroupLinkMessage: {
    id: 'Join the group using this key: {groupKey}',
    defaultMessage: 'Join the group using this key: {groupKey}',
  },
  wantToWatchYourGameInTheMeantime: {
    id: 'Want to watch your <span>game</span> in the meantime?',
    defaultMessage: 'Want to watch your <span>game</span> in the meantime?',
  },
  theWeeklyRankingHasNotStartedYet: {
    id: 'The weekly ranking has not started yet',
    defaultMessage: 'The weekly ranking has not started yet',
  },
  usersAmount: {
    id: '{count} users',
    defaultMessage: '{count} users',
  },
  gameCountDown: {
    id: '{mm}m {ss}s barru',
    defaultMessage: '{mm}m {ss}s barru',
  },
  theFirst20: {
    id: 'The first 20',
    defaultMessage: 'The First 20',
  },
  answer: {
    id: 'Answer',
    defaultMessage: 'Answer',
  },
  verify: {
    id: 'Verify',
    defaultMessage: 'Verify',
  },
  sendAnd: {
    id: 'Send and...',
    defaultMessage: 'Send and...',
  },
  goToGoldQuestion: {
    id: 'Go to gold question',
    defaultMessage: 'Go to gold question',
  },
});

export default messages;
