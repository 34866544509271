import { apiPath } from './constants';
import messages from './messages';

export interface IFlatpage {
  [x: string]: {
    url: string;
    title: { id: string; defaultMessage: string };
    showAppVersion?: boolean;
  };
}

const flatpages: IFlatpage = {
  acceptPrivacyPolicy: {
    url: apiPath + '/privacy-policy/',
    title: messages.privacyPolicy,
    showAppVersion: false,
  },
  acceptTermsOfUse: {
    url: apiPath + '/terms-of-use/',
    title: messages.termsOfUse,
    showAppVersion: false,
  },
  acceptCommercialCommunications: {
    url: apiPath + '/commercial-communications/',
    title: messages.commercialCommunications,
    showAppVersion: false,
  },
  deleteAccount: {
    url: `${apiPath}/delete-account/`,
    title: messages.deleteAccount,
    showAppVersion: false,
  },
  smsHelp: {
    url: `${apiPath}/sms-help/`,
    title: messages.didntReceiveAnySMS,
    showAppVersion: false,
  },
  generalInformation: {
    url: `${apiPath}/general-information/`,
    title: messages.generalInformation,
    showAppVersion: true,
  },
  aboutClassification: {
    url: `${apiPath}/about-classification/`,
    title: messages.aboutClassification,
    showAppVersion: false,
  },
  prizes: {
    url: `${apiPath}/prizes/`,
    title: messages.prizes,
    showAppVersion: false,
  },
  aboutGroups: {
    url: `${apiPath}/about-groups/`,
    title: messages.aboutGroups,
    showAppVersion: false,
  },
  aboutGames: {
    url: `${apiPath}/about-games/`,
    title: messages.aboutGames,
    showAppVersion: false,
  },
  privacyPolicy: {
    url: `${apiPath}/privacy-policy/`,
    title: messages.privacyPolicy,
    showAppVersion: false,
  },
  mihiluzePolicy: {
    url: `${apiPath}/mihiluze-policy/`,
    title: messages.termsOfUse,
    showAppVersion: true,
  },
};

export { flatpages };
