import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonPage,
  useIonToast,
} from '@ionic/react';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import BirthDateInput from 'components/Base/BirthDateInput/BirthDateInput';
import Button from 'components/Base/Button/Button';
import Input from 'components/Base/Input/Input';
import Header from 'components/Elements/Headers/Header/Header';
import RemoveAccountButton from 'components/Elements/RemoveAccount/RemoveAccount';
import PhotoEditor from 'components/Utils/PhotoEditor/PhotoEditor';
import { userDefaultPhoto } from 'config/constants';
import messages from 'config/messages';
import { routes } from 'config/routes';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import { IUser } from 'interfaces/user.interface';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import { FormEvent, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { updateUserData } from 'store/actions/user';

const Profile: React.FC = () => {
  const user: IUser = useAppSelector((state) => state.user.data);
  const dispatch = useAppDispatch();
  const [presentToast] = useIonToast();
  const { loading } = useAppSelector((state) => state.user);

  const [form, setForm] = useState({
    name: user.name,
    birthdate: user.birthdate || undefined,
    town: user.town,
    email: user.email,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedUserData = {
      ...user,
      ...form,
    };

    user.id && form && dispatch(updateUserData(user.id, updatedUserData));
    presentToast({
      message: intl.formatMessage(messages.profileUpdatedSuccessfully),
      duration: 3000,
    });
  };
  const intl = useIntl();
  const onInputChange = (inputName: string, value: string | null) => {
    setForm({
      ...form,
      [inputName]: value,
    });
  };

  const ref = useRef();
  const pushRoute = usePushRoute();

  return (
    <IonPage ref={ref}>
      <Header title={user.name}>
        <IonButtons slot="start">
          <IonBackButton />
        </IonButtons>

        <IonButtons slot="end" className="ion-no-margin ion-no-padding">
          <IonButton
            onClick={() =>
              pushRoute(routes.moreInfo, {
                menuId: 'userProfile',
                title: intl.formatMessage(messages.settings),
              })
            }
          >
            <IonIcon slot="icon-only" icon={ellipsisVerticalOutline} />
          </IonButton>
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer ionPageRef={ref} ellipsedBackground>
          <PhotoEditor currentPhotoUrl={user.photo_url || userDefaultPhoto} />

          <p className="ion-text-center">{user.phone}</p>
          <form onSubmit={handleSubmit} className="ion-padding">
            <Input
              label={intl.formatMessage(messages.name)}
              value={form?.name}
              type="text"
              onChange={(e) => onInputChange('name', e)}
            />
            <br />
            <Input
              label={intl.formatMessage(messages.email)}
              value={form.email}
              type="email"
              onChange={(e) => onInputChange('email', e)}
              maxLenght={100}
            />
            <br />
            <Input
              label={intl.formatMessage(messages.town)}
              value={form.town}
              type="text"
              onChange={(e) => onInputChange('town', e)}
            />
            <br />
            <BirthDateInput
              value={form.birthdate}
              onChange={(e) => onInputChange('birthdate', e)}
            />
            <br />
            <Button
              type="submit"
              disabled={loading}
              loading={loading}
              title={intl.formatMessage(messages.save)}
            />
          </form>
          <br />
          <RemoveAccountButton />
          <br />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
