import { IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import { FC } from 'react';
import './GroupItem.css';
import { groupPhoto } from 'config/constants';
import { useIntl } from 'react-intl';
import messages from 'config/messages';

interface GroupItemProps {
  name: string;
  photoUrl: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;
  // position: string;
  members: number;
}
const GroupItem: FC<GroupItemProps> = ({
  onClick,
  name,
  photoUrl,
  members,
}) => {
  const intl = useIntl();
  return (
    <IonItem
      onClick={onClick}
      lines="full"
      className="group-item"
      button
      detail
    >
      <IonThumbnail slot="start">
        <img alt={name} src={photoUrl || groupPhoto} />
      </IonThumbnail>
      <IonLabel color="secondary">
        {name}
        <p>
          {intl.formatMessage(messages.members, {
            membersCount: members,
          })}
        </p>
      </IonLabel>

      {/* <IonLabel slot="end">{position}</IonLabel> */}
    </IonItem>
  );
};
export default GroupItem;
