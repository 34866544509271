import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  isPlatform,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { IMoreInfoMenus, moreInfoMenus } from 'config/moreInfoMenus';
import { usePushRoute } from 'hooks';
import { routes } from 'config/routes';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import Header from 'components/Elements/Headers/Header/Header';
import { closeOutline } from 'ionicons/icons';
import { openUrl } from 'helpers/browser';
import { appStoresUrls } from 'config/constants';
import './MoreInfoMenu.css';

const MoreInfoMenu: React.FC = () => {
  const { menuId, title } = useParams<any>();
  const [menuItems, setMenuItems] = useState<any>([]);
  const intl = useIntl();
  useEffect(() => {
    setMenuItems(moreInfoMenus[menuId]);
  }, [menuId]);

  const pushRoute = usePushRoute();

  const popoverClickAction = (item: IMoreInfoMenus) => {
    switch (item.action.type) {
      case 'rateApp':
        openUrl(
          isPlatform('android') ? appStoresUrls.android : appStoresUrls.ios,
        );
        break;
      case 'route':
        let search = window.location.search;
        // pushRoute(
        //   routes[item.action.route],
        //   item.action?.params ? item.action?.params : {},
        // );
        pushRoute(
          routes[item.action.route],
          {
            ...(item.action?.params ? item.action?.params : {}),
          },
          search,
        );
        break;
    }
  };
  const ref = useRef();
  return (
    <IonPage ref={ref}>
      <Header title={title}>
        <IonButtons slot="end" className="ion-no-margin ion-no-padding">
          <IonBackButton icon={closeOutline} />
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer ionPageRef={ref} ellipsedBackground>
          {menuItems.map((item: IMoreInfoMenus, i: number) => (
            <IonItem
              button={true}
              detail={true}
              key={i}
              lines={menuItems.length === i + 1 ? 'none' : 'full'}
              className="popover-list-item"
              onClick={() => {
                popoverClickAction(item);
              }}
            >
              <IonLabel>{intl.formatMessage(item.label)}</IonLabel>
            </IonItem>
          ))}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default MoreInfoMenu;
