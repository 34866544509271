import { Dispatch } from '@reduxjs/toolkit';
import {
  getClassification as getClassificationRequest,
  getWeekClassification as getWeekClassificationRequest,
  getCompetitionList as getCompetitionListRequest,
  getWeeks,
} from 'api/classification';

export const GET_TODAY_CLASSIFICATION_PENDING =
  'GET_TODAY_CLASSIFICATION_PENDING';
export const GET_TODAY_CLASSIFICATION_SUCCESS =
  'GET_TODAY_CLASSIFICATION_SUCCESS';
export const GET_TODAY_CLASSIFICATION_ERROR = 'GET_TODAY_CLASSIFICATION_ERROR';
export const getTodayClassification = (id: number) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_TODAY_CLASSIFICATION_PENDING,
    });
    try {
      const { data } = await getClassificationRequest(id);
      dispatch({
        type: GET_TODAY_CLASSIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_TODAY_CLASSIFICATION_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_WEEK_CLASSIFICATION_PENDING =
  'GET_WEEK_CLASSIFICATION_PENDING';
export const GET_WEEK_CLASSIFICATION_SUCCESS =
  'GET_WEEK_CLASSIFICATION_SUCCESS';
export const GET_WEEK_CLASSIFICATION_ERROR = 'GET_WEEK_CLASSIFICATION_ERROR';
export const getWeekClassification = (id: number) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_WEEK_CLASSIFICATION_PENDING,
    });
    try {
      const { data } = await getWeekClassificationRequest(id);
      dispatch({
        type: GET_WEEK_CLASSIFICATION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_WEEK_CLASSIFICATION_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_PREVIOUS_DAYS_PENDING = 'GET_PREVIOUS_DAYS_PENDING';
export const GET_PREVIOUS_DAYS_SUCCESS = 'GET_PREVIOUS_DAYS_SUCCESS';
export const GET_PREVIOUS_DAYS_ERROR = 'GET_PREVIOUS_DAYS_ERROR';

export const getPreviousDays = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PREVIOUS_DAYS_PENDING,
    });
    try {
      const { data } = await getCompetitionListRequest();
      dispatch({
        type: GET_PREVIOUS_DAYS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PREVIOUS_DAYS_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_PREVIOUS_WEEKS_PENDING = 'GET_PREVIOUS_WEEKS_PENDING';
export const GET_PREVIOUS_WEEKS_SUCCESS = 'GET_PREVIOUS_WEEKS_SUCCESS';
export const GET_PREVIOUS_WEEKS_ERROR = 'GET_PREVIOUS_WEEKS_ERROR';

export const getPreviousWeeks = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PREVIOUS_WEEKS_PENDING,
    });
    try {
      const { data } = await getWeeks();
      dispatch({
        type: GET_PREVIOUS_WEEKS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PREVIOUS_WEEKS_ERROR,
        payload: error,
      });
    }
  };
};
