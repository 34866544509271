import { Dispatch } from '@reduxjs/toolkit';
import {
  postUserGame,
  signUpToCompetition as signUpToCompetitionRequest,
} from 'api/liveGame';
import { ILiveUserGame } from 'interfaces/live.interface';

export const EMPTY_LIVE = 'EMPTY_LIVE';
export const emptyLive = () => {
  return {
    type: EMPTY_LIVE,
  };
};

export const END_OF_TIME = 'END_OF_TIME';
export const endOfTime = () => {
  return {
    type: END_OF_TIME,
  };
};

export const SIGN_UP_TO_COMPETITION_PENDING = 'SIGN_UP_TO_COMPETITION_PENDING';
export const SIGN_UP_TO_COMPETITION_SUCCESS = 'SIGN_UP_TO_COMPETITION_SUCCESS';
export const SIGN_UP_TO_COMPETITION_ERROR = 'SIGN_UP_TO_COMPETITION_ERROR';
export const signUpToCompetition = (competitionId: number) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: SIGN_UP_TO_COMPETITION_PENDING,
    });
    try {
      const { data } = await signUpToCompetitionRequest(competitionId);
      dispatch({
        type: SIGN_UP_TO_COMPETITION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SIGN_UP_TO_COMPETITION_ERROR,
        payload: error,
      });
    }
  };
};

export const SEND_USER_GAME_PENDING = 'SEND_USER_GAME_PENDING';
export const SEND_USER_GAME_SUCCESS = 'SEND_USER_GAME_SUCCESS';
export const SEND_USER_GAME_ERROR = 'SEND_USER_GAME_ERROR';
export const sendUserGame = (game: ILiveUserGame) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: SEND_USER_GAME_PENDING,
    });
    try {
      const { data } = await postUserGame(game);
      dispatch({
        type: SEND_USER_GAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SEND_USER_GAME_ERROR,
        payload: error,
      });
    }
  };
};
