import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import { useEffect, useRef } from 'react';
import { useAPI } from 'hooks';
import Header from 'components/Elements/Headers/Header/Header';
import { useParams } from 'react-router';
import { getClassification, getCompetitionDetail } from 'api/classification';
import ClassificationLayout from './ClassificationLayout';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';

const PreviousDayDetail: React.FC = () => {
  const { data, triggerRequest } = useAPI();
  const { data: competitionData, triggerRequest: triggerCompetitionRequest } =
    useAPI();

  const ref = useRef();
  const { id } = useParams<{ id: string }>();
  const intl = useIntl();

  useEffect(() => {
    if (id) triggerRequest(getClassification, Number(id));
  }, [id, triggerRequest]);

  useEffect(() => {
    if (id) triggerCompetitionRequest(getCompetitionDetail, Number(id));
  }, [id, triggerCompetitionRequest]);
  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.classification)}>
        <IonToolbar>
          <IonButtons slot="start" className="ion-no-margin ion-no-padding">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <ClassificationLayout
            date={competitionData?.competition?.date}
            classification={data?.classification}
            msg={data?.msg}
            userGame={data?.user_game}
            userPosition={data?.user_position}
          />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default PreviousDayDetail;
