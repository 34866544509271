import { IonContent, IonModal, isPlatform, useIonToast } from '@ionic/react';
import { leaveTheGroup } from 'api/groups';
import Button from 'components/Base/Button/Button';
import UserItem from 'components/Elements/UserItem/UserItem';
import messages from 'config/messages';
import { useAPI, useAppDispatch } from 'hooks';
import { IGroupDetail } from 'interfaces/groups.interface';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { getGroups } from 'store/actions/groups';
import GroupAdminSettings from './AdminSettings/GroupAdminSettings';

import '../../GroupDetail.css';

const GroupSettings: React.FC<{
  group: IGroupDetail;
  isOpen: boolean;
}> = ({ group, isOpen }) => {
  const intl = useIntl();
  const { triggerRequest, data } = useAPI();
  const [toast] = useIonToast();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.success && data?.removed) {
      history.goBack();
      dispatch(getGroups());
    }
  }, [data, dispatch, history]);

  const presentToast = () => {
    toast({
      message: intl.formatMessage(messages.areYouSureLeaveTheGroup),
      mode: 'ios',
      color: 'danger',
      duration: 10000,
      position: 'bottom',
      buttons: [
        {
          text: intl.formatMessage(messages.cancel),
          role: 'cancel',
        },
        {
          text: intl.formatMessage(messages.leaveTheGroup),
          role: 'info',
          handler: () => {
            triggerRequest(leaveTheGroup, group.id);
          },
        },
      ],
    });
  };
  const isIosDevice = isPlatform('ios') ? 'ios-device' : '';
  return (
    <IonModal
      isOpen={isOpen}
      className={`full-modal group-settings-modal ${isIosDevice}`}
    >
      <IonContent className="ion-padding-horizontal">
        <h1
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 3,
            background: 'white',
            width: '100%',
            padding: '15px 0',
            margin: '0px',
          }}
        >
          {intl.formatMessage(messages.settings)}
        </h1>

        <GroupAdminSettings group={group} />
        <div className="ion-padding-vertical">
          <h2 style={{ marginTop: 0 }}>
            {intl.formatMessage(messages.participants)}
          </h2>
          {group?.users?.map((user, i) => (
            <UserItem
              key={i}
              name={user.name}
              photoUrl={user?.photo_url || ''}
              endLabel={group?.admin?.id === user.id ? 'Admin' : null}
            />
          ))}
          <br />
          <Button
            title={intl.formatMessage(messages.leaveTheGroup)}
            onClick={presentToast}
          />
          <br />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default GroupSettings;
