import {
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IClassificationRequest } from 'interfaces/classification.interface';
import { getTodayClassification } from 'store/actions/classification';
import ClassificationLayout from '../ClassificationLayout';

const CurrentCompetition: React.FC = () => {
  const dispatch = useAppDispatch();

  const { today }: { today: IClassificationRequest } = useAppSelector(
    (state) => state.classification.data,
  );

  const competitionId = useAppSelector((state) =>
    state.home?.data?.today
      ? state.home?.data?.today?.id
      : state.home?.data?.last || null,
  );

  useEffect(() => {
    dispatch(getTodayClassification(competitionId));
  }, [dispatch, competitionId]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getTodayClassification(competitionId));
    event.detail.complete();
  };

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      <ClassificationLayout
        date={today?.date}
        classification={today?.classification}
        userPosition={today?.user_position}
        msg={today?.msg}
        userGame={today?.user_game}
      />
    </>
  );
};

export default CurrentCompetition;
