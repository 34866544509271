import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { IUser } from 'interfaces/user.interface';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonModal,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import Header from '../Headers/Header/Header';
import { InnerHTML } from 'components/Utils';
import { flatpages } from 'config/flatpages';
import { useIntl } from 'react-intl';
import { deleteUser } from 'store/actions/user';
import messages from 'config/messages';
import Button from 'components/Base/Button/Button';
import './RemoveAccount.css';

const RemoveAccountButton: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const intl = useIntl();

  const {
    data,
  }: {
    loading: boolean;
    loaded: boolean;
    data: IUser;
  } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const removeUserHandler = () => {
    if (data !== null && data?.id !== null && data.id !== undefined) {
      dispatch(deleteUser(data.id));
    }
  };

  const [toast] = useIonToast();

  const presentToast = () => {
    toast({
      message: intl.formatMessage(messages.areYouSureDeleteAccount),
      mode: 'ios',
      color: 'danger',
      duration: 10000,
      position: 'bottom',
      buttons: [
        {
          text: intl.formatMessage(messages.cancel),
          role: 'cancel',
        },
        {
          text: intl.formatMessage(messages.delete),
          role: 'info',
          handler: () => {
            removeUserHandler();
          },
        },
      ],
    });
  };
  return (
    <>
      <IonModal
        trigger="open-remove-account-modal"
        ref={modal}
        className="remove-account-modal"
      >
        <Header title={intl.formatMessage(messages.deleteAccount)}>
          <IonButtons slot="start">
            <IonButton onClick={() => modal.current?.dismiss()}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </Header>
        <IonContent className="ion-padding">
          <InnerHTML url={flatpages.deleteAccount.url} />
        </IonContent>
        <IonFooter className="ion-no-border">
          <IonToolbar>
            <Button
              size="large"
              onClick={() => presentToast()}
              title={intl.formatMessage(messages.deleteAccount)}
            />
          </IonToolbar>
        </IonFooter>
      </IonModal>

      <IonButton
        fill="clear"
        color="danger"
        expand="block"
        id="open-remove-account-modal"
        size="small"
        style={{ textTransform: 'none' }}
      >
        {intl.formatMessage(messages.deleteAccount)}
      </IonButton>
    </>
  );
};

export default RemoveAccountButton;
