import { FC, ReactNode, RefObject, useEffect } from 'react';
import './BackgroundContainer.css';
import { bannerPhoto } from 'config/constants';

interface BackgroundContainerProps {
  children: ReactNode;
  ionPageRef: RefObject<any>;
  showLogo?: boolean;
  className?: string;
  ellipsedBackground?: boolean;
}
const BackgroundContainer: FC<BackgroundContainerProps> = ({
  children,
  ionPageRef,
  showLogo = false,
  className = '',
  ellipsedBackground = false,
}) => {
  useEffect(() => {
    if (ionPageRef?.current) {
      ionPageRef.current.classList.add('blue-bg');
    }
  }, [ionPageRef]);
  return (
    <>
      {showLogo && (
        <div className="ion-text-center">
          <img src={bannerPhoto} alt="Mihiluze" />
        </div>
      )}
      <div
        className={`blue-bg-container ${
          ellipsedBackground ? 'ellipsed-top-margin' : ''
        }`}
      >
        <div id="ellipse"></div>
        <div className={`blue-bg-content ion-padding-top  ${className}`}>
          {children}
        </div>
      </div>
    </>
  );
};
export default BackgroundContainer;
