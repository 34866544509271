import {
  IonAvatar,
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { useAppSelector, usePushRoute } from '../../../../hooks';
import '../CenteredHeaderTitle.css';
import './ProfileHeader.css';
import { routes } from 'config/routes';
import { IUser } from 'interfaces/user.interface';
import { avatarPhoto } from 'config/constants';

export interface ProfileHeaderProps {
  title: string;
  children?: React.ReactNode | any;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  title,
  children = false,
}) => {
  const user: IUser = useAppSelector((state) => state.user.data);

  const pushRoute = usePushRoute();
  return (
    <IonHeader className="header-profile-item centered-header-title ion-no-border">
      <IonToolbar>
        <IonItem lines="none">
          <IonAvatar onClick={() => pushRoute(routes.profile)}>
            <img
              src={user.photo_url || avatarPhoto}
              alt={user.name}
              loading="lazy"
            />
          </IonAvatar>
          <IonLabel className="ion-no-margin ion-text-center" mode="md">
            <IonTitle className="ion-no-padding">{title}</IonTitle>
          </IonLabel>
          <IonButtons slot="end" className="ion-no-margin ion-no-padding">
            {children}
          </IonButtons>
        </IonItem>
      </IonToolbar>
    </IonHeader>
  );
};

export default ProfileHeader;
