import { useIntl } from 'react-intl';
import { useIonPicker } from '@ionic/react';
import messages from 'config/messages';
import './BirthDateInput.css';
import { useState } from 'react';
interface IBirthDateInput {
  /**
   * Optional click handler
   */
  onChange?: (e: string | null) => void;
  onCancel?: () => void;
  value?: number;
}
const BirthDateInput: React.FC<IBirthDateInput> = ({
  onChange,
  onCancel,
  value,
}) => {
  const currentYear = new Date().getFullYear() - 17;
  const intl = useIntl();

  const [birthdate, setBirthdate] = useState(value);

  const [presentPicker, dissmissPicker] = useIonPicker();

  const openPicker = () => {
    let startYear = 1923;
    const years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    const values: { text: string; value: string }[] = years
      .reverse()
      .map((year) => {
        return {
          text: String(year),
          value: String(year),
        };
      });
    presentPicker({
      columns: [
        {
          name: 'year',
          options: values,
        },
      ],
      buttons: [
        {
          text: intl.formatMessage(messages.cancel),
          role: 'cancel',
          handler: () => {
            onCancel && onCancel();
            dissmissPicker();
          },
        },
        {
          text: intl.formatMessage(messages.confirm),
          handler: (value) => {
            onChange && onChange(value.year.value);
            setBirthdate(value.year.value);
          },
        },
      ],
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="birthdate-input-container" onClick={openPicker}>
      <input
        className="birthdate-input"
        value={birthdate}
        {...(!birthdate && {
          placeholder: intl.formatMessage(messages.birthYear),
        })}
      />
      {birthdate && <span>{intl.formatMessage(messages.birthYear)}</span>}
    </div>
  );
};
export default BirthDateInput;
