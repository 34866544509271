import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonText,
  isPlatform,
  useIonLoading,
  useIonToast,
} from '@ionic/react';
import { authStateChange, sendVerificationCode } from 'helpers/smsAuth';
import NumberInput from 'components/Base/NumberInput/NumberInput';
import { flatpages } from 'config/flatpages';
import { InnerHTML } from 'components/Utils';
import messages from 'config/messages';
// import Button from 'components/Base/Button/Button';

export interface onVerifyPhoneNumberProps {
  phoneNumber: string;
  accessToken: string;
}
interface SendVerificationCodeProps {
  verificationId: string;
  onVerifyPhoneNumber: ({
    phoneNumber,
    accessToken,
  }: onVerifyPhoneNumberProps) => void;
}
const SendVerificationCode: React.FC<SendVerificationCodeProps> = ({
  verificationId,
  onVerifyPhoneNumber,
}) => {
  // const [verificationCode, setVerificationCode] = useState<string>();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();

  // const flatpageUrl = flatpages.find((flatpage) => flatpage.id === 'sms-help')
  //   ?.url[language === 'eu' ? 'eu' : 'es'];
  const intl = useIntl();
  const SMSAuthError = intl.formatMessage({
    id: 'SMS auth error message',
    defaultMessage: 'SMS auth error message',
  });

  const handleSendVerificationCode = async (verificationCode: string) => {
    if (verificationCode) {
      presentLoading();
      try {
        const { phoneNumber, accessToken } = await sendVerificationCode(
          verificationId,
          verificationCode,
        );
        if (phoneNumber && accessToken) {
          onVerifyPhoneNumber({ phoneNumber, accessToken });
          dismissLoading();
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dismissLoading();
        presentToast({
          message: SMSAuthError,
          duration: 3000,
          color: 'danger',
        });
      }
    }
  };
  useEffect(() => {
    if (isPlatform('android')) {
      authStateChange().then((res) => {
        if (res.phoneNumber) {
          onVerifyPhoneNumber({
            phoneNumber: res.phoneNumber,
            accessToken: res.accessToken,
          });
          dismissLoading();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <h2 className="ion-no-margin">
        {intl.formatMessage(messages.verification)}
      </h2>
      <p>
        {intl.formatMessage(messages.enterTheNumberWeSentToYourPhoneNumber)}
      </p>

      <br />
      <p>{intl.formatMessage(messages.verificationNumber6Digits)}</p>
      <NumberInput onComplete={(value) => handleSendVerificationCode(value)} />
      <div style={{ bottom: 0, position: 'absolute', width: '93%' }}>
        <IonAccordionGroup>
          <IonAccordion>
            <IonItem color="light" slot="header">
              <IonLabel color="dark">
                {intl.formatMessage(messages.didntReceiveAnySMS)}
              </IonLabel>
            </IonItem>
            <div slot="content" className="ion-padding-horizontal">
              <IonText color="dark">
                <InnerHTML url={flatpages.smsHelp.url} />
              </IonText>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      </div>
    </>
  );
};

export default SendVerificationCode;
