import {
  IonContent,
  IonIcon,
  IonModal,
  isPlatform,
  useIonLoading,
} from '@ionic/react';
import {
  ILiveQuestion,
  ILiveUserCompetition,
  ILiveUserGame,
  ILiveUserGameSelection,
  ISendUserGame,
  IStatus,
} from 'interfaces/live.interface';
import { useEffect, useRef, useState } from 'react';
import PlayGameFirst from './PlayGame/PlayGameFirstRound';
import PlayGameSecondRound from './PlayGame/PlayGameSecondRound';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import {
  liveGameFirstRoundSeconds,
  liveGameSecondRoundSeconds,
  requestGameDetailAfterPlayRandomTimeout,
  statusCodes,
} from 'config/constants';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import './LiveGameModal.css';
import { routes } from 'config/routes';
import { emptyLive, endOfTime, sendUserGame } from 'store/actions/live';
import { stopwatchOutline } from 'ionicons/icons';
import { getHomeInfo } from 'store/actions/home';
import GameButton from 'components/Base/GameButton/GameButton';
import { InnerHTML } from 'components/Utils';
import { IHome } from 'interfaces/home.interface';
import useRealtimeDB from 'hooks/useRTDB';
import { randomIntBetweenTwoNumbers } from 'helpers/utils';
import { getLiveGameDetail } from 'store/actions/games';
import PlayGameResolution from './PlayGame/PlayGameResolution';

const LiveGameModal: React.FC = () => {
  const liveCompetition: ILiveUserCompetition = useAppSelector(
    (state) => state.live.liveCompetition.competition,
  );
  // const liveStatus: IStatus = useAppSelector(
  //   (state) => state.live.liveStatus.status,
  // );
  // const liveQuestions: ILiveQuestion[] = useAppSelector(
  //   (state) => state.live.liveQuestions.questions,
  // );
  const liveSendUserGame: ISendUserGame = useAppSelector(
    (state) => state.live.liveSendUserGame,
  );
  const home: IHome = useAppSelector((state) => state.home.data);

  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [openLiveModal, setOpenLiveModal] = useState<boolean>(false);

  const [userSelection, setUserSelection] = useState<ILiveUserGame>({
    1: [],
    2: [],
    time: 0,
  });
  const [userSelectionResolved, setUserResolutionResolved] =
    useState<boolean>(false);
  const onFirstRoundFinished = ({
    finished,
    userSelection: userSelectionArgs,
    time,
  }: {
    finished: boolean;
    userSelection: ILiveUserGameSelection[];
    time: number;
  }) => {
    if (!liveSendUserGame?.success) {
      if (finished) {
        setUserSelection({
          ...userSelection,
          1: userSelectionArgs,
          time,
        });
      } else {
        dispatch(endOfTime());
        // setUserSelection({ 1: [], 2: [], time: 0 });
      }
    }
  };
  const onSecondRoundFinished = ({
    finished,
    userSelectionIds,
    time,
  }: {
    finished: boolean;
    userSelectionIds: string[];
    time: number;
  }) => {
    if (!liveSendUserGame?.success) {
      if (finished) {
        const secondRoundSelection: ILiveUserGameSelection[] | undefined =
          liveQuestions
            ?.find((item) => item.type === 2)
            ?.options.map((item) => {
              return {
                id: item.id,
                name: item.name,
                value: Boolean(userSelectionIds.includes(item.id)),
              };
            });
        if (secondRoundSelection) {
          const userFinishedGame = {
            ...userSelection,
            2: secondRoundSelection,
            time: userSelection.time + time,
          };
          setUserSelection(userFinishedGame);
          // if (liveCompetition.game_id) {
          dispatch(sendUserGame(userFinishedGame));
          requestGameDetail();
          // }
        }
      } else {
        dispatch(endOfTime());
        // If timeout was in second round, send first round values to API
        // if (liveCompetition.game_id) {
        const userFirstRoundSelection = {
          ...userSelection,
          time: userSelection.time + liveGameSecondRoundSeconds,
        };
        dispatch(sendUserGame(userFirstRoundSelection));
        requestGameDetail();
        // }
      }
    }
  };
  const onResolutionFinished = ({
    finished,
    userSelection: userSelectionArgs,
    time,
  }: {
    finished: boolean;
    userSelection: ILiveUserGameSelection[];
    time: number;
  }) => {
    if (finished) {
      setUserResolutionResolved(true);
      setUserSelection((prev) => {
        return {
          ...prev,
          1: userSelectionArgs,
          time: prev.time + time,
        };
      });
    } else {
      dispatch(endOfTime());
    }
  };
  const requestGameDetail = () => {
    // Partidaren detailea eskatu random bidez (5 eta 20 segundu artean)

    const timeout =
      randomIntBetweenTwoNumbers(
        requestGameDetailAfterPlayRandomTimeout[0],
        requestGameDetailAfterPlayRandomTimeout[1],
      ) * 1000;
    setTimeout(() => {
      dispatch(getLiveGameDetail());
    }, timeout);
  };
  const pushRoute = usePushRoute();

  const liveStatus = useRealtimeDB<IStatus>(
    `competitions/${home?.today?.hash_code}/status`,
  );
  const liveQuestions = useRealtimeDB<ILiveQuestion[]>(
    `competitions/${home?.today?.hash_code}/questions`,
  );

  const liveQuestionsAux = useRealtimeDB<string>(
    `competitions/${home?.today?.hash_code}/qs_aux`,
  );

  // if (liveQuestionsAux) {
  // const test = decryptAes(liveQuestionsAux, encyptKey);
  // console.log('test: ', test);
  // }
  useEffect(() => {
    if (liveStatus) {
      if (liveStatus?.status === statusCodes.HASTERA) {
        setOpenLiveModal(true);
      }
      if (
        (liveStatus.status === statusCodes.BUKATUTA &&
          liveStatus.show_classification === true &&
          openLiveModal) ||
        (liveStatus.status === statusCodes.HASI_GABE && openLiveModal)
      ) {
        setOpenLiveModal(false);
        liveSendUserGame?.success &&
          liveCompetition?.game_id &&
          pushRoute(routes.gameDetail, { id: liveCompetition?.game_id });
        setTimeout(() => dispatch(emptyLive()), 500);
        setUserSelection({ 1: [], 2: [], time: 0 });
        setUserResolutionResolved(false);
        setShowUserSelection(false);
        dispatch(getHomeInfo());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, liveStatus, pushRoute]);

  const [presentLoading, dismissLoading] = useIonLoading();
  useEffect(() => {
    if (liveSendUserGame.loading && !liveSendUserGame.loaded) {
      presentLoading({
        message: intl.formatMessage(messages.sendingGame),
        backdropDismiss: true,
      });
    }
    if (!liveSendUserGame.loading && liveSendUserGame.loaded) {
      dismissLoading();
    }
  }, [presentLoading, dismissLoading, liveSendUserGame, intl]);

  const selectedInFirstRound = (optionName: string) => {
    return userSelection[1]?.find((item) => item.name === optionName)?.value;
  };
  const selectedInSecondRound = (optionName: string) => {
    return userSelection[2]?.find((item) => item.name === optionName)?.value;
  };
  const [showUserSelection, setShowUserSelection] = useState<boolean>(false);
  const isIosDevice = isPlatform('ios') ? 'ios-device' : '';
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref.current !== null) {
      if (liveSendUserGame.endOfTime) {
        // ref.current.className = 'live-red-bg';
        setBGClassName('live-red-bg');
      } else {
        setBGClassName('live-bg');
        // ref.current.className = 'live-bg';
      }
    }
  }, [liveSendUserGame.endOfTime]);

  const [bgClassName, setBGClassName] = useState<
    'live-bg' | 'live-red-bg' | 'live-green-bg'
  >('live-bg');
  return (
    <IonModal
      isOpen={openLiveModal}
      backdropDismiss={false}
      className="full-modal"
    >
      <IonContent ref={ref} className={bgClassName}>
        {(userSelection[1]?.length !== 0 && userSelection[2]?.length !== 0) ||
        liveSendUserGame.endOfTime ? (
          <div className="ion-text-center">
            {liveSendUserGame.endOfTime ? (
              <div className="ion-padding center-container">
                <h2>{intl.formatMessage(messages.liveGameOutOfTimeHeader)}</h2>

                {!userSelectionResolved ? (
                  <h2 className="live-msg">
                    <InnerHTML>
                      {intl.formatMessage(
                        messages.liveGameOutOfTimeHamaikakoaMessage,
                        {
                          seconds: `<strong>${liveGameFirstRoundSeconds}</strong>`,
                        },
                      )}
                    </InnerHTML>
                  </h2>
                ) : (
                  <h2 className="live-msg">
                    <InnerHTML>
                      {intl.formatMessage(
                        messages.liveGameOutOfTimeGoldQuestionMessage,
                        {
                          seconds: `<strong>${liveGameSecondRoundSeconds}</strong>`,
                        },
                      )}
                    </InnerHTML>
                  </h2>
                )}
                <IonIcon
                  icon={stopwatchOutline}
                  style={{ zoom: 4 }}
                  color="light"
                />
                {!liveSendUserGame.loading && liveSendUserGame.loaded && (
                  <InnerHTML>{liveSendUserGame?.msg}</InnerHTML>
                )}
                <h2>{intl.formatMessage(messages.messageAfterPlayLiveGame)}</h2>
              </div>
            ) : (
              <>
                <div
                  className={`ion-padding ${
                    showUserSelection
                      ? `sticky-top animate__animated animate__backInUp animate__fast ${isIosDevice}`
                      : 'center-container'
                  }`}
                >
                  <h2>
                    {intl.formatMessage(messages.messageAfterPlayLiveGame)}
                  </h2>
                  {!liveSendUserGame.loading &&
                    liveSendUserGame.loaded &&
                    !showUserSelection && (
                      <h2 className="live-msg">
                        <InnerHTML>{liveSendUserGame?.msg}</InnerHTML>
                      </h2>
                    )}
                  {!showUserSelection && (
                    <div
                      onClick={() => setShowUserSelection(!showUserSelection)}
                      onKeyUp={() => setShowUserSelection(!showUserSelection)}
                      role="button"
                      tabIndex={0}
                      className="show-user-selection-button"
                    >
                      <InnerHTML>
                        {`<p>${intl.formatMessage(
                          messages.wantToWatchYourGameInTheMeantime,
                        )}</p>`}
                      </InnerHTML>
                    </div>
                  )}
                </div>

                {showUserSelection && (
                  <div className="ion-padding-horizontal animate__animated animate__backInUp animate__fast">
                    <br />
                    {userSelection[1]?.map((item, i) => (
                      <GameButton
                        title={item.name}
                        position={
                          selectedInFirstRound(item.name) ? 'end' : 'start'
                        }
                        key={i}
                        selected={selectedInSecondRound(item.name)}
                        disabled={true}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <>
            {liveQuestions && (
              <>
                {userSelection[1]?.length === 0 ? (
                  <PlayGameFirst
                    question={
                      liveQuestions.find((item) => item.type === 1) || null
                    }
                    countDownToStartGame={liveStatus?.start_timeout || 20}
                    onFinishRound={onFirstRoundFinished}
                  />
                ) : userSelectionResolved ? (
                  <PlayGameSecondRound
                    firstRoundUserSelection={userSelection[1] || null}
                    question={
                      liveQuestions.find((item) => item.type === 2) || null
                    }
                    onFinishRound={onSecondRoundFinished}
                  />
                ) : (
                  <PlayGameResolution
                    timer={userSelection.time}
                    question={
                      liveQuestions.find((item) => item.type === 1) || null
                    }
                    questionAux={liveQuestionsAux}
                    firstRoundUserSelection={userSelection[1]}
                    onResolutionFinished={onResolutionFinished}
                    onBGChange={(className: any) => setBGClassName(className)}
                  />
                )}
              </>
            )}
          </>
        )}
      </IonContent>
    </IonModal>
  );
};

export default LiveGameModal;
