import { FC } from 'react';
import './NotificationItem.css';
import { useIntl } from 'react-intl';

interface NotificationItemProps {
  title: string;
  description: string;
  date: string;
  className?: string;
}
const NotificationItem: FC<NotificationItemProps> = ({
  title,
  description,
  date,
  className,
}) => {
  const intl = useIntl();
  return (
    <div className={`notification-item ${className}`}>
      <h2>{title}</h2>
      <p>{description}</p>
      <br />
      <p className="ion-text-end">{intl.formatDate(date)}</p>
    </div>
  );
};
export default NotificationItem;
