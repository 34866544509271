import {
  IonButton,
  IonCol,
  IonFab,
  IonGrid,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  useIonToast,
} from '@ionic/react';
import messages from 'config/messages';
import { keyOutline, peopleOutline } from 'ionicons/icons';
import { FC, FormEvent, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import './AddGroupModal.css';
import Button from 'components/Base/Button/Button';
import Input from 'components/Base/Input/Input';
import { useAPI, useAppDispatch } from 'hooks';
import { joinToGroup, postGroup } from 'api/groups';
import { getGroups } from 'store/actions/groups';

const NewGroupOptions = {
  enterWithKey: 'ENTER_WITH_KEY',
  createNew: 'CREATE_NEW',
};

const AddGroupModal: FC = () => {
  const intl = useIntl();
  const modal = useRef<HTMLIonModalElement>(null);
  const [createGroupModal, setCreateGroupModal] = useState<boolean>(false);
  const [newGroupOption, setNewGroupOption] = useState<string | null>();
  const { data, loaded, loading, triggerRequest } = useAPI();
  const dispatch = useAppDispatch();

  const submitCreateNewGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      'group-name': { value: string };
    };
    const groupName = formElements['group-name'].value;
    groupName && triggerRequest(postGroup, { name: groupName });
  };

  const submitJoinNewGroup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      'group-key': { value: string };
    };
    const groupKey = formElements['group-key'].value;
    groupKey && triggerRequest(joinToGroup, groupKey);
  };

  const [presentToast] = useIonToast();

  useEffect(() => {
    if (!data?.created && !data?.success && loaded) {
      dismiss();
      presentToast({
        message: intl.formatMessage(
          NewGroupOptions.createNew === newGroupOption
            ? messages.createNewGroupErrorMessage
            : messages.joinToGroupErrorMessage,
        ),
        duration: 3000,
        color: 'danger',
      });
    }
    if (loaded && data?.success) {
      dispatch(getGroups());
      dismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch, intl, loaded, presentToast]);

  const dismiss = () => {
    modal.current?.dismiss();
  };

  return (
    <>
      <IonFab
        vertical="bottom"
        horizontal="end"
        className={'add-group-modal-fab'}
      >
        <Button
          title={intl.formatMessage(messages.addGroup)}
          onClick={() => setCreateGroupModal(true)}
        />
      </IonFab>
      <IonModal
        isOpen={createGroupModal}
        ref={modal}
        className="bottom-modal"
        onDidDismiss={() => {
          setCreateGroupModal(false);
          setNewGroupOption(null);
        }}
      >
        <div className="ion-padding-horizontal">
          <h1>{intl.formatMessage(messages.addGroup)}</h1>
          <IonGrid className="ion-no-padding">
            {!newGroupOption ? (
              <IonRow className="new-group-modal-button">
                <IonCol size="6">
                  <IonButton
                    mode="ios"
                    className="new-group-modal-button button-1"
                    onClick={() => {
                      setNewGroupOption(NewGroupOptions.enterWithKey);
                    }}
                  >
                    <div>
                      <IonIcon icon={keyOutline} />
                      {intl.formatMessage(messages.usingGroupKey)}
                    </div>
                  </IonButton>
                </IonCol>
                <IonCol size="6">
                  <IonButton
                    mode="ios"
                    className="new-group-modal-button  button-2"
                    onClick={() => {
                      setNewGroupOption(NewGroupOptions.createNew);
                    }}
                  >
                    <div>
                      <IonIcon icon={peopleOutline} />
                      {intl.formatMessage(messages.createNewGroup)}
                    </div>
                  </IonButton>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="animate__animated animate__zoomIn animate__faster">
                <IonCol>
                  {newGroupOption === NewGroupOptions.enterWithKey && (
                    <form onSubmit={submitJoinNewGroup}>
                      <div className="ion-padding-vertical">
                        <Input
                          label={intl.formatMessage(messages.groupKey)}
                          name="group-key"
                          disabled={loading}
                        />
                      </div>
                      {loading ? (
                        <div className="ion-text-center">
                          <IonSpinner color="danger" />
                        </div>
                      ) : (
                        <Button
                          title={intl.formatMessage(messages.joinToGroup)}
                          type="submit"
                        />
                      )}
                    </form>
                  )}

                  {newGroupOption === NewGroupOptions.createNew && (
                    <form onSubmit={submitCreateNewGroup}>
                      <div className="ion-padding-vertical">
                        <Input
                          label={intl.formatMessage(messages.groupName)}
                          name="group-name"
                          disabled={loading}
                        />
                      </div>
                      {loading ? (
                        <div className="ion-text-center">
                          <IonSpinner color="danger" />
                        </div>
                      ) : (
                        <Button
                          title={intl.formatMessage(messages.createNewGroup)}
                          type="submit"
                        />
                      )}
                    </form>
                  )}
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </div>
      </IonModal>
    </>
  );
};
export default AddGroupModal;
