import { IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { FC } from 'react';
import './UserItem.css';
import { avatarPhoto } from 'config/constants';

interface UserItemProps {
  name: string;
  photoUrl: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;
  position?: number;
  endLabel?: number | string | null | any;
  height?: 'small' | 'large';
}
const UserItem: FC<UserItemProps> = ({
  onClick,
  name,
  photoUrl,
  position = 0,
  endLabel = null,
  height = 'small',
}) => {
  return (
    <IonItem onClick={onClick} lines="none" className="user-item">
      <IonAvatar slot="start">
        <img
          alt={name}
          src={photoUrl || avatarPhoto}
          onError={(e) => {
            e.currentTarget.onerror = null; // prevents looping
            e.currentTarget.src = avatarPhoto;
          }}
        />
      </IonAvatar>
      <IonLabel>{position ? `${position}. ${name}` : name}</IonLabel>

      {endLabel !== null && (
        <IonLabel slot="end">
          <p className="ion-text-end">{endLabel}</p>
          <div
            id="user-ellipse"
            className={height === 'large' ? 'large-height' : ''}
          ></div>
        </IonLabel>
      )}
    </IonItem>
  );
};
export default UserItem;
