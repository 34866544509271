import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonPage,
  IonToolbar,
} from '@ionic/react';
import { useIntl } from 'react-intl';
import messages from 'config/messages';
import { useEffect, useRef } from 'react';
import { useAPI } from 'hooks';
import Header from 'components/Elements/Headers/Header/Header';
import { useParams } from 'react-router';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import ClassificationLayout from 'pages/Classification/ClassificationLayout';
import { getGroupClassification } from 'api/groups';

const GroupPreviousDayDetail: React.FC = () => {
  const { data, triggerRequest } = useAPI();
  // const { data: competitionData, triggerRequest: triggerCompetitionRequest } =
  //   useAPI();

  const ref = useRef();
  const { id, competitionId } = useParams<{
    id: string;
    competitionId: string;
  }>();
  const intl = useIntl();

  useEffect(() => {
    if (id && competitionId)
      triggerRequest(getGroupClassification, Number(id), Number(competitionId));
  }, [id, competitionId, triggerRequest]);

  // useEffect(() => {
  //   if (id) triggerCompetitionRequest(getGroupClassification, Number(id));
  // }, [id, triggerCompetitionRequest]);
  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.classification)}>
        <IonToolbar>
          <IonButtons slot="start" className="ion-no-margin ion-no-padding">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <ClassificationLayout
            date={data?.date}
            classification={data?.classification}
            msg={data?.msg}
            userGame={data?.user_game}
            userPosition={data?.user_position}
          />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default GroupPreviousDayDetail;
