import {
  LOAD_SESSION_FROM_LOCAL_ERROR,
  LOAD_SESSION_FROM_LOCAL_PENDING,
  LOAD_SESSION_FROM_LOCAL_SUCCESS,
  REGISTER_SESSION_ERROR,
  REGISTER_SESSION_PENDING,
  REGISTER_SESSION_SUCCESS,
  REMOVE_SESSION,
  REMOVE_TOKEN,
  SET_JWT_TOKEN,
} from 'store/actions/session';

interface ISession {
  access: string;
  refresh: string;
  loading: boolean;
  loaded: boolean;
  loggedIn: boolean;
}

const initialState: ISession = {
  access: '',
  refresh: '',
  loading: false,
  loaded: false,
  loggedIn: false,
};
const session = (state = initialState, action: any) => {
  switch (action.type) {
    case REMOVE_TOKEN:
      return {
        ...state,
        access: '',
        loading: false,
        loaded: true,
      };
    case SET_JWT_TOKEN:
    case LOAD_SESSION_FROM_LOCAL_SUCCESS:
      return {
        ...state,
        access: action.payload.access,
        refresh: action.payload.refresh,
        loggedIn: action.payload.refresh !== null,
        loading: false,
        loaded: true,
      };
    case REGISTER_SESSION_SUCCESS:
      return {
        ...state,
        access: action.payload.access,
        loading: false,
        loaded: true,
      };
    case REGISTER_SESSION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: false,
      };
    case LOAD_SESSION_FROM_LOCAL_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        loaded: true,
      };
    case REGISTER_SESSION_PENDING:
    case LOAD_SESSION_FROM_LOCAL_PENDING:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case REMOVE_SESSION:
      return {
        ...state,
        access: '',
        refresh: '',
        loading: false,
        loaded: true,
        loggedIn: false,
      };
    default:
      return state;
  }
};

export default session;
