import { IonBackButton, IonButtons, IonContent, IonPage } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { AppVersion, InnerHTML } from '../components/Utils';
import { flatpages } from '../config/flatpages';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import Header from 'components/Elements/Headers/Header/Header';
import { closeOutline } from 'ionicons/icons';

const Flatpage: React.FC = () => {
  const { id } = useParams<any>();
  const intl = useIntl();
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const ref = useRef();
  const [showAppVersion, setShowAppVersion] = useState(false);
  useEffect(() => {
    const flatpage = flatpages[id];
    if (flatpage) {
      setTitle(intl.formatMessage(flatpage.title));
      setUrl(flatpage.url);
      setShowAppVersion(flatpage.showAppVersion || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <IonPage ref={ref}>
      <Header title={title}>
        <IonButtons slot="end" className="ion-no-margin ion-no-padding">
          <IonBackButton icon={closeOutline} />
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          ellipsedBackground
          className="ion-padding"
        >
          {url && <InnerHTML url={url} />}
          {showAppVersion && <AppVersion />}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Flatpage;
