import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from '../config/routes';

const useIonTabBarHiderStyle = () => {
  const [tabStyle, setTabStyle] = useState({});
  const history = useHistory();

  const hideTabBarPaths = Object.values(routes)
    .filter((item: any) => ('hideTabBar' in item ? item?.hideTabBar : false))
    .map((item: any) => item.path);

  const changeStyleByPathName = useCallback((pathname: string) => {
    const isCurrentPathnameHide = hideTabBarPaths.includes(pathname);
    if (isCurrentPathnameHide) {
      setTabStyle({
        display: 'none',
      });
    } else {
      setTabStyle({
        display: 'flex',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (history) {
      changeStyleByPathName(history?.location?.pathname);

      let unlisten = history?.listen(({ location }: any) => {
        /*
            Normalize browser route to react-router-dom route
            example: /lives/12312 => /lives/:id
            */
        let pathname = location?.pathname;
        if (location?.state) {
          Object.keys(location.state).forEach((key) => {
            pathname = pathname.replace(`/${location.state[key]}`, `/:${key}`);
          });
        }
        changeStyleByPathName(pathname);
      });
      return () => unlisten();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return tabStyle;
};

export default useIonTabBarHiderStyle;
