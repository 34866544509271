import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIonAlert, useIonLoading } from '@ionic/react';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useAPI, useAppDispatch, usePushRoute } from 'hooks';
import { getGroups } from 'store/actions/groups';
import { routes } from 'config/routes';
import { deepLinkGroupHashLink } from 'config/constants';
import { joinToGroup } from 'api/groups';

const AppDeepLinking: React.FC<any> = () => {
  //   const { deepLinkGroupHashLink, routes } = useConfig();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const { data, loading, loaded, triggerRequest } = useAPI();

  const pushRoute = usePushRoute();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data?.success) {
      dispatch(getGroups());
      pushRoute(routes.groups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  useEffect(() => {
    if (loading && !loaded) {
      presentLoading();
    } else {
      dismissLoading();
    }
  }, [loading, loaded, presentLoading, dismissLoading]);
  const intl = useIntl();
  const messages = defineMessages({
    alertHeader: {
      id: 'Do you want to be a member of the group?',
      defaultMessage: 'Do you want to be a member of the group?',
    },
    joinToGroup: {
      id: 'Join to group',
      defaultMessage: 'Join to group',
    },
    cancel: {
      id: 'Cancel',
      defaultMessage: 'Cancel',
    },
  });

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const groupKey = event.url.split(deepLinkGroupHashLink).pop();
      if (groupKey) {
        presentAlert({
          header: intl.formatMessage(messages.alertHeader),
          // message: `You have been invited to join group ${groupKey}`,
          buttons: [
            {
              text: intl.formatMessage(messages.cancel),
              role: 'cancel',
            },
            {
              text: intl.formatMessage(messages.joinToGroup),
              handler: () => {
                triggerRequest(joinToGroup, groupKey);
              },
            },
          ],
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AppDeepLinking;
