export const getDate = () => {
  var x = new Date();
  var y = x.getFullYear().toString();
  var m = (x.getMonth() + 1).toString();
  var d = x.getDate().toString();
  d.length === 1 && (d = '0' + d);
  m.length === 1 && (m = '0' + m);
  var yyyymmdd = y + '-' + m + '-' + d;
  return yyyymmdd;
};

export const roundFloat = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  const rounded = Math.round(value * multiplier) / multiplier;
  return rounded.toFixed(precision);
};

// export const getRemainingMinutes = (date: string) => {
//   const todayGameDate: any = new Date(date);
//   const now: any = new Date();
//   const diffMs = todayGameDate - now;
//   const minutes = Math.floor(diffMs / 60000) + 1;
//   return minutes;
// };

export const normalizeDate = (date: string) => {
  if (!date) return '';
  return date.replace(' ', 'T') + '+00:00';
};

export const capitalizeString = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isEmptyObject = (obj: {}) => {
  if (obj === null) return true;
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

export const randomIntBetweenTwoNumbers = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
