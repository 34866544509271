import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';

const useAPI = () => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<AxiosError | boolean>(false);

  const triggerRequest = useCallback(
    async (requestFunction: Function, ...rest: any[]) => {
      if (!requestFunction) return;
      setLoading(true);
      setLoaded(false);
      try {
        const { data } = await requestFunction(...rest);
        setData(data);
        setLoading(false);
        setLoaded(true);
      } catch (e: any) {
        setError(e);
        setLoading(false);
        setLoaded(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetAllValues = () => {
    setData(null);
    setLoading(false);
    setLoaded(false);
    setError(false);
  };

  return {
    data,
    loading,
    loaded,
    error,
    triggerRequest,
    resetAllValues,
  };
};

export default useAPI;
