import { Dispatch } from '@reduxjs/toolkit';
import { getHome } from '../../api/home';

export const GET_HOME_PENDING = 'GET_HOME_PENDING';
export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const GET_HOME_ERROR = 'GET_HOME_ERROR';
export const getHomeInfo = () => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_HOME_PENDING,
    });
    try {
      const { data } = await getHome();
      dispatch({
        type: GET_HOME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_HOME_ERROR,
        payload: error,
      });
    }
  };
};
