import { IonBackButton, IonButtons, IonContent, IonPage } from '@ionic/react';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';
import Header from 'components/Elements/Headers/Header/Header';
import { closeOutline } from 'ionicons/icons';
import messages from 'config/messages';
import { useAPI } from 'hooks';
import { getNotifications } from 'api/notification';
import NotificationItem from 'components/Elements/NotificationItem/NotificationItem';
import { INotifications } from 'interfaces/notification.interface';

const Notifications: React.FC = () => {
  const ref = useRef();
  const intl = useIntl();
  const {
    data,
    triggerRequest,
  }: {
    loading?: boolean;
    loaded?: boolean;
    triggerRequest: (x: () => void) => void;
    data: INotifications;
  } = useAPI();
  useEffect(() => {
    triggerRequest(getNotifications);
  }, [triggerRequest]);

  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.notifications)}>
        <IonButtons slot="end" className="ion-no-margin ion-no-padding">
          <IonBackButton icon={closeOutline} />
        </IonButtons>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          ellipsedBackground
          className="ion-padding"
        >
          {data?.results.map(({ title, description, added }) => (
            <NotificationItem
              title={title}
              description={description}
              date={added}
              className="ion-margin-bottom"
            />
          ))}
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
