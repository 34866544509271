import { Dispatch } from '@reduxjs/toolkit';
import {
  getGames as getGamesRequest,
  getGameDetail as getGameDetailRequest,
} from 'api/games';
import { RootState } from 'store';

export const GET_GAMES_PENDING = 'GET_GAMES_PENDING';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_ERROR = 'GET_GAMES_ERROR';
export const getGames = () => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_GAMES_PENDING,
    });
    try {
      const { data } = await getGamesRequest();
      dispatch({
        type: GET_GAMES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GAMES_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_GAME_DETAIL_PENDING = 'GET_GAME_DETAIL_PENDING';
export const GET_GAME_DETAIL_SUCCESS = 'GET_GAME_DETAIL_SUCCESS';
export const GET_GAME_DETAIL_ERROR = 'GET_GAME_DETAIL_ERROR';
export const getGameDetail = (id: number) => {
  return async (dispatch: Dispatch) => {
    // get redux state
    dispatch({
      type: GET_GAME_DETAIL_PENDING,
    });
    try {
      const { data } = await getGameDetailRequest(id);
      dispatch({
        type: GET_GAME_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_GAME_DETAIL_ERROR,
        payload: error,
      });
    }
  };
};

export const GET_LIVE_GAME_DETAIL_PENDING = 'GET_LIVE_GAME_DETAIL_PENDING';
export const GET_LIVE_GAME_DETAIL_SUCCESS = 'GET_LIVE_GAME_DETAIL_SUCCESS';
export const GET_LIVE_GAME_DETAIL_ERROR = 'GET_LIVE_GAME_DETAIL_ERROR';
export const getLiveGameDetail = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    // get redux state
    dispatch({
      type: GET_LIVE_GAME_DETAIL_PENDING,
    });
    try {
      const gameId = getState().live.liveCompetition.competition.game_id;
      const { data } = await getGameDetailRequest(gameId);
      dispatch({
        type: GET_LIVE_GAME_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIVE_GAME_DETAIL_ERROR,
        payload: error,
      });
    }
  };
};
