import { useIntl } from 'react-intl';
import { Redirect, Route } from 'react-router';

import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';

import initialDataLoader from 'helpers/hocs/InitialLoader';

import { tabs } from 'config/tabs';
import { initialRoute, routes } from 'config/routes';
import useIonTabBarHiderStyle from 'hooks/useIonTabBarHideStyle';
import { useState } from 'react';

const AppTabsRoutes = () => {
  const intl = useIntl();
  const ionTabBarHiderStyle = useIonTabBarHiderStyle();

  const [selectedTab, setSelectedTab] = useState<string>(
    initialRoute.replace('/', ''),
  );
  return (
    <IonTabs>
      <IonRouterOutlet>
        {Object.values(routes).map((route: any, i) => (
          <Route
            path={route.path}
            component={route.component}
            key={i}
            {...('exact' in route && route?.exact && { exact: true })}
          />
        ))}
        <Route exact path="/">
          <Redirect to={initialRoute} />
        </Route>
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        style={ionTabBarHiderStyle}
        onIonTabsDidChange={(e) => {
          setSelectedTab(e.detail.tab);
        }}
      >
        {tabs.map((tab: any, i: number) => (
          <IonTabButton key={i} href={`/${tab.tab}`} tab={tab.tab}>
            <IonIcon icon={selectedTab === tab.tab ? tab.fillIcon : tab.icon} />
            <IonLabel>{intl.formatMessage(tab.label)}</IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};
export default initialDataLoader(AppTabsRoutes);
