import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { IonFabButton, IonIcon, IonSpinner, useIonToast } from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import { CameraResultType } from '@capacitor/camera';

import { changeUserPhoto } from 'api/user';
import { userDefaultPhoto } from 'config/constants';
import { useAPI, useAppDispatch, useAppSelector } from 'hooks';
import { getUserData } from 'store/actions/user';

import './PhotoEditor.css';

import { useCamera } from 'hooks';
import { updateGroupPhoto } from 'api/groups';
import { getGroupDetail } from 'store/actions/groups';

export interface IPhotoEditorProps {
  groupId?: number;
  currentPhotoUrl: string | null;
}

/**
 * User and group photo editor
 * @param groupId Pass groupId if you want to update group photo
 * @param currentPhotoUrl Pass current photoUrl
 *
 */

const PhotoEditor: React.FC<IPhotoEditorProps> = ({
  currentPhotoUrl,
  groupId,
}) => {
  const [photo, setPhoto] = useState(currentPhotoUrl);
  const userId: number = useAppSelector((state) => state.user.data?.id);
  const intl = useIntl();
  const messages = defineMessages({
    editPhoto: {
      id: 'Edit photo',
      defaultMessage: 'Edit photo',
    },
    photoUpdatedSuccessfully: {
      id: 'Photo updated successfully',
      defaultMessage: 'Photo updated successfully',
    },
    fromPhotos: {
      id: 'From Photos',
      defaultMessage: 'From Photos',
    },
    takePicture: {
      id: 'Take Picture',
      defaultMessage: 'Take Picture',
    },
    cancel: {
      id: 'Cancel',
      defaultMessage: 'Cancel',
    },
    photo: {
      id: 'Photo',
      defaultMessage: 'Photo',
    },
  });

  const { triggerRequest, data, loading } = useAPI();

  const [presentToast] = useIonToast();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.success && data?.updated) {
      if (!groupId) {
        dispatch(getUserData(userId));
      } else {
        dispatch(getGroupDetail(groupId));
      }
      presentToast({
        message: intl.formatMessage(messages.photoUpdatedSuccessfully),
        duration: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const { photo: cameraResult, getPhoto } = useCamera();
  const triggerCamera = useCallback(async () => {
    getPhoto({
      quality: 80,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      promptLabelPhoto: intl.formatMessage(messages.fromPhotos),
      promptLabelPicture: intl.formatMessage(messages.takePicture),
      promptLabelCancel: intl.formatMessage(messages.cancel),
      promptLabelHeader: intl.formatMessage(messages.photo),
    });
  }, [getPhoto, intl, messages]);

  useEffect(() => {
    if (cameraResult?.base64String) {
      if (!groupId) {
        triggerRequest(
          changeUserPhoto,
          cameraResult.base64String,
          cameraResult.format,
        );
      } else {
        triggerRequest(
          updateGroupPhoto,
          groupId,
          cameraResult.base64String,
          cameraResult.format,
        );
      }
      setPhoto(`data:image/png;base64,${cameraResult?.base64String}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraResult?.base64String, triggerRequest]);
  return (
    <div className="ion-padding ion-text-center photo-editor">
      <img
        src={photo || userDefaultPhoto}
        alt={intl.formatMessage(messages.editPhoto)}
      />
      <br />
      <IonFabButton onClick={triggerCamera} className="bottom-right small-fab">
        {!loading ? <IonIcon icon={cameraOutline} /> : <IonSpinner />}
      </IonFabButton>
    </div>
  );
};
export default PhotoEditor;
