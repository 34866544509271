import { useHistory } from 'react-router-dom';
import { IRoute } from '../config/routes';

const usePushRoute = () => {
  const history = useHistory();

  const pushRoute = (
    route: IRoute,
    params?: { [key: string]: string | number },
    queryParams?: string,
  ) => {
    // Replace all /* with the current history.location.pathname
    let path = route.path.replace('/**', history.location.pathname);
    // Replace all parameters (example-> :id) with the json params
    if (params) {
      const keys = Object.keys(params);
      keys.forEach((key) => {
        path = path.replace(`:${key}`, String(params[key]));
      });
    }
    // history.push(path, params);
    history.push({
      pathname: path,
      state: params,
      search: queryParams || '',
    });
  };
  return pushRoute;
};

export default usePushRoute;
