import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector, usePushRoute } from 'hooks';
import { getPreviousWeeks } from 'store/actions/classification';
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonToolbar,
  RefresherEventDetail,
} from '@ionic/react';
import { routes } from 'config/routes';
import { IPreviousWeek } from 'interfaces/classification.interface';
import { useIntl } from 'react-intl';
import Header from 'components/Elements/Headers/Header/Header';
import messages from 'config/messages';
import BackgroundContainer from 'components/Base/BackgroundContainer/BackgroundContainer';

const PreviousWeeks: React.FC = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const pushRoute = usePushRoute();
  const previousWeeks: IPreviousWeek[] = useAppSelector(
    (state) => state.classification.data.previousWeeks,
  );
  useEffect(() => {
    dispatch(getPreviousWeeks());
  }, [dispatch]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(getPreviousWeeks());
    event.detail.complete();
  };
  const ref = useRef();

  return (
    <IonPage ref={ref}>
      <Header title={intl.formatMessage(messages.previousWeeks)}>
        <IonToolbar>
          <IonButtons slot="start" className="ion-no-margin ion-no-padding">
            <IonBackButton />
          </IonButtons>
        </IonToolbar>
      </Header>
      <IonContent>
        <BackgroundContainer
          ionPageRef={ref}
          className="ion-padding-horizontal"
        >
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {previousWeeks &&
            previousWeeks.map((comp: IPreviousWeek, i: number) => (
              <IonCard
                mode="ios"
                className="ion-no-margin ion-margin-bottom"
                button
                key={i}
                onClick={() =>
                  pushRoute(routes.weekClassificationDetail, { id: comp.id })
                }
              >
                <IonCardContent>
                  <h1 className="ion-no-margin">
                    <strong>{comp.name}</strong>
                  </h1>
                </IonCardContent>
              </IonCard>
            ))}
          <br />
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default PreviousWeeks;
