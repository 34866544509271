import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import '../CenteredHeaderTitle.css';

export interface HeaderProps {
  title: string;
  children?: React.ReactNode | any;
}

const Header: React.FC<HeaderProps> = ({ title, children = false }) => {
  return (
    <IonHeader className="ion-no-border centered-header-title">
      <IonToolbar>
        {children}
        <IonTitle>{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
